import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import { Button, Modal, Form, Select, Input } from "antd";
import "./AdminPage.css"
import InfiniteScroll from "react-infinite-scroll-component";

const { Search } = Input;

function BlockedCommunitiesComponent() {
  const [blockedCommunities, setBlockedCommunities] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [finalBlockedMemberStatus, setFinalBlockedMemberStatus] = useState<any>(
    []
  );
  const [blockedMemberStatus, setBlockedMemberStatus] = useState<any>([]);
  const [blockedSearchResults, setBlockedSearchResults] = useState([]);
  const [blockedSearchText, setBlockedSearchText] = useState("");
  const [blockedMemberStatusMap, setBlockedMemberStatusMap] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const fetchData = async (page?: number) => {
    try {
      const resp = await request(
        `/admin/community/search?block=true&page=${page}&size=10`
      );
      // if (blockedResponse) {
      //   setBlockedCommunities(blockedResponse);
      // }
      if (!resp?.status) {
        if (!resp?.content || resp?.content?.length === 0) {
          if (page === 0){
            setBlockedCommunities(resp?.content);
          }
          setHasMore(false);
        } else {
          if (page === 0) {
            setBlockedCommunities(resp?.content);
            if (resp?.content?.length === 0) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }else {
            setBlockedCommunities([...blockedCommunities, ...resp?.content]);
          }
        }
      } else {
       setLoading(false);
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const onAddButtonClick = async () => {
    setLoading(true);
    const blockedData = Object.values(blockedMemberStatusMap);
    const saveButtonPayload = {
      method: "PATCH",
      body: JSON.stringify(blockedData),
      type: "application/json",
    };
    const response = await request(
      `/admin/community/block-unblock`,
      saveButtonPayload
    );
    if (response?.length) {
      setBlockedMemberStatusMap([]);
      toast.success("Community Status Updated");
      setCurrentPage(0)
      fetchData(0);
    }
  };
  const handleBlockedSearch = async (value: string) => {
    try {
      const resp = await request(
        `/admin/community/search?communityName=${value}&block=true&page=${currentPage}&size=10`
      );
      if (resp) {
        if (resp?.content?.length === 0) {
          setHasMore(false);
        }
        if (currentPage === 0) {
          setBlockedSearchResults(resp?.content);
          if (resp?.content?.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          let oldResults:any = [...blockedSearchResults];
          oldResults = [...oldResults, ...resp?.content];
          setBlockedSearchResults(oldResults);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const onBlockChange = (value: any, index: any, id: any) => {
    const updatedStatusMap = { ...blockedMemberStatusMap };

    updatedStatusMap[id] = {
      block: value.toUpperCase() === "ACTIVE" ? false : true,
      communityId: id,
    };

    setBlockedMemberStatusMap(updatedStatusMap);
  };
console.log("BLOCK MEMBER STATUS",blockedMemberStatusMap)
  const communityPermissions = ["Active", "Block"];
  const { Option } = Select;
  return (
    <>
      {loading ? (
        <div className="loader">
          <Modal className="loaderModal" open centered footer>
            <Loader />
          </Modal>
        </div>
      ) : (
        <>
          <div className="flex justify-center">
            <Search
              placeholder="Search Community..."
              allowClear
              style={{ borderRadius: "20px" }}
              id="communitySearch"
              enterButton
              size="large"
              value={blockedSearchText}
              onChange={(e: any) => {
                setBlockedSearchText(e.target.value);
                handleBlockedSearch(e.target.value);
              }}
              className="adminCommunitySearchBar"
              // onSearch={handleCommunitySearch}
            />
          </div>
          {blockedSearchText?.length === 0 ? (
            <InfiniteScroll
            dataLength={blockedCommunities?.length}
            next={() => {
              setCurrentPage(currentPage + 1);
            }}
            hasMore={hasMore}
            loader={ <></>
              // currentPage!==0 &&
              // (<div className="flex flex-row items-center justify-center">
              //   <img
              //     src={newloader}
              //     style={{ width: "80px", height: "80px" }}
              //     className="my-3"
              //     alt=""
              //   />
              // </div>)
              }
              scrollableTarget="adminScrollableDiv"
          >
            <div id="adminScrollableDiv" className="flex justify-center adminCommunityList">
              <div className="flex flex-col adminCommunityListContainer">
                {blockedCommunities?.length > 0 ? blockedCommunities?.map(
                  (blockedCommunity: any, index: any) => (
                    <div className="flex flex-row items-center justify-between" key={blockedCommunity?.id}>
                      <div
                        key={index}
                        className="flex flex-row items-center py-2 justify-start dpnamecontainer"
                      >
                        <div
                          className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                          //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                          style={{
                            backgroundColor:
                              blockedCommunity?.communityProfileUrl,
                          }}
                        >
                          <img
                            src={blockedCommunity?.communityProfileUrl}
                            alt=""
                          />
                        </div>
                        <div
                          className="flex items-center flex-col modalmemberName cursor-pointer"
                          style={{ color: "black" }}
                        >
                          <span className="adminpostMembertitle capitalize ml-2">
                            <span className="modalmemberName">
                              {blockedCommunity?.name}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div>
                        <Select
                          defaultValue={
                            blockedMemberStatusMap[blockedCommunity?.id]
                              ?.block === undefined
                              ? "Blocked"
                              : blockedMemberStatusMap[blockedCommunity?.id]
                                  ?.block
                              ? "Blocked"
                              : "Active"
                          }
                          className={
                            blockedMemberStatusMap[blockedCommunity?.id]
                              ?.block === undefined
                              ? "blockedDropdown statusDropdown searchbardiscover"
                              : blockedMemberStatusMap[blockedCommunity?.id]
                                  ?.block
                              ? "blockedDropdown statusDropdown searchbardiscover"
                              : "activeDropdown statusDropdown searchbardiscover"
                          }
                          bordered={false}
                          optionLabelProp="label"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            onBlockChange(value, index, blockedCommunity?.id)
                          }
                        >
                          {communityPermissions?.map((item: any) => (
                            <Option key={item} value={item} label={item}>
                              <div>
                                {item === "Blocked" ? "Block" : `${item}`}
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                )
              :
              <div className="flex flex-row items-center justify-center noCommunitiesText">No Communities</div>
            }
              </div>
            </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
                  dataLength={blockedSearchResults?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={ <></>
                    }
                    scrollableTarget="adminsearchScrollableDiv"
                >
            <div id="adminsearchScrollableDiv" className="flex justify-center adminCommunityList">
              <div className="flex flex-col adminCommunityListContainer">
                {blockedSearchResults?.length > 0 ? blockedSearchResults?.map(
                  (blockedCommunity: any, index: any) => (
                      <div className="flex flex-row items-center justify-between" key={blockedCommunity?.id}>
                        <div
                          key={index}
                          className="flex flex-row items-center py-2 justify-start dpnamecontainer"
                        >
                          <div
                            className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                            //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                            style={{
                              backgroundColor:
                                blockedCommunity?.communityProfileUrl,
                            }}
                          >
                            <img
                              src={blockedCommunity?.communityProfileUrl}
                              alt=""
                            />
                          </div>
                          <div
                            className="flex items-center flex-col modalmemberName cursor-pointer"
                            style={{ color: "black" }}
                          >
                            <span className="adminpostMembertitle capitalize ml-2">
                              <span className="modalmemberName">
                                {blockedCommunity?.name}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div>
                          <Select
                            defaultValue={
                              blockedCommunity?.block &&
                              blockedMemberStatusMap?.length === 0
                                ? "Blocked"
                                : blockedMemberStatusMap[blockedCommunity?.id]
                                    ?.block
                                ? "Blocked"
                                : "Active"
                            }
                            className={
                              blockedMemberStatusMap[blockedCommunity?.id]
                                ?.block === undefined
                                ? "blockedDropdown statusDropdown searchbardiscover"
                                : blockedMemberStatusMap[blockedCommunity?.id]
                                    ?.block
                                ? "blockedDropdown statusDropdown searchbardiscover"
                                : "activeDropdown statusDropdown searchbardiscover"
                            }
                            bordered={false}
                            optionLabelProp="label"
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              onBlockChange(value, index, blockedCommunity?.id)
                            }
                          >
                            {communityPermissions?.map((item: any) => (
                              <Option key={item} value={item} label={item}>
                                <div>
                                  {item === "Blocked" ? "Block" : `${item}`}
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    )
                )
              :
              <div className="flex flex-row items-center justify-center noCommunitiesText">No Communities</div>
              }
              </div>
            </div>
            </InfiniteScroll>
          )}
          <div className="w-full flex justify-center">
            <div className="flex justify-end adminSettingsSaveButtonContainer">
              <Button
                disabled={blockedMemberStatusMap?.length === 0 ? true : false}
                className="adminSettingsSaveButton"
                onClick={(event) => {
                  onAddButtonClick();
                  event.stopPropagation();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default BlockedCommunitiesComponent;
