import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import CommunityRequest from "./CommunityRequest";
import MatrimonyRequest from "./MatrimonyRequest";
import RequestPost from "./RequestPost";

type Props = {};
const items: TabsProps["items"] = [
  {
    key: "1",
    label: `Community Request`,
    children: <CommunityRequest />,
  },

  // {
  //   key: "2",
  //   label: `Post Request`,
  //   children: <RequestPost />,
  // },
  {
    key: "2",
    label: `Matrimony Request`,
    children: <MatrimonyRequest />,
  },
];
const RequestComponent = (props: Props) => {
  return (
    <div>
      <Tabs
        className=""
        defaultActiveKey="1"
        items={items}
        onChange={() => {}}
      />
    </div>
  );
};

export default RequestComponent;
