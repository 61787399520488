import React from "react";
import "../Commuinty/YourCommunity.css";
import { useNavigate } from "react-router";
import banner from "../../assets/images/community-banner.png";

type Props = {
  user?: any;
};

function UserCard(props: Props) {
  const {
    id,
    profileColorCode,
    profileUrl,
    name,
    currentCity,
    bannerUrl,
  } = props?.user;
  const navigate = useNavigate();
  return (
    <div
      className={"thumbnailRowForThreeColumn text-[#804C31]"}
      style={{ cursor: "pointer" }}
      key={id}
      onClick={() => navigate(`/profile/${id}`)}
    >
      <div className={"thumbnailImg"}>
        <img
          style={{
            width: "100%",
            height: "150px",
            objectFit: "cover",
          }}
          src={bannerUrl ? bannerUrl : banner}
          alt="Banner Cover"
        />
      </div>

      <div
        className="communityGridParent"
        style={{ position: "relative", bottom: "55px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            objectFit: "cover",
          }}
          className="communityGrid"
        >
          {profileUrl ? (
            <img
              src={profileUrl}
              className={"communityProfileImage"}
              alt=""
            />
          ) : (
            <div
              className="communityProfileImage flex justify-center items-center text-5xl text-white"
              style={{ backgroundColor: profileColorCode }}
            >
              {name[0]}
            </div>
          )}

          <div className="mobWraplist">
            <div className="communitythumbnailTitle">{name}</div>
            <div className="communitythumbnailDescription">
              <p>Lives in {currentCity}</p>
            </div>
          </div>
          <div
            className="joingroup"
            style={{ justifyContent: "flex-end" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
