import React, { useState, useEffect, useRef } from "react";
import "./ChatComponent.css";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { v4 as uuid } from "uuid";
import { useChatContext } from "../../Context/ChatContext";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { toast } from "react-toastify";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { isIOS, isMobileOnly } from "react-device-detect";

function ChatInput() {
  const [text, setText] = useState("");
  const [img, setImg] = useState<any>();
  const [imgPreview, setImgPreview] = useState<string | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const { chatData } = useChatContext();
  const { userMatrimonyData } = useUserMatrimonyContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const combinedId =
    userMatrimonyData?.matrimonyId > chatData?.matrimonyId
      ? userMatrimonyData?.matrimonyId + "_" + chatData?.matrimonyId
      : chatData?.matrimonyId + "_" + userMatrimonyData?.matrimonyId;

  useEffect(() => {
    // Update image preview when img state changes
    if (img) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result as string);
      };
      reader.readAsDataURL(img);
    } else {
      setImgPreview(null);
    }
  }, [img]);

  const handleSend = async () => {
    if (img) {
      const formattedSize: number = img?.size / (1024 * 1024);
      if (formattedSize > 10) {
        toast.error("Image must be less than 10 MB");
        setImg(false);
      } else {
        const storageRef = ref(storage, uuid());
        const uploadTask = uploadBytesResumable(storageRef, img);
        uploadTask.on(
          "state_changed", // Change the event type to "state_changed"
          (snapshot) => {
            // Handle upload progress if needed
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(`Upload is ${progress}% done`);
          },
          (error: any) => {
            // Handle Error
            console.error(error);
          },
          async () => {
            // Upload completed successfully
            if (text !== "") {
              if (inputRef.current) {
                inputRef.current.blur();
              }
              try {
                const abusiveResult = await CheckBadWordsExist(text);
                if (abusiveResult?.status === true) {
                  if (
                    !abusiveResult?.profanity &&
                    !abusiveResult.words?.length
                  ) {
                    getDownloadURL(uploadTask.snapshot.ref).then(
                      async (downloadURL) => {
                        await updateDoc(doc(db, "chats", combinedId), {
                          messages: arrayUnion({
                            id: uuid(),
                            text,
                            senderId: userMatrimonyData?.matrimonyId,
                            date: Timestamp.now(),
                            img: downloadURL,
                          }),
                        });
                      }
                    );
                    await updateDoc(
                      doc(db, "recentChats", userMatrimonyData?.matrimonyId),
                      {
                        [combinedId + ".lastMessage"]: {
                          text,
                        },
                        [combinedId + ".date"]: serverTimestamp(),
                      }
                    );

                    await updateDoc(
                      doc(db, "recentChats", chatData?.matrimonyId),
                      {
                        [combinedId + ".lastMessage"]: {
                          text,
                        },
                        [combinedId + ".date"]: serverTimestamp(),
                      }
                    );
                  } else if (abusiveResult?.profanity) {
                    let resultString = abusiveResult.words.join(" ");
                    toast.error(
                      `Not allowed to write abusive content ${resultString} `
                    );
                  }
                } else {
                  toast.error(abusiveResult?.message || "Something went wrong");
                }
              } catch (error) {
                console.log(error);
                toast.error("An error occurred while checking for bad words");
              }
              setImgPreview(null);
              setImg(false);
            } else {
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  await updateDoc(doc(db, "chats", combinedId), {
                    messages: arrayUnion({
                      id: uuid(),
                      senderId: userMatrimonyData?.matrimonyId,
                      date: Timestamp.now(),
                      img: downloadURL,
                    }),
                  });
                }
              );
            }
            setImg(false);
            setImgPreview(null);
          }
        );
      }
    } else {
      if (text !== "") {
        if (inputRef.current) {
          inputRef.current.blur();
        }
        try {
          const abusiveResult = await CheckBadWordsExist(text);
          if (abusiveResult?.status===true) {
            if (!abusiveResult?.profanity && !abusiveResult.words?.length) {
              await updateDoc(doc(db, "chats", combinedId), {
                messages: arrayUnion({
                  id: uuid(),
                  text,
                  senderId: userMatrimonyData?.matrimonyId,
                  date: Timestamp.now(),
                }),
              });
              await updateDoc(
                doc(db, "recentChats", userMatrimonyData?.matrimonyId),
                {
                  [combinedId + ".lastMessage"]: {
                    text,
                  },
                  [combinedId + ".date"]: serverTimestamp(),
                }
              );

              await updateDoc(doc(db, "recentChats", chatData?.matrimonyId), {
                [combinedId + ".lastMessage"]: {
                  text,
                },
                [combinedId + ".date"]: serverTimestamp(),
              });
            } else if (abusiveResult?.profanity) {
              let resultString = abusiveResult.words.join(" ");
              toast.error(
                `Not allowed to write abusive content ${resultString} `
              );
            }
          } else {
            toast.error(abusiveResult?.message || "Something went wrong");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    setText("");
    setImg(false);
  };

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 200) {
      setText(inputValue);
    } else {
      toast.error("Message length exceeds 200 characters");
    }
  };

  return (
    <div className="relative flex justify-center">
      <div className="img-preview absolute bottom-10 left-0 w-full flex justify-center items-center ">
        {imgPreview && (
          <>
            <div
              className="absolute top-0 right-5 cursor-pointer text-[#804c31] font-bold"
              onClick={() => {
                setImg(false);
              }}
            >
              X
            </div>
            <img
              src={imgPreview}
              alt="Preview"
              className="m-2 p-1"
              style={{ opacity: 1, maxWidth: "50%", maxHeight: "300px" }}
            />
          </>
        )}
      </div>
      <div
        className={
          focused && isMobileOnly && !isIOS
            ? "chatInput z-2 items-center p-1 pr-2 keyboardExtra overflow-hidden"
            : "chatInput z-2 items-center pr-2 overflow-hidden"
        }
        style={{ minHeight: "7dvh" }}
      >
        <input
          type="text"
          ref={inputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder="Type something..."
          onChange={handleInputChange}
          className="w-full chatInputField ml-2 h-full"
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSend();
            }
          }}
        />
        <div className="flex px-3  items-center">
          <input
            type="file"
            style={{ display: "none" }}
            accept=".jpg, .jpeg, .png"
            id="file"
            onChange={(e) => {
              if (e?.target?.files) {
                if (e?.target?.files[0]?.type.startsWith("image/")) {
                  setImg(e?.target?.files[0]);
                } else {
                  toast.error("File format not supported!!");
                }
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSend();
              }
            }}
          />
          <div className="text-md text-gray-400">
            {text?.length ? text?.length : 0}/200
          </div>
          <label htmlFor="file">
            <div className="flex mx-2 text-xl text-[#804C31]">
              <i className="bi bi-image-fill"></i>
            </div>
          </label>
          <button onClick={handleSend} className="m-1 mr-0 text-[#804C31] ">
            <i className="bi bi-send-fill"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatInput;
