import React, { useEffect, useRef } from "react";
import { useChatContext } from "../../Context/ChatContext";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import { Image } from "antd";

interface MessageProps {
  message: any;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const { chatData } = useChatContext();
  const { userMatrimonyData } = useUserMatrimonyContext();
  /**
   * Scrolls the referenced HTML div element into view with a smooth animation.
   * @param {React.RefObject<HTMLDivElement>} ref - The reference to the HTML div element.
   * @param {any} message - The message that triggers the scroll effect.
   * @returns None
   */
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [message]);

  return (
    <div
      className={`message ${
        message?.senderId === userMatrimonyData?.matrimonyId && "owner"
      }`}
    >
      <div className="messageInfo">
        <img
          src={
            message?.senderId === userMatrimonyData?.matrimonyId
              ? userMatrimonyData?.profileUrl?.length > 0
                ? userMatrimonyData?.profileUrl[0]
                : profilePlaceholder
              : chatData?.profileUrl?.length > 0
              ? chatData?.profileUrl[0]
              : profilePlaceholder
          }
          alt=""
          className="chatImg"
        />
      </div>

      <div
        className="messageContent"
        ref={ref as React.RefObject<HTMLDivElement>}
      >
        {message?.text?.length > 0 && <p className="text">{message?.text}</p>}
        {message?.img && (
          <Image
            src={message?.img}
            alt=""
            style={{ maxHeight: "300px" }}
            className="image"
          />
        )}
      </div>
      {/* <div>{message?.date.toString()}</div> */}
    </div>
  );
};

export default Message;
