import React,{useEffect} from 'react'
import LeftNav from '../Components/LeftNav/LeftNav'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { request } from '../Utils/request';

type Props = {}
// eslint-disable-next-line no-empty-pattern
function Connections({}: Props) {
  const navigate = useNavigate();

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

    if (!token?.length) {
      localStorage.clear()
      navigate("/login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex w-full  mt-4'>
    <LeftNav />
    <div>Connections</div>

  </div>
  )
}

export default Connections