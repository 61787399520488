import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const getColorForPercentage = (percentage:any) => {
  if (percentage > 70) {
    return ["#32CD32", "#E7E7E7"];
  } else if (percentage >= 40 && percentage <= 70) {
    return ["#F7C900", "#E7E7E7"];
  } else {
    return ["#FF5733", "#E7E7E7"];
  }
};

const MatchingDoughnutChart: React.FC<{ matchingPercentage: number }> = ({
  matchingPercentage,
}) => {
  const showData = Math.floor(matchingPercentage) + "%";
  const backgroundColor = getColorForPercentage(matchingPercentage);

  const data = {
    labels: [],
    datasets: [
      {
        data: [matchingPercentage, 100 - matchingPercentage],
        backgroundColor,
      },
    ],
    text: showData,
  };

  const options = {
    cutout: "80%",
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {enabled: false},
    },
  };

  return (
    <div className="matching-doughnut-chart">
      <div className="doughnut-chart-container" style={{position:"relative"}}>
        <Doughnut data={data} options={options} />
        <div className="matching-score" style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%, -50%)", fontWeight:"bold"}}>{showData}</div>
      </div>
    </div>
  );
};

export default MatchingDoughnutChart;
