import React, { useState, useEffect } from 'react';
import { InfoCircleOutlined} from '@ant-design/icons';
import './EmailVerificationAlert.css'; // Custom CSS for alert styling

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };
  

const EmailVerificationAlert = ({from}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = debounce(() => {
    const offset = window.scrollY; // Get the current scroll position
    const triggerPoint = from === "tabs" ? 90 : 70; // Set a scroll position where the alert becomes fixed
    console.log(offset,triggerPoint)
    // If user scrolls past the trigger point, make the alert fixed
    setIsFixed(offset > triggerPoint);
  }, 10); // Adjust the debounce delay as needed

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);


  return (
    // <div  className={`custom-alert custom-alert-warning email-verification-alert ${isFixed ? 'fixed' : ''}`}>
    <div className={`custom-alert custom-alert-warning email-verification-alert ${isFixed ? from === "tabs" ? 'tabsfixed' : 'fixed' : ''}`}>
      <div className="custom-alert-content">
        <div className="custom-alert-message">
            <div className='flex flex-row items-center'>
      <div className="custom-alert-icon">
        <InfoCircleOutlined />
      </div>
          <span>Email Verification Pending</span>
          </div>
          <button className="expand-button" onClick={toggleExpand}>
            {expanded ? <i className="bi bi-chevron-up"/> : <i className="bi bi-chevron-down"/>}
          </button>
        </div>
        <div className={`custom-alert-description ${expanded ? 'expanded' : ''}`}>
          <p>
            Verify your account by clicking on the link sent to your email.
          </p>
          <div style={{ marginTop: '10px' }}>
            Some actions are currently disabled until your email is verified, such as:
            <ul>
              <li>Access to dashboard features</li>
              <li>Profile updates</li>
              <li>Account-related notifications</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationAlert;
