import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import { Button, Modal, Form, Select, Input } from "antd";
import { TIMEAGO } from "../../Utils/timeago";
import "./AdminPage.css";
import { useNavigate } from "react-router";
import community from "../../assets/images/community-connect.jpg";

const { Search } = Input;
 
function AdminUserSettings() {

    const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [postType, setPostType] = useState("addMembers");
  const [memberStatus, setMemberStatus] = useState<any>([]);
  const [finalMemberStatus, setFinalMemberStatus] = useState<any>([]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [searchResultsWithOldData, setSearchResultWithOldData] = useState<
    any[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<any>([]);
  const [communities, setCommunities] = useState<any>([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const navigate= useNavigate();

    const items = [
        {
          key: "addMembers",
          label: `Communities`,
        },
        {
          key: "addPermission",
          label: `Members`,
        },
      ];



      const onChange = (value:any, index:any, id:any) => {
        // const findIndex = memberStatus.findIndex((item:any) => item.userId === id);
        if(memberStatus[id]?.lenght>0){
          
        const updatedStatuses = [...memberStatus];
          updatedStatuses[id] = {...updatedStatuses[id],
            block: value.toUpperCase()==="ACTIVE" ? false : true,
            userId:id,
        //   communityId: communityId
        };
          setMemberStatus(updatedStatuses);
          setFinalMemberStatus(updatedStatuses)
        }else{
          const newStatus = {
            block: value.toUpperCase() === "ACTIVE" ? false : true,
            userId: id,
            // communityId: communityId,
          };
          setMemberStatus({...memberStatus,[id]: newStatus});
          setFinalMemberStatus({...finalMemberStatus,[id]: newStatus});
        }
        
      };
      const memberPermissions = ["Active", "Blocked"];
      const communityPermissions = ["Active", "Blocked"];
      const { Option } = Select;

      const handleSearch = async (value: string) => {
        try {
          // const resp = await request(`/member/current/community/search?communityName=${value}`);
          const resp = await request(`/admin/users/${value}`);
          if (resp) {
            const datafetch = resp;
            // setShowSearchResults(true);
            setSearchResults(datafetch);
          } else {
            setShowSearchResults(false);
          }
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      };


  return (
    <div className="">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          <div className="tabPanelBox">
          <Search
            placeholder="Search User..."
            allowClear
            style={{ borderRadius: "20px" }}
            id="communitySearch"
            enterButton
            size="large"
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value);
              handleSearch(e.target.value);
            }}
            // onSearch={handleCommunitySearch}
          />
             {searchText?.length >= 1 && 
                <div className="flex justify-center adminUsersList">
            <div className="flex flex-col adminMembersListContainer">
            {searchResults?.map((member: any, index: any) => (
              <div className="flex flex-row items-center justify-between">
                                    <div
                                      key={index}
                                      className="flex flex-row items-center py-2 pl-5 justify-start"
                                    >
                                      {member?.profileUrl ? (
                                        <div
                                          className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                                          //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                          onClick={() =>
                                            navigate(
                                              `/profile/${member?.userId}`
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              member.profileColorCode,
                                          }}
                                        >
                                          <img src={member?.profileUrl} />
                                        </div>
                                      ) : (
                                        <div
                                          className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                                            index === 0 ? "" : "" // Apply green border to the first element
                                          }`}
                                          onClick={() =>
                                            navigate(
                                              `/profile/${member?.userId}`
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              member.profileColorCode,
                                          }}
                                        >
                                          <span
                                            className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                            style={{ color: "white",fontSize:"1.15rem" }}
                                            onClick={() =>
                                              navigate(
                                                `/profile/${member?.userId}`
                                              )
                                            }
                                          >
                                            <span className="memberSubstring">
                                              {member?.name?.substring(0, 1)}
                                            </span>
                                          </span>
                                        </div>
                                      )}

                                      <div
                                        className="flex items-center flex-col modalmemberName cursor-pointer"
                                        style={{ color: "black" }}
                                        onClick={() =>
                                          navigate(`/profile/${member?.userId}`)
                                        }
                                      >
                                        <span className="postMembertitle capitalize ml-2">
                                            <span className="modalmemberName">
                                              {member?.name}
                                            </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                    <Select
                                    defaultValue={member?.block ? "Blocked" : "Active"}
                                    className={
                                      (member?.block && memberStatus?.length === 0) ?  "blockedDropdown statusDropdown ml-5 searchbardiscover" : memberStatus[member?.userId]?.block ? "blockedDropdown statusDropdown ml-5 searchbardiscover" : " activeDropdown statusDropdown ml-5 searchbardiscover" }
                                   bordered={false}
          optionLabelProp="label"
          style={{ width: "100%" }}
          onChange={(value) => onChange(value, index,  member?.userId)}
        >
          {memberPermissions?.map((item:any) => (
            <Option key={item} value={item} label={item}>
              <div>{item === "Blocked" ? "Block" : `${item}`}</div>
            </Option>
          ))}
        </Select>
             
              </div>
                                    </div>
                                  ))}
            </div>
            </div>}
           {searchText?.length === 0 &&
            <div className="flex items-center justify-center w-full">
            <div className="flex flex-col justify-center placeholderText">
               <p>={">"} Search for a user by name or by emailId</p>
               <p>={">"} Update the user's visibility thoughout the application by selecting "ACTIVE" or "BLOCKED"</p>
            </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUserSettings;
