import React, { useState } from "react";
import "./AdminPage.css";
import ActiveCommunitiesComponent from "./ActiveCommunitiesComponent";
import BlockedCommunitiesComponent from "./BlockedCommunitiesComponent";


function AdminSettings() {

  const [postType, setPostType] = useState("activeCommunities");

    const items = [
        {
          key: "activeCommunities",
          label: `Active Communities`,
        },
        {
          key: "blockedCommunities",
          label: `Blocked Communities`,
        },
      ];

  return (
    <div className="">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          <div className="tabPanelBox">
          <div className="select-Dropdown pb-2">
              <div className="adminTabsWrapper">
                {items?.map((tabOptions: any) => (
                  <div
                    className={`${
                      tabOptions?.key === postType ? "activeTab" : ""
                    } optionsTab`}
                    onClick={() => {
                      setPostType(tabOptions?.key);
                    }}
                  >
                    <p>{tabOptions?.label}</p>
                  </div>
                ))}
              </div>
            </div>
         {postType === "activeCommunities" ?
         (
          <ActiveCommunitiesComponent/>
         )
               :(
                <BlockedCommunitiesComponent/>
               )
              }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSettings;
