import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import {  Button, Modal, Form, Select } from "antd";
import { TIMEAGO } from "../../Utils/timeago";
import "./AdminPage.css";
import community from "../../assets/images/community-connect.jpg";

function AdminLanding() {
  const [loading, setLoading] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [form] = Form.useForm();
  const [marimonyID, setMarimonyID] = useState("");
  const [kycPopup, setKycPopup] = useState(false);

  const getKycDatas = async () => {
    try {
      const response = await request("/admin/matrimony-profiles");
      setKycData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKycDatas();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const { documentType } = values;
      const requestBody = {
        method:'POST',
        body:JSON.stringify({
          matrimonyId: [marimonyID],
          docType: documentType,
        })
       
      };
      const response = await request(`/admin/set-kyc-status`, requestBody);
      if (response) {
        toast.success("KYC verified successfully!");
        getKycDatas();
        form.resetFields();
        setKycPopup(false);
        setMarimonyID("");
      }
    } catch (error) {
      toast.error("KYC verification failed!");
    }
  };
  return (
    <div className="">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol" style={{ width: "calc(100% - 40%)" }}>
          <div className="tabPanelBox">
            <section className="">
              <div
                className="thumbnailRowForThree requestbox"
                style={{ display: "inline" }}
              >
                {loading ? (
                  <div className="loader mt-20">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {!kycData?.length ? (
                      <div className="text-xl font-medium w-full">
                        <div className="text-xl font-medium text-center">
                          <div className="flex items-center justify-center noRequestContainer">
                            <div className="text-gray-400 noRequestsText">
                              {"No KYC data found!"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="p-10 pr-0">
                        {kycData?.map((data: any, index) => (
                          <div
                            className="thumbnailRowForThreeCol"
                            key={index}
                            style={{
                              display: "flex",
                              padding: "0 10px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {data?.profileUrl[0] ? (
                              <img
                                src={data?.profileUrl[0]}
                                alt=""
                                className="admin-kyc-avatar"
                              />
                            ) : (
                              <img
                                src={community}
                                alt=""
                                className="admin-kyc-avatar"
                              />
                            )}

                            <div className="thumbnailDescription fontSize !text-black">
                              <span>
                                <strong className="text-[20px] font-medium">
                                  {data?.member?.name}
                                </strong>
                                <div className="mt-1 text-gray-700">
                                  {data?.emailId}
                                </div>
                                <div className="mt-2">
                                  <TIMEAGO timestamp={data?.createdAt || ""} />
                                </div>
                              </span>
                            </div>
                            <div className="buttonRow">
                              <Button
                                className="approve-btn border border-green-500 text-green-500  font-medium m-1"
                                onClick={() => {
                                  setMarimonyID(data?.matrimonyId);
                                  setKycPopup(true);
                                }}
                              >
                                Verify
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>

      {kycPopup && (
        <Modal
          className="kyc-modal-popup"
          title="Verify KYC"
          open
          onCancel={() => {
            form.resetFields();
            setKycPopup(false);
            setMarimonyID("");
          }}
          footer
        >
          <div className="relative">
            <Form
              form={form}
              onFinish={onFinish}
              className={`postForm`}
              name="createForm"
              labelCol={{ span: 8 }}
            >
              <Form.Item
                className="FeedsLabelName"
                label="Document Type"
                name="documentType"
                rules={[{ required: true, message: "Select document type " }]}
              >
                <Select
                  className="FeedsDropDown"
                  placeholder="select document type"
                >
                  <Select.Option className="selectOptions" value={"AADHAAR"}>
                    <span>Aadhar Card</span>
                  </Select.Option>
                  <Select.Option className="selectOptions" value={"PAN"}>
                    <span className="categoryDropdown">PAN Card</span>
                  </Select.Option>
                  <Select.Option className="selectOptions" value={"VOTER"}>
                    <span className="categoryDropdown">Voter ID</span>
                  </Select.Option>
                  <Select.Option className="selectOptions" value={"DL"}>
                    <span className="categoryDropdown">Driving License</span>
                  </Select.Option>
                </Select>
              </Form.Item>
              <div className="wrapbuttons">
                <Form.Item wrapperCol={{ offset: 20 }}>
                  <Button
                    className="popupButton postbutton"
                    type="primary"
                    htmlType="submit"
                  >
                    Verify
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AdminLanding;
