import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Modal } from "antd";
import { Button, Form, Input, Select, Switch, ConfigProvider } from "antd";
import TextArea from "antd/es/input/TextArea";
import { request } from "../../Utils/request";
import "./CreateCommunityPopup.css";
import leftArrow from "../../assets/newUiImages/leftArrow.png";
import axios from "axios";
import Loader from "../Loader/Loader";
import * as Survey from "survey-react";
import { type } from "os";
import { stringify } from "querystring";
import { useNavigate } from "react-router";
import { isMobile } from "../../Utils/mobileSettings";
import { toast } from "react-toastify";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { getToken } from "../../Utils/timeago";
import { multiFormRequest } from "../../Utils/request";
import Compressor from "compressorjs";

interface createCommunityPopupProps {
  onCancelClick: Function;
  communityCategory: any[];
  onSaveClick: Function;
  // religionForm:any;
  isOpen: boolean; // Add isOpen prop to control the popup's visibility
}

const CreateCommunityPopup = (props: createCommunityPopupProps) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [form] = Form.useForm();
  const [selectedcategoryid, setselectedcategoryid] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [imageName, setImageName] = useState<string>("");
  const [bannerImageName, setBannerImageName] = useState<string>("");
  const hiddenBannerFileInput = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState({});
  const [image, setImage] = useState("");
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [postByAdmin, setPostByAdmin] = useState(false);
  const [type, setType] = useState("");
  const [profileImage, setProfileImage] = useState<string>("");
  const [bannerImage, setBannerImage] = useState<string>("");
  const [saveDisable, setSaveDisable] = useState(true);
  const [religionformData, setReligionformData] = useState();
  const [imageLoading, setImageLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getToken();
  }, []);

  const categoriesOptions = props?.communityCategory?.map((category: any) => (
    <>
      <Select.Option
        className="selectOptions"
        key={category?.id}
        value={category?.id}
        // onChange={(category) => handleCategorySelect(category.formData)}
      >
        {category.name}
      </Select.Option>
    </>
  ));

  const handleselectchange = (value: any) => {
    setselectedcategoryid(value);
  };

  const handleBannerImageClick = () => {
    hiddenBannerFileInput?.current?.click();
  };
  const HandleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const onFinish = async (values: any) => {
    let imageFormData = new FormData();

    if (selectedFiles) {
      selectedFiles.forEach((item) => {
        imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
      });
    }

    const id = localStorage.getItem("id");
    if (id) {
      const blob = new Blob(
        [
          JSON.stringify({
            name: values.name,
            description: values.description,
            type: values.type,
            adminId: id,
            categoryId: selectedcategoryid,
            postsOnlyByAdmin: values.postsOnlyByAdmin,
          }),
        ],
        {
          type: "application/json",
        }
      );
      imageFormData.append("data", blob);
    }

    setLoader(true);

    const nameAbusiveWord = await CheckBadWordsExist(values?.name);
    const descriptionAbusiveWord = await CheckBadWordsExist(
      values?.description
    );
    if (nameAbusiveWord?.status && descriptionAbusiveWord?.status) {
      if (
        !nameAbusiveWord?.profanity &&
        !descriptionAbusiveWord?.profanity &&
        !descriptionAbusiveWord?.words?.length &&
        !nameAbusiveWord?.words?.length
      ) {
        try {
          // const response = await axios.post(
          //   `${BASE_URL}/member/current/community`,
          //   imageFormData,
          //   {
          //     headers: {
          //       "Content-Type": "multipart/form-data",
          //       Authorization: `Bearer ${token}`,
          //     },
          //   }
          // );

          const response: any = await multiFormRequest(
            `/member/current/community`,
            imageFormData,
            "post"
          );
          if (response) {
            const CustomToast = () => (
              <div>
                Community created!{" "}
                <span
                  onClick={() => {
                    navigate(`/communityPreview/${response?.data?.id}`,
                      {
                        state: {from : 0}
                      }
                    );
                  }}
                  style={{ cursor: "pointer", color: "brown" }}
                >
                  Click here
                </span>{" "}
                to view
              </div>
            );
            props.onSaveClick();
            toast.success(<CustomToast />);
            setLoader(false);
          } else {
            setLoader(false);
          }
        } catch (error) {
          console.error("Error:", error);
          setLoader(false);
        }
      } else if (
        nameAbusiveWord?.profanity &&
        descriptionAbusiveWord?.profanity
      ) {
        setLoader(false);
        toast.error("Prohibited from generating abusive content");
      } else {
        setLoader(false);
        if (nameAbusiveWord?.profanity) {
          toast.error(
            "Disallowed from including abusive terms in the community name"
          );
        } else if (descriptionAbusiveWord?.profanity) {
          toast.error(
            "Disallowed from producing offensive content in the description"
          );
        }
      }
    } else {
      setLoader(false);
      toast.error(
        `${
          nameAbusiveWord.status === false
            ? nameAbusiveWord?.message
            : descriptionAbusiveWord?.message
        }`
      );
    }
  };
  useEffect(() => {
    const religionCategory = props?.communityCategory.find(
      (category) => category.name === "Religion community"
    );

    if (religionCategory) {
      const formData = religionCategory.formData;
      setReligionformData(formData);
    }
  }, []);

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = Array.from(event.target.files ?? []);

    if (files && files[0]) {
      setImageLoading(true);
      if (
        !(
          files[0]?.type?.includes("image/") ||
          files[0]?.type?.includes("video/")
        )
      ) {
        setImageLoading(false);
        toast.error("Invalid format");
        return;
      }

      if (files[0].size > 1024 * 1024) {
        new Compressor(files[0], {
          quality: 0.6,
          success: (result: any) => {
            if (type === "profileImage") {
              setImageName(result.name);
              setProfileImage(URL.createObjectURL(result));
              form.setFieldsValue({ imageName: result.name });
            }

            if (type === "bannerImage") {
              setBannerImageName(result.name);
              setBannerImage(URL.createObjectURL(result));
              form.setFieldsValue({ bannerImageName: result.name });
            }

            // Replace the existing image data in selectedFiles or add a new image data
            const updatedFiles = selectedFiles.filter(
              ([fileType]) => fileType !== type
            );
            updatedFiles.push([type, result]);
            setSelectedFiles(updatedFiles);
            setImageLoading(false);
          },
          error: (err: any) => {
            setImageLoading(false);
            console.error(err.message);
            toast.error("File upload failed. Please try again later.");
          },
        });
      } else {
        if (type === "profileImage") {
          setImageName(files[0].name);
          setProfileImage(URL.createObjectURL(files[0]));
          form.setFieldsValue({ imageName: files[0].name });
        }

        if (type === "bannerImage") {
          setBannerImageName(files[0].name);
          setBannerImage(URL.createObjectURL(files[0]));
          form.setFieldsValue({ bannerImageName: files[0].name });
        }

        // Replace the existing image data in selectedFiles or add a new image data
        const updatedFiles = selectedFiles.filter(
          ([fileType]) => fileType !== type
        );
        updatedFiles.push([type, files[0]]);
        setSelectedFiles(updatedFiles);
        setImageLoading(false);
      }
    }
  };

  const onBannerImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = Array.from(event.target.files ?? []);

    setSelectedFiles([...selectedFiles, [type, files[0]]]);

    if (event.target.files && event.target.files[0]) {
      setBannerImageName(event?.target?.files[0]?.name);
    }
  };

  const handleReligionForm = () => {
    let obj = form.getFieldsValue();
    if (isMobile) {
      navigate("/survey", {
        state: {
          formData: obj,
          religionformData: religionformData,
          selectedFiles,
          selectedcategoryid,
        },
      });
    } else {
      setShowSurvey(true);
      setName(obj.name);
      setValues(obj);
    }
  };

  const postSurveyData = async (imageFormData: any) => {
    try {
      const response = await multiFormRequest(
        `/member/current/community`,
        imageFormData,
        "post"
      );
      if (response) {
        props.onSaveClick();
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };

  const completeSurvey = (survey: any) => {
    // props?.onSaveClick();
    // props?.onCancelClick();

    let sur = JSON.stringify(survey.valuesHash);
    let imageFormData = new FormData();

    if (selectedFiles) {
      selectedFiles.forEach((item) => {
        imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
      });
    }

    const id = localStorage.getItem("id");
    if (id) {
      const blob = new Blob(
        [
          JSON.stringify({
            name: name,
            description: description,
            type: type,
            adminId: id,
            categoryId: selectedcategoryid,
            postsOnlyByAdmin:postByAdmin,
            surveyJSFormData: {
              communityData: sur,
            },
            // formData: JSON from surveyJS
          }),
        ],
        {
          type: "application/json",
        }
      );
      imageFormData.append("data", blob);
    }

    setLoader(true);

    postSurveyData(imageFormData);
    // try {
    //   const response = await axios.post(`${BASE_URL}/member/current/community`, imageFormData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   if (response) {
    //     props.onCancelClick();
    //     props?.onSaveClick()
    //     setLoader(false)
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   setLoader(false)

    // }
  };

  const formValidation = () => {
    const communityName = form?.getFieldValue("name");
    const description = form?.getFieldValue("description");
    const type = form?.getFieldValue("type");
    const categoryId = form?.getFieldValue("communityCategory");
    const imageName = form?.getFieldValue("imageName");
    const bannerImageName = form?.getFieldValue("bannerImageName");

    // Enable buttons if all mandatory fields are filled
    if (communityName && description && type && categoryId && imageName) {
      setSaveDisable(false);
    } else {
      setSaveDisable(true);
    }
  };
  useEffect(() => {
    // Add formValidation to validate the form whenever the form values change
    formValidation();
  }, [form.getFieldsValue()]);

  return (
    <div className="communityPopup1" id="community1">
      <Modal
        title={<div className="modalTitle">Create Community</div>}
        style={{ top: "5px", position: "relative" }}
        // centered
        open
        onOk={() => {}}
        onCancel={() => {
          props.onCancelClick();
        }}
        footer
        className="modalPopup communitypop"
      >
        <div className="relative">
          {(loader || imageLoading) && (
            <div className="loader">
              <Modal className="loaderModal" open centered footer>
                <Loader />
              </Modal>
            </div>
          )}
          {showSurvey ? (
            <div>
              <Survey.Survey
                json={religionformData}
                onComplete={completeSurvey}
                showCompletedpage={false}
              />
            </div>
          ) : (
            <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#7d8742",
                borderRadius: 10,
                colorPrimaryBorder: "#7d8742",
              },
            }}
          >
            <Form
              className="communityForm"
              onFinish={(values) => {
                onFinish(values);
              }}
              form={form}
              name="createForm"
            >
              <Form.Item
                className="labelName"
                style={{ marginBottom: 16 }}
                label="Community Name:"
                name="name"
                rules={[{ required: true, message: "Enter Community Name" }]}
              >
                <Input
                  onChange={(e) => setName(e.target.value)}
                  className="inputBox popupWidth"
                  style={{ width: "100%" }}
                  maxLength={200}
                />
              </Form.Item>
              {name?.length > 0 && (
                <p className="remainingCharacters">{name?.length} / 200</p>
              )}
              <div>
                <Form.Item
                  className="labelName community-image "
                  style={{ marginBottom: 16 }}
                  label="Community Image:"
                  name="imageName"
                  rules={[{ required: true, message: "Add Profile Image" }]}
                >
                  {/* <Input
                  className="inputBox inputBoxForImage"
                  style={{ width: "100%" }}
                  value={imageName}
                /> */}
                  <span
                    onClick={HandleClick}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {profileImage ? (
                      <img
                        src={profileImage}
                        className="EditProfileImage"
                        onClick={HandleClick}
                        alt=""
                      />
                    ) : (
                      <i
                        className="bi bi-camera-fill w-[150px] h-[150px] text-9xl text-gray-400 pointer"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          color: "silver",
                        }}
                      ></i>
                    )}
                  </span>
                  {isMobile ? (
                    <Button
                      className="float-right"
                      type="primary"
                      onClick={HandleClick}
                    >
                      <i className="bi bi-camera-fill"></i>
                      <input
                        onChange={(e: any) => {
                          onImageChange(e, "profileImage");
                        }}
                        ref={hiddenFileInput}
                        accept="image/png, image/jpeg, image/jpg"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      className="BrowseButton popupImageButton  float-right"
                      type="primary"
                      onClick={HandleClick}
                    >
                      Browse
                      <input
                        onChange={(e: any) => {
                          onImageChange(e, "profileImage");
                        }}
                        ref={hiddenFileInput}
                        accept="image/png, image/jpeg, image/jpg"
                        type="file"
                        style={{ display: "none" }}
                      />
                    </Button>
                  )}
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className="labelName community-image ml-[2px]"
                  style={{ marginBottom: 16 }}
                  label="Community Banner Image:"
                  name="bannerImageName"
                  // rules={[{ required: true, message: "Add Banner Image" }]}
                >
                  {/* <Input
                  className="inputBox inputBoxForImage"
                  style={{ width: "100%" }}
                  value={bannerImageName}
                /> */}
                  <span
                    onClick={handleBannerImageClick}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {bannerImage ? (
                      <img
                        src={bannerImage}
                        style={{ width: "100%" }}
                        className="EditProfileImage"
                        onClick={HandleClick}
                        alt=""
                      />
                    ) : (
                      <i
                        className="bi bi-camera-fill w-[150px] h-[150px] text-9xl text-gray-400 pointer"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          color: "silver",
                        }}
                      ></i>
                    )}
                  </span>
                  {isMobile ? (
                    <Button
                      className="popupImageButton float-right"
                      type="primary"
                      onClick={handleBannerImageClick}
                    >
                      <i className="bi bi-camera-fill"></i>
                      <input
                        onChange={(e: any) => onImageChange(e, "bannerImage")}
                        ref={hiddenBannerFileInput}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        style={{ display: "none" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      className="BrowseButton popupImageButton float-right"
                      type="primary"
                      onClick={handleBannerImageClick}
                    >
                      Browse
                      <input
                        onChange={(e: any) => onImageChange(e, "bannerImage")}
                        ref={hiddenBannerFileInput}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        style={{ display: "none" }}
                      />
                    </Button>
                  )}
                </Form.Item>
              </div>

              <Form.Item
                className="labelName"
                style={{ marginBottom: 16 }}
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description" }]}
              >
                <TextArea
                  onChange={(e) => setDescription(e.target.value)}
                  className="communityDescription popupWidth"
                  style={{ width: "100%" }}
                  maxLength={500}
                />
              </Form.Item>
              {description?.length > 0 && (
                <p className="remainingCharacters">
                  {description?.length} / 500
                </p>
              )}
              <Form.Item
                className="labelName"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Community type:"
                name="type"
                rules={[{ required: true, message: "Select community type" }]}
              >
                <Select
                  onChange={(e) => setType(e)}
                  className="communityDropDown popupWidth"
                >
                  <Select.Option className="selectOptions" value="PRIVATE">
                    Private
                  </Select.Option>
                  <Select.Option className="selectOptions" value="PUBLIC">
                    Public
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                className="labelName popupWidth ml-[2px]"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Community Category:"
                rules={[
                  { required: true, message: "Select community category" },
                ]}
                name="communityCategory"
              >
                <Select
                  className="communityDropDown popupWidth"
                  onChange={handleselectchange}
                  value={selectedcategoryid}
                >
                  {categoriesOptions}
                </Select>
              </Form.Item>

              <Form.Item
                className="labelName popupWidth ml-[2px]"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Allow only admin to post:"
                name="postsOnlyByAdmin"
              >
                <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className="communityDropDown1 popupWidth1 w-14 bg-[#804d31]"
                              defaultChecked={false}
                              onChange={()=>setPostByAdmin(!postByAdmin)}
                            />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 21 }}>
                {selectedcategoryid === "64ddeb5af08a53232f530983" ? (
                  <Button
                    className="popupButton"
                    type="primary"
                    disabled={saveDisable}
                    onClick={handleReligionForm}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    className="popupButton"
                    type="primary"
                    htmlType="submit"
                    disabled={saveDisable}
                  >
                    Create
                  </Button>
                )}
              </Form.Item>
            </Form>
            </ConfigProvider>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CreateCommunityPopup;
