import moment from "moment";
import "moment-timezone";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { clearCache } from "./request";

export const TIMEAGO = ({ timestamp }) => {
  const newDate = moment.utc(timestamp).toDate();
  let res = moment(newDate).fromNow();
  if (res === "a minute ago") {
    res = "Just now";
  }

  return (
    <div>
      <span className="time-ago-format">{res}</span>
    </div>
  );
};

export const getExpireDate = (time) => {
   const displayTime =   moment.tz(time, 'Asia/Kolkata').tz(moment.tz.guess()).format('lll');
  //  const displayTime = moment.tz(time, 'Asia/Kolkata')
  // .tz(moment.tz.guess())
  // .format('MMM D, YYYY hh:mm A');

  return displayTime;
};

export const getToken = () => {
  try {
    return new Promise(async (resolve, reject) => {
      const user = await new Promise((innerResolve, innerReject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          async (user) => {
            unsubscribe(); // Unsubscribe after the first call
            if (user) {
              const token = await user.getIdToken(true);
              localStorage.setItem("token", token);
              resolve(user);
            } else {
              localStorage.clear();
              clearCache();
              window.location.href = "/login";
              // resolve(null);
              reject(null);
            }
          },
          innerReject
        );
      });

      if (user) {
        resolve(user.getIdToken());
      } else {
        localStorage.clear();
        clearCache();
        window.location.href = "/login";
        reject("User not authenticated.");
      }
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const getDateAndTime = (date) => {
  console.log("date", date);
  const currentTime = moment().format("HH:mm:ss");
  const combinedDateTime = moment(
    `${date} ${currentTime}`,
    "DD-MM-YYYYTHH:mm:ss"
  ).format("YYYY-MM-DDTHH:mm:ss");
  return combinedDateTime;
};

export const userName = "chandana";
