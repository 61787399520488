/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { TIMEAGO } from "../../Utils/timeago";
import NoRequest from "../RequestComponent/NoRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";
import { useNavigate } from "react-router";
type Props = {};

const MatrimonyNotification = (props: Props) => {
  const [matrimonyNotification, setMatrimonyNotification] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getMatrimonyNotification = async (page?: number) => {
    const resp = await request(
      `/member/current/matrimony-profile/requested/profile/details?page=${page}&size=10`
    );
    if (page === 0) {
      setMatrimonyNotification(resp?.content);
      setLoading(false);
    } else if (resp?.content.length) {
      setMatrimonyNotification([...matrimonyNotification, ...resp?.content]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMatrimonyNotification(currentPage);
  }, [currentPage, toggleRefresh]);

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    setToggleRefresh(!toggleRefresh);
  };

  const navigate = useNavigate();

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div>
        {loading ? (
          <div>
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          </div>
        ) : (
          <>
            {!matrimonyNotification.length ? (
              <div className="text-xl font-medium">
                <NoRequest message="No Notifications" />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={matrimonyNotification?.length}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={true}
                loader={<></>}
              >
                <div className="thumbnailRowForThree1 requestbox">
                  {matrimonyNotification?.map((data: any) => (
                    <div className="notificationRow" key={data.id}>
                      <div className="flex flex-col">
                        {data?.request !== "VISIBILITY_CHANGED" ? (
                          <div className="thumbnailDescription1 notificationDescription !text-black text-base">
                            Your request to view more details of{" "}
                            <span
                              className="font-bold text-[#6d3a21] ml-1 mr-1 cursor-pointer"
                              onClick={() => {
                                if (!data?.archive) {
                                  navigate(
                                    `/matrimony/matrimony-profile/${data?.matrimonyProfileId}`
                                  );
                                }
                              }}
                            >
                              {" "}
                              {data?.profileName}{" "}
                            </span>
                            {data?.request === "APPROVE" ? (
                              <strong className="successText">
                                has been approved.
                              </strong>
                            ) : data?.request === "REJECT" ? (
                              <strong className="rejectedText">
                                {data?.reason ? (
                                  <>
                                    has been rejected due to the reason -{" "}
                                    {data?.reason}.
                                  </>
                                ) : (
                                  <>has been rejected.</>
                                )}
                              </strong>
                            ) : (
                              data?.request === "REQUEST" && (
                                <strong className="text-blue-800">
                                  has been sent.
                                </strong>
                              )
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="thumbnailDescription1 notificationDescription !text-black text-base">
                              <span
                                className="font-bold text-[#6d3a21] ml-1 mr-1 cursor-pointer"
                                onClick={() =>{
                                   if (!data?.archive) {
                                    navigate(
                                      `/matrimony/matrimony-profile/${data?.matrimonyProfileId}`
                                    );
                                  }
                                }
                                }
                              >
                                {" "}
                                {data?.profileName}{" "}
                              </span>
                              {data?.reason?.toLowerCase()}.{" "}
                            </div>
                          </>
                        )}

                        <div className="ml-2 notificationDescription">
                          <TIMEAGO timestamp={data?.createdAt} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </PullToRefreshComponent>
  );
};

export default MatrimonyNotification;
