/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { request } from "../../Utils/request";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import NoRequest from "../RequestComponent/NoRequest";
import "./Notification.css";
import Loader from "../Loader/Loader";
import { TIMEAGO } from "../../Utils/timeago";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {};

const CommunityNotification = (props: Props) => {
  const navigate = useNavigate();
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [communityNotification, setCommunityNotification] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const getCommunityNotificationData = async (page?: number) => {
    const resp = await request(
      `/member/current/my/membership/requests?page=${page}&size=10`
    );
    if (resp.content.length === 0) {
      setHasMore(false);
    }
    if (page === 0) {
      setCommunityNotification(resp?.content);
      setLoading(false);
    } else if (resp?.content.length) {
      setCommunityNotification([...communityNotification, ...resp?.content]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunityNotificationData(currentPage);
  }, [currentPage, toggleRefresh]);

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    setToggleRefresh(!toggleRefresh);
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div className="w-full h-full">
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {!communityNotification.length ? (
              <div className="text-xl font-medium">
                <NoRequest message="No Notifications" />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={communityNotification?.length}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={hasMore}
                loader={<></>}
              >
                <div className="thumbnailRowForThree1 requestbox1">
                  {loading && (
                    <div className="loader ">
                      <div className="flex items-center justify-center">
                        <Loader />
                      </div>
                    </div>
                  )}
                  {communityNotification?.map((data: any, index: number) => (
                    <div className="notificationRow" key={index}>
                      <div className="flex1 flex-col1">
                        <div className="thumbnailDescription1 notificationDescription !text-black">
                          {data?.status === "APPROVE" && data?.adminAdded && (
                            <span>You have been added to</span>
                          )}
                          {data?.status === "BLOCKED" ? (
                            <span>
                              You have been{" "}
                              <strong className="rejectedText">blocked</strong>{" "}
                              from
                            </span>
                          ) : data?.status === "DELETED" ? (
                            "The community"
                          ) : (
                            (data?.status === "APPROVE" ||
                              data?.status === "REQUEST" ||
                              data?.status === "REJECT") &&
                            !data?.adminAdded &&
                            "Your request to join"
                          )}
                          <span
                            onClick={() => {
                              if (!data?.archive){
                              navigate(`/communityPreview/${data.communityId}`, {
                                state: {communityMemberStatus:"othercommunity", from : 0}
                              });}
                            }}
                            className={data?.status === "DELETED" ? "font-bold text-[#6d3a21] ml-1 " :"font-bold text-[#6d3a21] ml-1 cursor-pointer"}
                          >
                            {" "}
                            {data?.communityName}{" "}
                          </span>
                          {data?.status === "DELETED" && (
                            <strong className="rejectedText">has been deleted.</strong>
                          )}
                          {data?.status === "BLOCKED" && data?.reason && (
                            <>
                              due to the reason -{" "}
                              <strong>{data?.reason}</strong>.
                            </>
                          )}
                          {data?.status === "APPROVE" ? (
                            data?.adminAdded ? (
                              <span>by the community admin.</span>
                            ) : (
                              <strong className="successText">
                                has been approved.
                              </strong>
                            )
                          ) : data?.status === "REJECT" ? (
                            <strong className="rejectedText">
                              {data?.reason ? (
                                <>
                                  has been rejected due to the reason -{" "}
                                  {data?.reason}.
                                </>
                              ) : (
                                <>has been rejected.</>
                              )}
                            </strong>
                          ) : (
                            data?.status === "REQUEST" && (
                              <strong className="text-blue-800">
                                has been sent.
                              </strong>
                            )
                          )}
                        </div>
                        <div className="ml-2 notificationDescription">
                          <TIMEAGO timestamp={data?.createdAt} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </PullToRefreshComponent>
  );
};

export default CommunityNotification;
