import React, { useEffect,useState } from "react";
import loginLogo from "../assets/LoginImages/LoginLogo.svg";
import {toast} from 'react-toastify'
import loginFamilyPic from "../assets/LoginImages/emailVerified.png";
import { NavLink, useNavigate } from "react-router-dom";
import "./EmailRedirect.css";
import axios from "axios";
import { request } from "../Utils/request";
import { isMobile } from "../Utils/mobileSettings";
// import { BASE_URL } from "../Constants";

interface emailProps {
  // parentCallback: Function;
}

function EmailVerified(props: emailProps) {
  const navigate = useNavigate();
  const [message,setMessage] = useState<any>('') 


  const getEmailVerified = async () => {
    const BASE_URL =  process.env.REACT_APP_BASE_URL
    const path = window.location.pathname
    console.log('path',path)
    const newPath  = window.location.href

    console.log('path1',newPath)
    const token = newPath.split('token=')[1];
    console.log('token',token)
    // const resp = await request(`/member/verify-email?token=${token}`,body)
    try {
    const resp = await axios.post(`${BASE_URL}/member/verify-email?token=${token}`)
    console.log('resp',resp)
    setMessage(resp?.data)
    toast.success(resp?.data)
    } catch(error : any) {
      console.log('error',error)
      toast.error(error?.response?.data?.message)
      setMessage(error?.response?.data?.message || 'Email not yet been verified')
    }

    


  }

  useEffect(() => {
   

 getEmailVerified()

 
  },[])

  return (
    // <div className="EmailRedirectWrapper">
    //   <div className="emailContentWrapper">
    //     <div className="emailLogoAndTittleWrapper">
    //       <img
    //         className="cursor-pointer"
    //         onClick={() => {
    //           navigate("/");
    //           props.parentCallback("/");
    //         }}
    //         alt=""
    //         src={loginLogo}
    //       />
    //       <span className="logoText">VamshaVruksha</span>
    //     </div>
    //     <div>
    //       <span>Your email address has been verified </span>
    //       <p>
    //         Click year to{" "}
    //         <span
    //           onClick={() => {
    //             navigate("/login");
    //             props.parentCallback();
    //           }}
    //         >
    //           login
    //         </span>
    //       </p>
    //     </div>
    //   </div>
    // </div>
    <div className="LoginWrapper">
      <div className="logincontainerWithOutSideBar loginPage">
        <div className="LoginMainContentCol">
          <div className="loginRow">
            <div className="loginImageCol loginCol-L">
              <img className="Login-L-Img" src={loginFamilyPic} alt="" />
            </div>
            <div className="loginCol loginCol-L">
              <div className="loginColWrapper">
                <div className="logoAndTittleWrapper">
                  <img
                    alt=""
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/");
                      // props.parentCallback("/");
                    }}
                    src={loginLogo}
                  />
                  <span className="logoText">VamshaVruksha</span>
                </div>
                <div className="">
                  <p className="emailText">
                    {/* Your email address has been verified */}
                    {message}
                  </p>
                  {isMobile ? (
                    <p className="emailText">
                      For a better experience, please use the app to log in or continue from a desktop browser.
                    </p>
                  ) : (
                    <p className="emailText">
                      Click here to{" "}
                      <span
                        className="pointer"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Login
                      </span>
                    </p>
                  )}
                </div>

                {/* <FaceBookSignIn /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img />
      </div>
      <div></div>
    </div>
  );
}

export default EmailVerified;
