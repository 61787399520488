import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import "./RejectComponent.css";

interface RejectPopupProps {
  onCancelClick: Function;
  onRejectClick: Function;
  // communityCategory: any[];
  // onSaveClick:Function;
}

const { TextArea } = Input;

const RejectComponent = (props: RejectPopupProps) => {
  const [textArea, setTextArea] = useState<string>("");
  return (
    <div>
      <Modal
        title="Reject Message"
        centered
        open
        onOk={() => {}}
        onCancel={() => {
          props.onCancelClick();
        }}
        footer={
          <Button
            className="rejectButton"
            onClick={() => {
              props.onRejectClick(textArea);
            }}
          >
            Reject
          </Button>
        }
        className="rejectModalPopup"
      >
        <div className="">
          <TextArea
            style={{ height: 120, resize: "none" }}
            className="rejectInputMessage"
            placeholder="Reason for rejecting"
            onChange={(e) => {
              setTextArea(e.target.value);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RejectComponent;
