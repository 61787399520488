import React,{useState} from 'react'
import * as Survey from "survey-react";
import {useNavigate,useLocation} from 'react-router'
import { multiFormRequest } from '../../Utils/request';
import Loader from '../Loader/Loader';
import './SurveyCreate.css'
import {Modal} from 'antd'


type Props = {}

const SurveyCreate = (props: Props) => {
const location = useLocation()
const navigate = useNavigate()

const [loader, setLoader] = useState(false);
    
       console.log('location',location.state.formData)
       const completeSurvey = async (survey: any) => {
        setLoader(true);

        console.log('survey',survey)
        const {selectedFiles,formData,selectedcategoryid} = location?.state
       
    
        let imageFormData = new FormData();
    
        if (selectedFiles) {
          selectedFiles.forEach((item:any) => {
            imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
          });
        }
    
        const id = localStorage.getItem("id");
        if (id) {
          const blob = new Blob(
            [
              JSON.stringify({
                name: formData?.name,
                description: formData?.description,
                type: formData?.type,
                adminId: id,
                categoryId: selectedcategoryid,
                surveyJSFormData: {
                  communityData: JSON.stringify(survey?.valuesHash),
                },
                // formData: JSON from surveyJS
              }),
            ],
            {
              type: "application/json",
            }
          );
          imageFormData.append("data", blob);

          try {
            const response = await multiFormRequest(
              `/member/current/community`,
              imageFormData,
              "post"
            );
            if (response) {
              setLoader(false);
              navigate(-1)
            }
          } catch (error) {
            console.error("Error:", error);
            setLoader(false);
          }

        }
           
      };
      
  return (
    <div>
         {loader && (
            <div className="loaderWrapper">
              <Loader />
            </div>
          )}
        {location?.state?.religionformData && 
        <div className='surveyForm'>
          <Survey.Survey
              json={location?.state?.religionformData}
              onComplete={completeSurvey}
              showCompletedpage={false}
            />
            </div>
        }
    </div>
  )
}

export default SurveyCreate


