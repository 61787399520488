/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { request } from "../../Utils/request";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import NoRequest from "../RequestComponent/NoRequest";
import "./Notification.css";
import Loader from "../Loader/Loader";
import { TIMEAGO } from "../../Utils/timeago";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {};

const AdminNotification = (props: Props) => {
  const navigate = useNavigate();
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [communityAdminNotification, setCommunityAdminNotification] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const getAdminNotificationData = async (page?: number) => {
    const resp = await request(`/member/community-admin/blocked-communities?page=${page}&size=10`);
    if (resp?.content?.length === 0) {
      setHasMore(false);
    }
    if (page === 0) {
        setCommunityAdminNotification(resp?.content);
      setLoading(false);
    } else if (resp?.content?.length) {
        setCommunityAdminNotification([...communityAdminNotification, ...resp?.content]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => { 
    getAdminNotificationData(currentPage);
  }, [currentPage, toggleRefresh]);

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    setToggleRefresh(!toggleRefresh);
  };
  return (
    <PullToRefreshComponent onRefresh={() => {handleRefresh()}}>
      <div className="w-full h-full">
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {!communityAdminNotification.length ? (
              <div className="text-xl font-medium">
                <NoRequest message="No Notifications" />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={communityAdminNotification?.length}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={hasMore}
                loader={<></>}
              >
                <div className="thumbnailRowForThree1 requestbox1">
                  {loading && (
                    <div className="loader ">
                      <div className="flex items-center justify-center">
                        <Loader />
                      </div>
                    </div>
                  )}
                  {communityAdminNotification?.map((data: any, index: number) => (
                    <div className="notificationRow" key={index}>
                      <div className="flex1 flex-col1">
                        <div className="thumbnailDescription1 notificationDescription !text-black">
                          {data?.status === "BLOCKED" ? (
                            <span>
                              Your community <span
                            onClick={() => {
                              navigate(`/communityPreview/${data.communityId}`,
                                {
                                  state: {from : 0}
                                }
                              );
                            }}
                            className="font-bold text-[#6d3a21] ml-1 mr-2 cursor-pointer"
                          >
                            {" "}
                            {data?.communityName}{" "}
                          </span> has been{" "}
                              <strong className="rejectedText">blocked</strong>{" "}
                              by the admin.
                            </span>
                          ) : (
                            "Your request to join"
                          )}
                          
                          {data?.status === "BLOCKED" && data?.reason && (
                            <>due to the reason - <strong>{data?.reason}</strong>.</>
                          )}
                          {data?.status === "APPROVE" ? (
                            <strong className="successText">
                              has been approved.
                            </strong>
                          ) : data?.status === "REJECT" ? (
                            <strong className="rejectedText">
                              {data?.reason ? (
                                <>
                                  has been rejected due to the reason -{" "}
                                  {data?.reason}.
                                </>
                              ) : (
                                <>has been rejected.</>
                              )}
                            </strong>
                          ) : (
                            data?.status === "REQUEST" && (
                              <strong className="text-blue-800">
                                has been sent.
                              </strong>
                            )
                          )}
                        </div>
                        <div className="ml-2 notificationDescription">
                          <TIMEAGO timestamp={data?.createdAt} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </PullToRefreshComponent>
  );
};

export default AdminNotification;
