import React, { useState, useEffect, useRef } from "react";
import { useChatContext } from "../../Context/ChatContext";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import Message from "./Message";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import "./MessageComponent.css";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";

function MessageComponent() {
  const [messages, setMessages] = useState([]);
  const { chatData } = useChatContext();
  const { userMatrimonyData } = useUserMatrimonyContext();

  const combinedId =
    userMatrimonyData?.matrimonyId > chatData?.matrimonyId
      ? userMatrimonyData?.matrimonyId + "_" + chatData?.matrimonyId
      : chatData?.matrimonyId + "_" + userMatrimonyData?.matrimonyId;

  const messagesContainerRef = useRef<HTMLDivElement>(null); // Add the type for ref

  useEffect(() => {
    // Scroll to the bottom when messages change
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
    // scrollToBottom();
  }, [messagesContainerRef]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      // Scroll to the bottom
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const authenticateUser = async () => {
      if (!userMatrimonyData?.matrimonyId) return;
      const resp = await request(
        `/member/current/authenticate/matrimony-profile/${userMatrimonyData?.matrimonyId}`
      );
      if (resp?.auth) {
        const res = await getDoc(doc(db, "users", chatData?.matrimonyId));
        if (!res.exists()) {
          toast.error(
            `${chatData?.member?.name} has deleted their matrimony profile`
          );
        }
        getMessages();
      }
    };

    const getMessages = () => {
      const unSub = onSnapshot(doc(db, "chats", combinedId), (doc) => {
        doc.exists() && setMessages(doc.data().messages);
      });

      return () => {
        unSub();
      };
    };

    chatData?.matrimonyId &&
      userMatrimonyData?.matrimonyId &&
      authenticateUser();
  }, [chatData]);

  return (
    <div className="messages overflow-y-auto" ref={messagesContainerRef}>
      {messages?.map((m, index) => (
        <Message message={m} key={index} />
      ))}
    </div>
  );
}

export default MessageComponent;
