import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import SignUp from "./Pages/SignUp";
import Header from "./Components/Header/Header";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs";
import TermsOfUse from "./Pages/TermsOfUse";
import News from "./Pages/News";
import LandingPage from "./Pages/LandingPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Matrimony from "./Pages/Matrimony";
import Feeds from "./Pages/Feeds";
import Requests from "./Pages/Requests";
import Community from "./Pages/Community";
import Connections from "./Pages/Connections";
import CommunityPreview from "./Pages/CommunityPreview";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Notifications from "./Components/Notifications/Notifications";
import UpdateProfile from "./Components/Profile/UpdateProfile";
import FeedsPreview from "./Components/Feeds/FeedsPreview";
import EmailVerified from "./Pages/EmailVerified";
import { isMobile } from "./Utils/mobileSettings";
import Footer from "./Components/Footer/Footer";
import { Suspense, useState, useEffect } from "react";
import Profile from "./Pages/Profile";
import MatrimonyProfile from "./Pages/MatrimonyProfile";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import Kyc from "./Pages/Kyc";
import Chat from "./Pages/Chat";
import ChatScreenMobile from "./Pages/ChatScreenMobile";
import AdminLanding from "./Components/Admin/AdminLanding";
import AdminSettings from "./Components/Admin/AdminSettings";
import AdminUserSettings from "./Components/Admin/AdminUserSettings";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import ResetPassword from "./Pages/ResetPassword";
import ErrorBoundary from "./Components/ErrorBoundry/ErrorBoundary";
import SurveyCreate from "./Components/Commuinty/SurveyCreate";
import RootSearch from "./Pages/RootSearch";
import { toast } from "react-toastify";
import { ConfigProvider } from "antd";

function App() {
  const login = useSelector((state: any) => state.login.isLogin);
  const toggleLeftNav = () => {
    setIsLeftNavVisible(!isLeftNavVisible);
  };
  // useEffect(() => {
  //   const registerServiceWorker = (retryCount = 3, retryDelay = 1000) => {
  //     if ("serviceWorker" in navigator) {
  //       // setTimeout(() => {
  //         navigator.serviceWorker
  //           .register("/firebase-messaging-sw.js")
  //           .then((registration) => {
  //             console.log("Firebase Service Worker registered with scope:", registration.scope);
  //           })
  //           .catch((error) => {
  //             console.error("Error registering Firebase Service Worker:", error);
  //           });
  //       // }, 3000); // 10 seconds delay
  //     }else{
  //     if (retryCount > 0) {
  //         console.log(`Retrying in ${retryDelay}ms... (${retryCount} retries left)`);
  //         setTimeout(() => registerServiceWorker(retryCount - 1, retryDelay), retryDelay);
  //       } else {
  //         console.warn("Failed  registering Firebase Service Worker after several retries");
  //       }
  //     }
  //   };

  //   const unRegisterServiceWorker = (retryCount = 3, retryDelay = 1000) => {
  //     if ("serviceWorker" in navigator) {
  //       navigator.serviceWorker.getRegistrations().then((registrations) => {
  //         if (registrations.length === 0) {
  //           console.warn("No service workers found to unregister.");
  //           return;
  //         }

  //         registrations.forEach((registration) => {
  //           registration.unregister().then((success) => {
  //             if (success) {
  //               console.log("Unregistration successful.");

  //               // Optionally, clear caches
  //               caches.keys().then((cacheNames) => {
  //                 cacheNames.forEach((cacheName) => {
  //                   caches.delete(cacheName).then((deleted) => {
  //                     if (deleted) {
  //                       console.log(`Cache ${cacheName} deleted successfully.`);
  //                     } else {
  //                       console.warn(`Cache ${cacheName} deletion failed.`);
  //                     }
  //                   });
  //                 });
  //               });

  //               // Set a flag to prevent service worker registration
  //               localStorage.setItem('swUnregistered', 'true');

  //               // Reload the page after successful unregistration and cache clearing
  //               setTimeout(() => {
  //                 window.location.reload();
  //               }, 1000); // Give some time to clear caches before reloading
  //             } else {
  //               console.warn("Unregistration failed.");
  //               if (retryCount > 0) {
  //                 console.log(`Retrying in ${retryDelay}ms... (${retryCount} retries left)`);
  //                 setTimeout(() => unRegisterServiceWorker(retryCount - 1, retryDelay), retryDelay);
  //               } else {
  //                 console.warn("Failed to unregister service worker after several retries.");
  //               }
  //             }
  //           });
  //         });
  //       }).catch((error) => {
  //         console.error("Error during service worker unregistration:", error);
  //       });
  //     } else {
  //       console.warn("Service workers are not supported in this browser.");
  //     }
  //   };

  //   if (typeof window?.AndroidJSBridge?.getDeviceToken !== 'function') {
  //     // toast.success("not android func")
  //     // alert("Not android")
  //   unRegisterServiceWorker();
  //   }
  // }, []);

  const [isLeftNavVisible, setIsLeftNavVisible] = useState(false);

  const WithLayout = () => {
    return (
      <div className="pageLayout">
        <div className="pageLayout-L">
          <Home
            isLeftNavVisible={isLeftNavVisible}
            toggleLeftNav={toggleLeftNav}
          />
        </div>
        <div className="pageLayout-R">
          <div
            className="pageLayoutHeader"
            style={{ zIndex: "999", position: "relative" }}
          >
            <div className={login === false ? " Appwrap" : "Appwrap-inner"}>
              {" "}
              <Header />{" "}
            </div>
          </div>
          <div className="pageLayoutContent">
            {/* <Suspense> */}
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
            {/* </Suspense> */}
          </div>

          <div className="pageLayoutFooter  showfooterdesktop">
            <Footer />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="h-full">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={5}
      ></ToastContainer>
      <BrowserRouter>
        <Suspense fallback={<h1></h1>}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#7d8742",
                borderRadius: 10,
                colorPrimaryBorder: "#7d8742",
              },
            }}
          >
            <Routes>
              <Route
                path={`${process.env.REACT_APP_NAVIGATION_URL}login`}
                element={<LoginComponent />}
              />

              <Route
                path={`${process.env.REACT_APP_NAVIGATION_URL}member/verify-email`}
                element={<EmailVerified />}
              />

              <Route
                path={`${process.env.REACT_APP_NAVIGATION_URL}forgot-password/reset`}
                element={<ResetPassword />}
              />

              <Route element={<WithLayout />}>
                <>
                  {isMobile ? (
                    <Route
                      path={`${process.env.REACT_APP_NAVIGATION_URL}`}
                      element={<Feeds />}
                    />
                  ) : (
                    <Route
                      path={`${process.env.REACT_APP_NAVIGATION_URL}`}
                      element={<LandingPage />}
                    />
                  )}
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}feeds`}
                    element={<Feeds />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}matrimony`}
                    element={<Matrimony />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}chat`}
                    element={<Chat />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}chat/chat-details`}
                    element={<ChatScreenMobile />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}matrimony/matrimony-profile/:id`}
                    element={<MatrimonyProfile />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}connections`}
                    element={<Connections />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}feeds/:pid`}
                    element={<FeedsPreview />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}news`}
                    element={<News />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}requests`}
                    element={<Requests />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}profile/:uid`}
                    element={<Profile />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}survey`}
                    element={<SurveyCreate />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}profile`}
                    element={
                      <UpdateProfile
                        onClose={() => {
                          localStorage.clear();
                        }}
                      />
                    }
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}kyc/:id`}
                    element={<Kyc />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}community`}
                    element={<Community />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}communityPreview/:id`}
                    element={<CommunityPreview />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}notification`}
                    element={<Notifications />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}admin`}
                    element={<AdminLanding />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}adminSettings`}
                    element={<AdminSettings />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}adminUserSettings`}
                    element={<AdminUserSettings />}
                  />

                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}rootSearch`}
                    element={<RootSearch />}
                  />

                  {isMobile ? (
                    <Route
                      path={`${process.env.REACT_APP_NAVIGATION_URL}`}
                      element={<Feeds />}
                    />
                  ) : (
                    <Route
                      path={`${process.env.REACT_APP_NAVIGATION_URL}`}
                      element={<LandingPage />}
                    />
                  )}
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}aboutUs`}
                    element={<AboutUs />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}signUp`}
                    element={<SignUp />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}privacyPolicy`}
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}termsOfUse`}
                    element={<TermsOfUse />}
                  />
                  <Route
                    path={`${process.env.REACT_APP_NAVIGATION_URL}ContactUs`}
                    element={<ContactUs />}
                  />
                </>
              </Route>
            </Routes>
          </ConfigProvider>
        </Suspense>
      </BrowserRouter>
    </main>
  );
}

export default App;
