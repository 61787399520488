import React from "react";
import { Button, Checkbox, DatePicker, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { request } from "../../Utils/request";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

type Props = {
  matrimonyID: any;
};

const PanKyc = (props: Props) => {
const BASE_URL =  process.env.REACT_APP_BASE_URL
  let token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const matrimonyID = props?.matrimonyID;
  const currentDate = dayjs();
  const navigate = useNavigate();
  const disabledDate = (current: any) => {
    return current && current.year() >= 2006;
  };

  const onFinish = async (values: any) => {
    try {
      const { panNo, fullname, dob, phoneNumber, termsCondition } = values;
      if (!termsCondition) {
        form.setFields([
          {
            name: "termsCondition",
            errors: ["Please accept the terms and conditions!"],
          },
        ]);
        return;
      } else {
        const requestBody = {
          method:'POST',
          body:JSON.stringify({
            docNumber: panNo,
            matrimonyId: matrimonyID,
            docTypeEnum: "PAN",
            fullName: fullname,
            dob: dob,
            mobileNumber: phoneNumber,
          })
        };
        const response = await request(`/member/verify-doc-type`,  requestBody );
        form.resetFields();
        console.log("PANkyc Values===>", values);
        if (response?.userId) {
          toast.success("KYC request sent to admin for approval!");
          navigate(-1)
        } else {
          toast.error("KYC request failed to send to admin approval!");
        }
      }
    } catch (error: any) {
      console.log("PANkyc Error===>", error);
      toast.error("KYC request failed to send to admin approval!");
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="kycForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="kycFormItem"
      >
        <Form.Item
          className="formitem"
          label="Pan No"
          name="panNo"
          rules={[
            {
              required: true,
              message: "Please enter your PAN number!",
            },
            {
              validator(_, value) {
                const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                if (panRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter valid PAN number!")
                );
              },
            },
          ]}
        >
          <Input className="h-11" maxLength={10} />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Full Name"
          name="fullname"
          rules={[{ required: true, message: "Please input your fullname!" }]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Date of Birth"
          name="dob"
          rules={[
            {
              required: true,
              message: "Please select your date of birth!",
            },
          ]}
        >
          <DatePicker
            className="h-11"
            format={"DD-MM-YYYY"}
            defaultPickerValue={currentDate.subtract(18, "year")}
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please enter your phone number!",
            },
          ]}
        >
          <Input className="h-11" type="number" />
          {/* <PhoneInput specialLabel="" country={"in"} /> */}
        </Form.Item>

        <Form.Item
          name="termsCondition"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions!",
            },
          ]}
        >
          <Checkbox>
            <span className="text-[20px]">Terms & Conditions</span>
            <span style={{ color: "#ff4d4f" }}>*</span>
          </Checkbox>
        </Form.Item>

        <div className="Save-btn">
          <Form.Item
            wrapperCol={{ offset: 8, span: 24 }}
            className="flex justify-end gap-3 buttonsDiv"
          >
            <Button
              htmlType="submit"
              className="createButton"
              type="primary"
              style={{ marginTop: "-1px", height: "45px" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PanKyc;
