import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { request } from "../../Utils/request";

type Props = {
  matrimonyID: any;
};

const VoterKyc = (props: Props) => {
  const [form] = Form.useForm();
  const matrimonyID = props?.matrimonyID;
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      const { voterId, termsCondition } = values;
      if (!termsCondition) {
        form.setFields([
          {
            name: "termsCondition",
            errors: ["Please accept the terms and conditions!"],
          },
        ]);
        return;
      } else {
        const requestBody = {
          method:'POST',
          body:JSON.stringify({
            docNumber: voterId,
            matrimonyId: matrimonyID,
            docTypeEnum: "VOTER",
            fullName: "",
            dob: "",
            mobileNumber: "",
          })
        
        };
        const response = await request(`/member/verify-doc-type`, requestBody);
        form.resetFields();
        console.log("VoterID kyc Values===>", values);
        if (response?.userId) {
          toast.success("KYC request sent to admin for approval!");
          navigate(-1);
        } else {
          toast.error("KYC request failed to send to admin approval!");
        }
      }
    } catch (error: any) {
      toast.error("KYC request failed to send to admin approval!");
      console.log("VoterID kyc Error===>", error);
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="kycForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="kycFormItem"
      >
        <Form.Item
          className="formitem"
          label="Voter ID"
          name="voterId"
          rules={[
            {
              required: true,
              message: "Please enter your voter ID!",
            },
            {
              validator(_, value) {
                let voterRegex = /^[A-Z]{3}[0-9]{7}$/;
                if (voterRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter valid voter ID!")
                );
              },
            },
          ]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          name="termsCondition"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions!",
            },
          ]}
        >
          <Checkbox>
            <span className="text-[20px]">Terms & Conditions</span>
            <span style={{ color: "#ff4d4f" }}>*</span>
          </Checkbox>
        </Form.Item>

        <div className="Save-btn">
          <Form.Item
            wrapperCol={{ offset: 8, span: 24 }}
            className="flex justify-end gap-3 buttonsDiv"
          >
            <Button
              htmlType="submit"
              className="createButton"
              type="primary"
              style={{ marginTop: "-1px", height: "45px" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default VoterKyc;
