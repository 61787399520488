import React from "react";
import "./ChatComponent.css";
import MessageComponent from "./MessageComponent";
import ChatInput from "./ChatInput";
import { useChatContext } from "../../Context/ChatContext";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import { isMobileOnly } from "../../Utils/mobileSettings";
import { LeftOutlined } from "@ant-design/icons";

function ChatComponent() {
  const { chatData } = useChatContext();
  const { userMatrimonyData } = useUserMatrimonyContext();

  return (
    <div className="chatComponent flex flex-col lg:rounded-tr-2xl">
      {chatData?.matrimonyId && userMatrimonyData?.matrimonyId ? (
        <>
          <div className="chat-info flex items-center  sticky top-0 sm:-mt-2 lg:rounded-tr-lg z-50">
            {isMobileOnly ? (
              <div>
                <button onClick={() => window.history.back()}>
                  <LeftOutlined className="text-white pr-2" />
                </button>
              </div>
            ) : (
              <></>
            )}
            {chatData?.profileUrl?.length > 0 ? (
              <img
                src={chatData?.profileUrl[0]}
                alt=""
                className="chat-info-img mr-2 -mt-1 "
              />
            ) : (
              <img
                src={profilePlaceholder}
                alt=""
                className="chat-info-img mr-2 -mt-1 "
              />
            )}
            <span>{chatData?.member?.name}</span>
          </div>
          <MessageComponent />
          {chatData?.matrimonyId && userMatrimonyData?.matrimonyId && (
            <div className="w-full">
              <ChatInput />
            </div>
          )}
        </>
      ) : (
        <div className="bg-[#efe8e1] w-full h-full flex justify-center items-center">
          {" "}
          <span className="text-2xl text-gray-600">Start a conversation.</span>
        </div>
      )}
    </div>
  );
}

export default ChatComponent;
