import React from "react";
import ChatComponent from "../Components/ChatComponent/ChatComponent";

function ChatScreenMobile() {
  return (
    <div className="flex w-full remove-header">
      <div className="w-full">
        <ChatComponent />
      </div>
    </div>
  );
}

export default ChatScreenMobile;
