import React from "react";
import { Menu } from "antd";
import { DropboxOutlined, CodeSandboxOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const LeftNav: React.FC = () => {
  const location = useLocation();

  const leftNavProps = [
    {
      key: "feed",
      Label: "Feed",
      onClick: () => {
        console.log("onClick feed");
      },
      linkTo: "/feeds",
      icon: <DropboxOutlined />,
    },
    {
      key: "news",
      Label: "News",
      onClick: () => {
        console.log("onClick news");
      },
      linkTo: "/news",
      icon: <DropboxOutlined />,
    },
    {
      key: "community",
      Label: "Community",
      onClick: () => {
        console.log("onClick Community");
      },
      linkTo: "/community",
      icon: <CodeSandboxOutlined />,
    },
    {
      key: "requests",
      Label: "Requests",
      onClick: () => {
        console.log("onClick Requests");
      },
      linkTo: "/requests",
      icon: <DropboxOutlined />,
    },
    {
      key: "Profile",
      Label: "Profile",
      onClick: () => {
        console.log("onClick Profile");
      },
      linkTo: "/profile",
      icon: <DropboxOutlined />,
    },
    {
      key: "matrimony",
      Label: "Matrimony",
      onClick: () => {
        console.log("onClick Matrimony");
      },
      linkTo: "/matrimony",
      icon: <DropboxOutlined />,
    },
    {
      key: "news",
      Label: "News",
      onClick: () => {
        console.log("onClick news");
      },
      linkTo: "/news",
      icon: <DropboxOutlined />,
    },
  ];

  const isCurrentRoute = (path: string) => {
    return location.pathname === path;
  };

  return (
    <div className="rounded-lg shadow-lg bg-white flex flex-col items-center justify-center ">
      <div className="w-full flex justify-center items-center flex-col leftPanel ">
        <Menu
          theme="light"
          mode="vertical"
          className="focus:text-[red]"
          defaultSelectedKeys={["feeds"]}
          selectedKeys={[location.pathname]}
        >
          {leftNavProps?.map((menu) => (
            <div className="flex items-center justify-center ">
              <Menu.Item
                key={menu.key}
                className={` flex items-center justify-center text-[#333333] font-medium ${
                  isCurrentRoute(menu.linkTo)
                    ? "text-[#F83E5E] text-base font-medium"
                    : ""
                }`}
              >
                <div className="flex items-center gap-5 text-">
                  <p className="">{menu?.icon}</p>
                  <Link to={menu.linkTo}> {menu.Label}</Link>
                </div>
              </Menu.Item>
            </div>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default LeftNav;
