import React, { useState, useEffect } from "react";
import { request } from "../../Utils/request";
import "./CommunityPreview.css";
import { TIMEAGO } from "../../Utils/timeago";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import Loader from "../Loader/Loader";

type Props = {
  id?: string;
  type?: string;
  member?: boolean;
  posts?: any;
  from?: string;
};

function LatestActivity(props: Props) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);

  useEffect(() => {
    setPosts(props?.posts);
    setLoader(false);
  }, [props?.posts]);

  // Filter out duplicate usernames
  // const uniqueUsernames = Array.from(new Set(posts?.map((post) => post.userName)));

  if (props.type === "PRIVATE" && props.member === false) {
    return (
      <>
        {loader ? (
          <div style={{ minHeight: "8rem" }}>
            <div className="loader my-3">
              <Loader />{" "}
            </div>
          </div>
        ) : (
          <>
            <p className="RightSideTextLabel">Latest Activity</p>
            <div className="flex flex-col items-center pb-5">
              {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
              <div className="text-gray-500 text-3xl">
                <EyeInvisibleOutlined />
              </div>
              <div className="text-gray-500 noRecentActivity text-center">
                {" "}
                Join the community to see Latest Activity{" "}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  if (posts?.length === 0) {
    return (
      <>
        {loader ? (
          <div style={{ minHeight: "8rem" }}>
            <div className="loader my-3">
              <Loader />{" "}
            </div>
          </div>
        ) : (
          <>
            <p className="RightSideTextLabel">Latest Activity</p>
            <div className="flex flex-col items-center">
              {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
              <div className="text-gray-500 pb-8 noRecentActivity">
                {" "}
                No recent activity{" "}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {loader ? (
        <div style={{ minHeight: "8rem" }}>
          <div className="loader my-3">
            <Loader />{" "}
          </div>
        </div>
      ) : (
        <div>
          <p className="RightSideTextLabel">Latest Activity</p>
          {posts?.length > 0 &&
            posts?.map((post: any, index) => (
              <div className="latestActivityItem">
                <div className="dpandname">
                  <div>
                    {post?.userProfileUrl ? (
                      <div className="justify-center overflow-hidden rounded-full latestActivityImage">
                        <span className="">
                          <img
                            src={post?.userProfileUrl}
                            style={{ objectFit: "cover", borderRadius: "50%" }}
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        className="capitalize relative inline-flex items-center justify-center w-10 h-10 overflow-hidden  rounded-full"
                        style={{
                          backgroundColor: post.profileColorCode,
                        }}
                      >
                        <span className="font-medium text-white dark:text-gray-300">
                          {post?.userName?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className=" flex flex-row items-center">
                      <span className="posttitle capitalize">
                        {post?.userName}
                      </span>
                      <span className="ml-1">&#8594;</span>
                      {props?.from === "Profile" ? (
                        <span className="">
                          {post?.metaData?.length > 0 ? (
                            <span className="posttitle text-slate-500">
                              posted a picture on {post?.communityName}
                            </span>
                          ) : (
                            <span className="posttitle text-slate-500">
                              added a new post on {post?.communityName}
                            </span>
                          )}
                          {/* <span className="posttitle w-full">{post?.communityName}</span> */}
                        </span>
                      ) : post?.metaData?.length > 0 ? (
                        <span className="posttitle w-full text-slate-400">
                          posted a picture
                        </span>
                      ) : (
                        <span className="posttitle w-full text-slate-400">
                          added a new post
                        </span>
                      )}
                    </div>
                    <div className="text-sm ml-3 text-slate-400">
                      <TIMEAGO timestamp={post?.createdAt} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
}

export default LatestActivity;
