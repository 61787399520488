import { useEffect, useState } from "react";
import UpdateProfile from "../Components/Profile/ProfileComponent";
import { useNavigate, useParams } from "react-router-dom";
import UserProfile from "../Components/Profile/UserProfile";
import ProfileComponent from "../Components/Profile/ProfileComponent";

import "./profileResponsive.css";
import { toast } from "react-toastify";

type Props = {};
// eslint-disable-next-line no-empty-pattern
function Profile({}: Props) {
  const navigate = useNavigate();
  // const [showEditModal, setShowEditModal] = useState<boolean>(true);
  const token = localStorage.getItem("token");
  const userExist = localStorage.getItem("userExist");

  const params = useParams();
  // console.log("id in profile",params.uid)

  useEffect(() => {
    if (!token?.length) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="usercontainerWithOutSideBar">
        <div className="mainContentCol">
          {/* <div className="tabPanelBox"> */}
          <div className="profileContainer" style={{ minHeight: "700px" }}>
            {/* {token?.length && <UpdateProfile />} */}
            {token?.length && <UserProfile uid={params?.uid} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
