import React, { useEffect } from 'react'
import LeftQuote from '../assets/images/left-quote.png'
import RightQuote from '../assets/images/right-quote-sign.png'
import Logo from '../assets/images/logo.png'
import PeopleConnect from '../assets/images/people-connecct.jpg'
import FeedImage from '../assets/images/blog-post-image-guide.png'
import Matrimony from '../assets/images/matrimonyImg.png'
import ConnectionImage from '../assets/images/volunteers.png'
import { NavLink,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from '../Utils/request'

type Props = {}

const LandingPage = (props: Props) => {
  const navigate = useNavigate()

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }
  useEffect(() => {
    const token = localStorage.getItem('token')
  const profileScore = localStorage.getItem("profileScore");
  if(profileScore === null){
    meApiCall()
  }
  else if (token?.length && profileScore !== "100") {
    navigate("/profile");
  }
  }, [])



  const featuresPost = [
    { name: 'Feeds', linkTo: '/feeds', imageLink: FeedImage },
    { name: 'Communities', linkTo: '/community', imageLink: ConnectionImage },
    { name: 'Matrimony', linkTo: '/matrimony', imageLink: Matrimony }
  ]
  return (
    <div>

      <div className='containerWithOutSideBar landingPage'>
        <div className='homePageBackgroundUrl'>
          <h2 >VamshaVruksha</h2>
          <p >
            We provide users an option to communicate, share information
            with people based on communities.
          </p>
        </div>
        <div className='mainContentCol'>
          <div className='qouteBox'>
          <div className='flex items-center qoutediv'>
            <img className='w-[20px] h-[20px] icon1' src={LeftQuote} style={{ objectFit: 'cover' }} alt=''/>
            <div className='text-base'>
              VamshaVruksha provides a wonderful opportunity to create communities of types :
              Friends, Association, Family, Society, Religion, General. Users in our platform
              will be updated with latest international and national news and application
              posts..Vamshavruksha stands as a unique app integration with multiple technical
              features from email inboxes to share media across communities.
            </div>
            <img src={RightQuote} className='w-[20px] h-[20px] icon2' style={{ objectFit: 'cover' }} alt=''/>


          </div>
          </div>
          
          <h2 className='featureshead'>Our features</h2>
          <div className='featuresRow'>
            {featuresPost.map((feature) => (
              <div className='featuresCol cursor-pointer' onClick={() => {navigate(feature?.linkTo)}}>
                <div className='featuresBox'>
                  <img src={feature.imageLink} alt='' />
                  <div className='text'>
                    <NavLink to={feature?.linkTo}><span className='cursor-pointer'>{feature.name}</span></NavLink></div>
                </div>

              </div>
            ))}


          </div>
          <div className='aboutInfoRow'>
            <div className='aboutInfoCol'>
              <div className='aboutInfoHead'>
                <h2 className='featureshead'>Why Vamshavruksha?</h2>
                <img src={Logo} width={50} style={{ objectFit: 'cover' }} alt=''/>
              </div>
              <hr/>
              <div className='aboutInfoContent'>
                <p >
                  <span>With the development in technology and in the new economic order the local
                    communities have dispersed globally away from their socio-cultural contexts. In
                    this situation a void was felt where the connection to the roots was much needed
                    over a period of time. To connect with their family and friends seems impossible
                    with the distance and the career commitments of the current generation. It is
                    not the case just in the diasporic populace but also with the locals who dwell
                    in the city commuting and networking with their kith and kin was a hindrance,
                    owing to the demanding professional commitments and the buzz of the packed
                    cosmopolitan life schedules.</span>

                  <span>There are social media apps like Whatsapp , facebook , Linkedin etc which
                    attempts to fill this much needed demand of the current situation. But these
                    platforms had their own limitations and privacy was an issue.</span>

                  <span>A need was felt to fill this void and for more holistic user friendly
                    medium was felt, and hence a seed idea of the App VAMSHAVRUKSHA was sown.</span>
                </p>
              </div>
            </div>
            <div className='aboutInfoCol'>
              <div className='aboutInfoHead'>
                <h2 className='featureshead'>What can we do with it?</h2>
                <img src={PeopleConnect} width={50} style={{ objectFit: 'cover' }} alt=''></img>
              </div>
              <hr/>
              <div className='aboutInfoContent'>
                <p>
                  <span>Vamshavruksha is an app conceived to bring together like minded people in
                    a common virtual platform. The app Vamshavruksha purposes to facilitate multiple
                    community platforms for a single user from residential communities like
                    apartments or housing welfare communities, ethnic identity communities like
                    religious, spiritual communities, school alumni to professional and recreational
                    needs of the society.</span>
                  <span>This need based platform Vamshavruksha will facilitate opportunities for
                    the user to build and manage segregated and classified matrimonial needs of the
                    community which the user is a part of, a professional community, recreational
                    platforms, services like housekeeping, travel and tour operators, real estate
                    demand and queries, investment consultancy etc , all under one single access
                    Vamshavruksha.</span>
                  <span>The user is allowed to filter and create communities in all his ethnic
                    social and cultural contexts. Thus Vamshavruksha stands as a unique app
                    integrating multiple technical features from email inboxes to share videos,
                    audio and even live stream events for its user.</span>
                  <span>Vamshavruksha as an app is conceived to a be a progressive tool for its
                    user to design build and network conveniently all in a simple user friendly
                    state of the art technology.</span>
                  <span>Vamshavruksha is a technological vision as a tool to leverage and fulfill
                    the growing demands of today’s society.
                  </span>

                </p>

              </div>
            </div>
          </div>

          

        </div>
      </div>

    </div>
  )
}

export default LandingPage