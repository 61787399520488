import { Modal } from "antd";
import React, { useState } from "react";
import { Input, Radio, Space, Button } from "antd";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";

interface ReportPostModalProps {
  onCancel: () => void;
  postId: string;
  postData: any;
  setPostsData: React.Dispatch<React.SetStateAction<any[]>>;
}

const ReportPostModal: React.FC<ReportPostModalProps> = ({
  onCancel,
  postId,
  postData,
  setPostsData,
}) => {
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const handleRadioChange = (e: any) => {
    setReason(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.slice(0, 50); // Limiting to 50 characters
    setOtherReason(inputValue);
  };

  const submitReport = async () => {
    const finalReason = reason === "Others" ? otherReason : reason;

    if (!finalReason || (reason === "Others" && !finalReason.trim())) {
      toast.error("Please specify a reason for the report.");
      return;
    }

    const body = {
      postId,
      reason: finalReason.trim(),
    };

    const response = await request(`/member/current/post/report`, {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (response?.id) {
      toast.success("Reported successfully");
      const updatedPosts = postData.filter(
        (post: any) => post?.postId !== postId
      );
      setPostsData(updatedPosts);
      onCancel();
    }
  };

  return (
    <div>
      <Modal
        visible
        title={
          <div className="m-2 p-2">Please Specify the reason for report</div>
        }
        onCancel={onCancel}
        footer={[
          <Button className="m-2" key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button
            className="m-2"
            key="submit"
            type="primary"
            onClick={submitReport}
          >
            Report
          </Button>,
        ]}
        style={{ width: "80%" }}
      >
        <div className="m-5 p-2">
          <Radio.Group onChange={handleRadioChange} value={reason}>
            <Space direction="vertical">
              <Radio value={"Spam or misleading"}>Spam or misleading</Radio>
              <Radio value={"Hate speech or harassment"}>
                Hate speech or harassment
              </Radio>
              <Radio value={"Inappropriate or obscene content"}>
                Inappropriate or obscene content
              </Radio>
              <Radio value={"Violence or harmful behavior"}>
                Violence or harmful behavior
              </Radio>
              <Radio value={"Others"}>
                Others{" "}
                <span className="text-gray-400">
                  (must be under 50 characters)
                </span>
              </Radio>
              {reason === "Others" ? (
                <>
                  <Input
                    style={{ marginLeft: 10 }}
                    value={otherReason}
                    onChange={handleInputChange}
                    maxLength={50}
                    required
                    placeholder="Please Specify the reason"
                  />
                </>
              ) : null}
            </Space>
          </Radio.Group>
        </div>
      </Modal>
    </div>
  );
};

export default ReportPostModal;
