import acting from "./acting.svg";
import art from "./art.svg";
import biking from "./bikeRiding.svg";
import bingewatching from "./bingewatching.svg";
import blogging from "./blogging.svg";
import clubbing from "./clubbing.svg";
import content from "./contentCreation.svg";
import cooking from "./cooking.svg";
import cycling from "./cycling.svg";
import dancing from "./dancing.svg";
import DIY from "./DIY.svg";
import entre from "./entrepreneurship.svg";
import fashionBeauty from "./fashionBeauty.svg";
import foodie from "./foodie.svg"
import gaming from "./gaming.svg";
import gardening from "./gardening.svg";
import hiking from "./hiking.svg";
import homeDecor from "./homeDecor.svg";
import invest from "./invest.svg";
import movies from "./movies.svg";
import music from "./music.svg";
import pets from "./pets.svg";
import photography from "./photography.svg";
import poetry from "./poetry.svg";
import running from "./running.svg";
import shopping from "./shopping.svg";
import social from "./socialMedia.svg";
import socialService from "./socialService.svg";
import sports from "./sports.svg";
import standup from "./standup.svg";
import swimming from "./swimming.svg";
import travel from "./travel.svg";
import vegan from "./vegan.svg";
import walking from "./walking.svg";
import workingOut from "./workingout.svg";
import writing from "./writing.svg";
import yoga from "./yoga.svg";
import reading from "./reading.svg";
import singing from "./singing.svg";
import instrument from "./instruments.svg";
import doodling from "./doodling.svg";
import news from "./news&politics.svg";
import designing from "./designing.svg";

const icons = {
    acting,
    art,
    biking,
    bingewatching,
    blogging,
    clubbing,
    content,
    cooking,
    cycling,
    dancing,
    DIY,
    entre,
    fashionBeauty,
    foodie,
    gaming,
    gardening,
    hiking,
    homeDecor,
    invest,
    movies,
    music,
    pets,
    photography,
    poetry,
    running,
    shopping,
    social,
    socialService,
    sports,
    standup,
    swimming,
    travel,
    vegan,
    walking,
    workingOut,
    writing,
    yoga,
    reading,
    singing,
    instrument,
    doodling,
    news,
    designing
}

export default icons;