import { useState, useEffect } from "react";
import React from "react";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import draft from "../../assets/newUiImages/draftIcon.png";
import { Button, Modal } from "antd";
import edit from "../../assets/matrimonyIcons/edit.svg";
import "./MatrimonyTabs.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "../../Utils/mobileSettings";
import KYCActive from "../../assets/newUiImages/kycActive.svg";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {
  onDraftClick: (id: string) => void;
  refresh: number;
};

function truncateString(str: string, maxLength: number) {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength) + "...";
  }
  return str;
}

function convertHeightToFeetAndInches(heightInCms: number) {
  const inches = heightInCms / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  // Adjust for the case where remainingInches is 12
  if (remainingInches === 12) {
    return `${feet + 1}' 0''`;
  }

  return `${feet}' ${remainingInches}''`;
}

function YourProfiles({ onDraftClick, refresh }: Props) {
  const [yourMatrimonyList, setYourMatrimonyList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState<boolean>(false);
  const [draftDeleteConfirmationVisible, setDraftDeleteConfirmationVisible] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedDraftId, setSelectedDraftId] = useState<string | null>(null);
  const navigate = useNavigate();
  let apiCount = 0;

  const handleMatrimonyProfile = (id: any) => {
    navigate(`/matrimony/matrimony-profile/${id}`, {
      state: { user: "yourProfile" },
    });
  };

  const handleDelete = async () => {
    if (selectedDraftId) {
      const resp = await request(
        `/member/current/matrimony-profile/archive/${selectedDraftId}`,
        { method: "POST" }
      );
      if (resp) {
        await deleteDoc(doc(db, "users", selectedDraftId));
        toast.success(resp);
        setCurrentPage(0);
        getYourMatrimonyData(0);
        setDeleteConfirmationVisible(false);
      }
    }
  };

  const handleDeleteDraft = async () => {
    if (selectedDraftId) {
      const resp = await request(
        `/member/current/matrimony-profile/archive/${selectedDraftId}`,
        { method: "POST" }
      );
      if (resp) {
        toast.success(resp);
        setCurrentPage(0);
        getYourMatrimonyData(0);
        setDraftDeleteConfirmationVisible(false);
      }
    }
  };

  const getYourMatrimonyData = async (page?: number) => {
    let requestPromise = request(
      `/member/current/matrimony-profile?page=${page}&size=10`
    );
    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      if (!resp) {
        const cachedResponse = await getCachedResponse(
          `/member/current/matrimony-profile?page=${page}&size=10`
        );
        if (cachedResponse) {
          setYourMatrimonyList(cachedResponse);
          getYourMatrimonyData(page);
          setLoader(false);
        } else {
          console.error("Failed to get cached data");
        }
        return;
      }

      if (resp?.content) {
        const newYourMatrimonyList = resp;

        if (newYourMatrimonyList.content.length === 0) {
          setHasMore(false);
        }

        if (page === 0) {
          setYourMatrimonyList(resp?.content);
          cacheResponse(
            `/member/current/matrimony-profile?page=${page}&size=10`,
            resp?.content
          );
        } else if (newYourMatrimonyList?.content?.length) {
          let oldData = [...yourMatrimonyList];
          oldData = [...oldData, ...resp?.content];
          setYourMatrimonyList(oldData);
        }
        setLoader(false);
      } else {
        setLoader(false);

        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          `/member/current/matrimony-profile?page=${page}&size=10`
        );

        if (cachedResponse) {
          setYourMatrimonyList(cachedResponse);
          setLoader(false);
          if (apiCount < 3) {
            getYourMatrimonyData(page);
          }
        } else {
          console.error("Failed to get cached data");
        }
      }
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error in getYourMatrimonyData:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    getYourMatrimonyData(0);
  }, [refresh]);

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    getYourMatrimonyData(currentPage);
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div>
        {loader ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={yourMatrimonyList?.length}
            next={() => {
              setCurrentPage(currentPage + 1);
            }}
            hasMore={hasMore}
            loader={<></>}
          >
            <div className="yourProfiles">
              {yourMatrimonyList?.length > 0 ? (
                <div className="grid grid-cols-1   sm:grid-cols-2 -mr-3 md:grid-cols-3 lg:grid-cols-4 gap-4 pr-2 mt-2 pr-2 pt-2 mr-1 xl:grid-cols-4 gap-4">
                  {yourMatrimonyList?.map((yourMatrimony: any, index: any) => (
                    <div
                      className="relative border-2 border-gray-300 border-solid rounded-lg ml-2 mr-0 p-1 overflow-hidden shadow-md w-full profileList "
                      key={yourMatrimony?.matrimonyId}
                    >
                      <div className="flex items-center cursor-pointer p-2 mb-1 pb-2 relative w-full contentwrapper">
                        <div
                          className=" overflow-hidden rounded-l-lg sm:h-48 h-32 rounded-l-lg md:rounded-lg"
                          onClick={() => {
                            if (yourMatrimony?.status === "DRAFT") {
                              onDraftClick(yourMatrimony?.matrimonyId);
                            }
                          }}
                        >
                          {yourMatrimony?.profileUrl?.length > 0 ? (
                            <img
                              src={yourMatrimony?.profileUrl[0]}
                              alt=""
                              className="object-cover h-48"
                              width={210}
                              height={100}
                            />
                          ) : (
                            <img
                              src={profilePlaceholder}
                              alt=""
                              className="object-cover h-48 w-full"
                            />
                          )}
                        </div>
                        {yourMatrimony?.status === "DRAFT" ? (
                          <>
                            <div
                              className="absolute top-0 right-2"
                              onClick={() =>
                                onDraftClick(yourMatrimony?.matrimonyId)
                              }
                            >
                              <img src={draft} alt="" height={30} width={30} />
                            </div>
                            <div
                              className="thumbnailContent ml-3 pr-2"
                              onClick={() =>
                                onDraftClick(yourMatrimony?.matrimonyId)
                              }
                            >
                              <div className="matrimonyThumbnailTitle ThumbnailTitle text-lg font-semibold p-0 m-0 md:p-2 m-2 mt-1 ">
                                {truncateString(
                                  yourMatrimony?.member?.name,
                                  10
                                )}
                              </div>
                              <div className="text-base font-semibold p-2 m-2 mt-1">
                                {yourMatrimony?.age > 0 && (
                                  <div>{yourMatrimony?.age} years,</div>
                                )}{" "}
                                {convertHeightToFeetAndInches(
                                  yourMatrimony?.member?.height
                                )}
                                <br></br>
                                {yourMatrimony?.member?.state && (
                                  <div>
                                    From&nbsp;{yourMatrimony?.member?.state}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className=" absolute bottom-1 right-2 z-99"
                              onClick={() => {
                                setSelectedDraftId(yourMatrimony?.matrimonyId);
                                setDraftDeleteConfirmationVisible(true);
                              }}
                            >
                              <i className="bi bi-trash3-fill text-2xl"></i>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="absolute top-1 right-2 z-2"
                              onClick={() =>
                                onDraftClick(yourMatrimony?.matrimonyId)
                              }
                            >
                              <img src={edit} alt="" height={25} width={25} />
                            </div>
                            <div
                              className="thumbnailContent ml-3 pr-2"
                              onClick={() =>
                                handleMatrimonyProfile(
                                  yourMatrimony?.matrimonyId
                                )
                              }
                            >
                              <div className="matrimonyThumbnailTitle ThumbnailTitle text-lg font-semibold p-0 md:p-2 m-2 mt-1 ">
                                {truncateString(
                                  yourMatrimony?.member?.name,
                                  10
                                )}
                              </div>
                              <div className="text-base font-semibold p-0 md:p-2 m-2 mt-1 thumbnailContent">
                                <div>
                                  {yourMatrimony?.age > 0 && (
                                    <div>{yourMatrimony?.age} years,</div>
                                  )}{" "}
                                  &nbsp;{" "}
                                  {convertHeightToFeetAndInches(
                                    yourMatrimony?.member?.height
                                  )}
                                </div>
                                {yourMatrimony?.member?.currentState && (
                                  <div className="mr-2">
                                    From&nbsp;
                                    {truncateString(
                                      yourMatrimony?.member?.currentState,
                                      10
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className=" absolute bottom-1 right-2"
                              onClick={() => {
                                setSelectedDraftId(yourMatrimony?.matrimonyId);
                                setDeleteConfirmationVisible(true);
                              }}
                            >
                              <i className="bi bi-trash3-fill text-2xl"></i>
                            </div>
                          </>
                        )}
                      </div>

                      {yourMatrimony?.kycVerified &&
                        yourMatrimony?.show !== "DRAFT" && (
                          <div
                            className="kyc-verify-thumbnail"
                            title="KYC verified"
                          >
                            <img
                              src={KYCActive}
                              style={{ padding: "3px", marginLeft: "0" }}
                              className="kyc-verify-img"
                              alt=""
                            />
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-full text-center">
                  <div className="flex flex-col items-center pt-10 pb-10">
                    <div className="text-gray-500 text-2xl pt-3">
                      There are no profiles. Create a profile
                    </div>
                    <div className="flex justify-center items-center pt-5 shadow-xl pl-3 lg:max-w-2xl">
                      <div className="bg-white text-center pb-3 pt-1 rounded-lg border border-[#7F4C30]  ">
                        <>
                          <div className="text-[#7F4C30] text-xl font-semibold px-4 py-2 border-b border-[#7F4C30] flex justify-center items-center">
                            <span>Matrimony Quick Guide</span>
                          </div>
                          <div className="text-[#7F4C30] text-base mt-4 px-4">
                            <div className="mb-4">
                              <h3 className="text-[#7F4C30] font-semibold">
                                Profile Visibility: Open to All
                              </h3>
                              <p className="mt-2">
                                If a profile is set to "Open to All," it means
                                that anyone can view the profile and start a
                                conversation. To begin chatting with the user,
                                ensure you have at least one profile of your
                                own. You can initiate a chat directly without
                                needing any additional approvals.
                              </p>
                            </div>
                            <div className="mb-4">
                              <h3 className="text-[#7F4C30] font-semibold">
                                Profile Visibility: Request Only
                              </h3>
                              <p className="mt-2">
                                If a profile is set to "Request Only," you will
                                see a "Request More Details" button instead of a
                                chat icon. Click this button to send a request
                                for more information about the user.
                              </p>
                            </div>
                            <div className="mb-1">
                              <h3 className="text-[#7F4C30] font-semibold">
                                Request Approval Process
                              </h3>
                              <p className="mt-2">
                                After you send a request for more details, the
                                user will need to approve it. Once your request
                                is approved, you will be notified, and you will
                                then be able to start chatting with the user.
                                Ensure to check your notifications for updates
                                on your request status.
                              </p>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </InfiniteScroll>
        )}
        <Modal
          visible={deleteConfirmationVisible}
          onCancel={() => setDeleteConfirmationVisible(false)}
          // cancelButtonProps={{ style: { display: "none" } }}
          closable={false}
          footer={false}
        >
          <div className="">
            <div className="text-lg p-3 mr-3">
              Do you want to delete this profile? Please note that upon
              deletion, all associated chat data will also be permanently
              removed.
            </div>
            <div className="right-0">
              <div className="text-end">
                <Button
                  key="cancel"
                  onClick={() => setDeleteConfirmationVisible(false)}
                  className={isMobile ? "mr-1 my-4 " : "m-2 ml-8"}
                >
                  Cancel
                </Button>

                <Button
                  key="delete"
                  type="primary"
                  danger
                  onClick={handleDelete}
                  className={isMobile ? "my-4 mr-2" : "m-2 mr-2"}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          visible={draftDeleteConfirmationVisible}
          onCancel={() => setDraftDeleteConfirmationVisible(false)}
          // cancelButtonProps={{ style: { display: "none" } }}
          closable={false}
          footer={false}
        >
          <div className="">
            <div className="text-lg p-3 mr-3">
              Do you want to delete this draft profile?
            </div>
            <div className="right-0">
              <div className="text-end">
                <Button
                  key="cancel"
                  onClick={() => setDraftDeleteConfirmationVisible(false)}
                  className={isMobile ? "mr-1 my-4 " : "m-2 ml-8"}
                >
                  Cancel
                </Button>

                <Button
                  key="delete"
                  type="primary"
                  danger
                  onClick={handleDeleteDraft}
                  className={isMobile ? "my-4 mr-2" : "m-2 mr-2"}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </PullToRefreshComponent>
  );
}

export default YourProfiles;
