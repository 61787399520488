import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedsComponent from "../Components/Feeds/FeedsComponent";
import PullToRefresh from "react-simple-pull-to-refresh";
import { isMobile } from "../Utils/mobileSettings";
import newloader from "../assets/loader-new.gif";
import PullToRefreshComponent from "../PullToRefreshComponent/PullToRefreshComponent";
import { toast } from "react-toastify";
import { request } from "../Utils/request";
import { isIOS } from "react-device-detect";

// import { requestPermission } from "../firebase";

type Props = {
  // parentCallback: Function;
};

const Feeds = (props: Props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");
  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      // if(meApiResp?.emailVerified){
      navigate("/profile");
      // }else{
      //   localStorage.clear();
      //   navigate("/login");
      // }
    }
    return;
  };

  const fetchDeviceToken = async () => {
    // toast.success("In fetch device token")
    try {
      if (typeof window?.AndroidJSBridge?.getDeviceToken === "function") {
        const devicetoken = await window.AndroidJSBridge.getDeviceToken();
        const version = await window?.AndroidJSBridge?.getDeviceOsVersion();
        console.log("Token", devicetoken);
        // toast.success("Android token "+devicetoken)
        localStorage.setItem("deviceToken", devicetoken);
        localStorage.setItem("deviceOsVersion", version);
        localStorage.setItem("deviceType", "ANDROID");
      } else {
        console.log(
          "Running in browser environment",
          window?.AndroidJSBridge?.getDeviceToken,
          typeof window?.AndroidJSBridge?.getDeviceToken
        );
        const handleIOSEvent = (e: any) => {
          return new Promise((resolve, reject) => {
            try {
              const deviceToken = e.detail.data;
              // toast.success(`Token in feeds: ${deviceToken}`);
              localStorage.setItem("deviceToken", deviceToken);
              localStorage.setItem("deviceOsVersion", "0");
              localStorage.setItem("deviceType", "IOS");
              resolve(deviceToken);
            } catch (error) {
              reject(error);
            }
          });
        };

        const eventListener = (e: any) => {
          handleIOSEvent(e)
            .then((deviceToken) => {
              console.log(`Token received and processed: ${deviceToken}`);
              // toast.success(`Token received and processed: ${deviceToken}`);
            })
            .catch((error) => {
              console.error(`Error processing event: ${error}`);
              // toast.error(`Error processing event: ${error}`);
            });
        };
        window.addEventListener("iosEvent", eventListener);
      }
    } catch (error: any) {
      console.error("Error fetching device token: " + error.message);
    }
  };
  useEffect(() => {
    const deviceToken = localStorage.getItem("deviceToken");
    const hasMadeApiCall = localStorage.getItem("hasMadeApiCall");

    const deviceTokenApiCall = async () => {
      const deviceOsVersion = localStorage.getItem("deviceOsVersion") || "0";
      const deviceType = localStorage.getItem("deviceType");
      const payload = {
        method: "POST",
        body: JSON.stringify({
          deviceToken: deviceToken,
          deviceType: deviceType,
          deviceOsVersion: parseInt(deviceOsVersion),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await request(`/member/current/device-token`, payload);
        console.log("RESPONSE: " + JSON.stringify(response));
        localStorage.setItem("hasMadeApiCall", "true");
      } catch (error) {
        console.error("API call failed: " + error);
      }
    };

    if (!hasMadeApiCall && token?.length && deviceToken?.length) {
      // toast.success("In api call" + deviceToken);
      deviceTokenApiCall();
    }
  }, [token]);

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear();
      navigate("/login");
    }
    if (token?.length && profileScore === null) {
      meApiCall();
    } else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    } else {
      if (localStorage.getItem("deviceToken") === null || !token?.length) {
        fetchDeviceToken();
      }
    }
    // eslint-disable-next-line no-empty-pattern
  }, [navigate, token?.length]);

  const handleRefresh = async (): Promise<void> => {
    window.location.reload();
    // setToggleRefresh(!toggleRefresh);
    // setCurrentPage(0);
  };

  return (
    <div>
      {token?.length && (
        <div
          className="containerWithOutSideBar feedsPage"
          id="feedsPageContainer"
          // style={{ paddingTop: "50px" }}
        >
          <div className="mainContentCol">
            <div className="w-full">
              {token?.length && isMobile ? (
                <PullToRefreshComponent onRefresh={() => handleRefresh()}>
                  <FeedsComponent />
                </PullToRefreshComponent>
              ) : (
                <FeedsComponent />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feeds;
