import {configureStore} from '@reduxjs/toolkit'
import AuthenticateSlice from './Reducers/Authenticate/AuthenticateSlice'

export const store = configureStore({
    reducer:{
login:AuthenticateSlice
    }
})

// export type Rootstate = ReturnType<typeof store.ge>

export default store