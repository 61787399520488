import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { Form, Input, Button, Radio, DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import { request } from "../Utils/request";
import { auth, db } from "../firebase";

import "../custom.css";
import { addDoc, collection } from "firebase/firestore";

const SignUp: React.FC = () => {
  const [selecteddob, setselecteddob] = useState(null);
  const handledobchange = (date: any, dateString: any) => {
    setselecteddob(dateString);
  };
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    try {
      const { email, password, phoneNumber, place, name, confirmPassword } =
        values;

      const phone = values.phoneNumber;
      const genderdata = values.gender;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userUid = userCredential.user.uid;
      const formatteddob = selecteddob;
      await addDoc(collection(db, "users"), {
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        phoneNumber: phone,
        gender: genderdata,
        dob: formatteddob,
        place: place,
        name: name,
        loginType: "signUp",
      });
      toast.success("Account created successfully");
      navigate("/login");
      const body = {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          role: "MEMBER",
          firebaseUID: userUid,
          signUpVia: "IN_APP",
          member: {
            name,
            place,
            dob: formatteddob,
            gender: genderdata?.toUpperCase(),
            phoneNumber,
          },
        }),
      };

      const resp = request(`/member/current/create`, body);
      // console.log("BE resp outcome", resp);
    } catch (error: any) {
      if (error.message.split("/")[1].includes("email")) {
        toast.error("Email Exist");
      } else {
        toast.error("something went wrong");
      }
    }
  };

  return (
    <main>
      <section className="flex gap-6 m-3 ">
        <div className=" hidden sm:flex sm:w-1/5 ">
          <div className=" bg-white h-full pt-7 pr-7 pb-[300px] w-full">
            <div className=" py-10">
              <div className="flex text-[#f83e5e] text-base font-normal ">
                <div className="border-l-2 border-[#f83e5e] rounded-sm  "></div>
                <div className="flex gap-2 px-2 py-2">
                  <div className="bi bi-person"></div>
                  <p>Registration</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-4/5 w-full  bg-white h-full   p-5 signUp">
          <div>
            <div className="border-b-[1.4px] py-2 m-">
              <span className="border-b-2 border-[#f83e5e] rounded-sm py-3">
                User Registration
              </span>
            </div>
            <p className="text-base my-3 font-normal">
              Note: Fields with <span className="text-[#f83e5e]">*</span>are
              mandatory
            </p>
            <p className="labelHeading">Login Credentials</p>
            <div>
              <Form
                name="registration"
                onFinish={onFinish}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className="registrationform"
              >
                <Form.Item
                  className="formitem"
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input className="h-9" />
                </Form.Item>

                <Form.Item
                  className="formitem"
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <Input.Password className="h-9" />
                </Form.Item>

                <Form.Item
                  className="formitem"
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="h-9" />
                </Form.Item>

                <Form.Item
                  className="formitem"
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input className="h-9" />
                </Form.Item>

                <p className="labelHeading">Personal Details</p>

                <Form.Item
                  className="formitem"
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input className="h-9" />
                </Form.Item>

                <Form.Item label="Place" name="place" className="formitem">
                  <Input className="h-9" />
                </Form.Item>

                <Form.Item label="Gender" name="gender" className="formitem">
                  <Radio.Group className="h-9">
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="other">Other</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  className="formiteminline"
                >
                  <DatePicker onChange={handledobchange} />
                </Form.Item>

                <div className="flex  items-center gap-2 my-3 onSave">
                  <input
                    className="w-[15px] h-[15px]  sm:h-[25px] sm:w-[20px] cursor-pointer"
                    type="checkbox"
                  />
                  <div>
                    <span className=" text-[#333333] font-thin sm:text-base">
                      On Save, you agree to
                    </span>{" "}
                    <span
                      className="text-[#0075C1] text-[13px] sm:text-base cursor-pointer"
                      onClick={() => {
                        navigate("/termsOfUse");
                      }}
                    >
                      Terms & Conditions{" "}
                    </span>
                    and{" "}
                    <span
                      className="text-[#0075C1] text-[13px] sm:text-base cursor-pointer"
                      onClick={() => {
                        navigate("/privacyPolicy");
                      }}
                    >
                      {" "}
                      Privacy Policy.
                    </span>
                    <span className="text-red-700">*</span>
                  </div>
                </div>

                <Form.Item
                  wrapperCol={{ offset: 8, span: 16 }}
                  className="flex gap-3 buttonsDiv"
                >
                  <Button
                    type="primary"
                    className="signUpButtons"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    className="signUpButtons"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
