import React, { useState, ChangeEvent, useRef, useEffect } from "react";
// import { BASE_URL } from "../../Constants";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Checkbox,
  Radio,
  Tag,
  Tooltip,
} from "antd";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { multiFormRequest } from "../../Utils/request";
import { request } from "../../Utils/request";
import countries from "./countries.json";
import "survey-react/survey.css";
import { toast } from "react-toastify";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import back from "../../assets/matrimonyIcons/back.svg";
import "./multiStepForm.css";
import * as Survey from "survey-react";
import icons from "../../assets/hobbiesIcons/icons";
import MultiStepProgressBar from "./multistepProgressBar";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import PhoneInput from "react-phone-input-2";
import { db } from "../../firebase";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { getDateAndTime, getToken } from "../../Utils/timeago";

const { Option } = Select;

interface PersonalDetails {
  name: string;
  gender: string;
  dateOfbirth: Date;
  height: number;
  currentState: string;
  currentCity: string;
  profileFor: string;
  age: number;
  maritalStatus: string;
  noOfChildren: number;
  emailiddetails: string;
  phonenodetails: string;
  isContactSharingAllowed: boolean;
}

interface PersonalDetailsFormProps {
  onSubmit: (details: PersonalDetails) => void;
  onDraft: (details: PersonalDetails) => void;
  initialValues: PersonalDetails | null;
  completeInitialValues: any;
}
const aboutMeJsonData = {
  pages: [
    {
      name: "page1",
      elements: [
        {
          maxLength: 1000,
          type: "comment",
          name: "aboutMe",
          title: "About me",
          hideNumber: true,
          isRequired: true,
        },
      ],
    },
  ],
  showCompletedPage: false,
  showNavigationButtons: "none",
};
const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({
  onSubmit,
  onDraft,
  initialValues,
  completeInitialValues,
}) => {
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const [selectedstate, setselectedstate] = useState<any>();
  const [profileFor, setProfileFor] = useState<any>("");
  const [age, setAge] = useState<number>(0);
  const [checked, setchecked] = useState(false);
  const [statesData, setStatesData] = useState<any>();
  const [statesDataApi, setStatesDataApi] = useState<boolean>(false);
  const [selfProfileCheck, setSelfProfileCheck] = useState<boolean>(false);
  const [cityData, setCityData] = useState<any>();
  const [isCelebrityName, setIsCelebrityName] = useState(false);
  const [fullNameError, setFullNameError] = useState<any>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const localStorageUser = JSON.parse(localStorage.getItem("user") || "");
  const heightOptions = [
    { value: 135, label: "4'5\" or 135cm" },
    { value: 137, label: "4'6\" or 137cm" },
    { value: 140, label: "4'7\" or 140cm" },
    { value: 142, label: "4'8\" or 142cm" },
    { value: 145, label: "4'9\" or 145cm" },
    { value: 147, label: "4'10\" or 147cm" },
    { value: 149, label: "4'11\" or 149cm" },
    { value: 152, label: "5' or 152cm" },
    { value: 155, label: "5'1\" or 155cm" },
    { value: 157, label: "5'2\" or 157cm" },
    { value: 160, label: "5'3\" or 160cm" },
    { value: 163, label: "5'4\" or 163cm" },
    { value: 165, label: "5'5\" or 165cm" },
    { value: 167, label: "5'6\" or 167cm" },
    { value: 170, label: "5'7\" or 170cm" },
    { value: 172, label: "5'8\" or 172cm" },
    { value: 175, label: "5'9\" or 175cm" },
    { value: 177, label: "5'10\" or 177cm" },
    { value: 180, label: "5'11\" or 180cm" },
    { value: 182, label: "6' or 182cm" },
    { value: 185, label: "6'1\" or 185cm" },
    { value: 187, label: "6'2\" or 187cm" },
    { value: 190, label: "6'3\" or 190cm" },
    { value: 193, label: "6'4\" or 193cm" },
    { value: 195, label: "6'5\" or 195cm" },
    { value: 197, label: "6'6\" or 197cm" },
    { value: 200, label: "6'7\" or 200cm" },
    { value: 202, label: "6'8\" or 202cm" },
    { value: 205, label: "6'9\" or 205cm" },
    { value: 208, label: "6'10\" or 208cm" },
    { value: 210, label: "6'11\" or 210cm" },
    { value: 213, label: "7' or 213cm and above" },
  ];

  console.log("localStorageUser", localStorageUser);
  const getSelfprofileCheck = async () => {
    try {
      const resp = await request(
        `/member/current/matrimony-profile/get-profile-for-status`
      );
      if (resp) {
        setSelfProfileCheck(resp?.selfProfileExists);
      } else {
        // console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  const getStatesData = async () => {
    try {
      const resp = await request(`/member/current/states`);
      if (resp?.length) {
        const newList = resp;
        setStatesData(newList); // Update the state directly
      }
    } catch (error) {
      console.error("Error fetching states data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getStatesData();
      await getSelfprofileCheck();
      if (initialValues?.currentState) {
        setStatesDataApi(!statesDataApi);
      } else {
        localStorage.setItem("age", "18");
      }
    };
    checkIfFieldsAreFilled(
      [],
      form.getFieldsValue([
        "name",
        "gender",
        "phonenodetails",
        "emailiddetails",
        "maritalStatus",
        "height",
        "currentCity",
        "currentState",
        "profileFor",
      ])
    );
    fetchData();
  }, []);

  useEffect(() => {
    console.log(profileFor);
    if (profileFor === "SELF") {
      form.setFieldsValue({
        name: localStorageUser?.member.name,
        gender: localStorageUser?.member.gender,
        dateOfbirth: dayjs(localStorageUser?.member.dob),
        currentState: localStorageUser?.member.currentState,
        currentCity: localStorageUser?.member.currentCity,
        phonenodetails: localStorageUser?.phoneNumber,
        emailiddetails: localStorageUser?.email,
      });
    }
  }, [profileFor, form]);

  useEffect(() => {
    if (initialValues?.currentState && statesData) {
      getCityData(initialValues?.currentState);
    }
  }, [statesDataApi]);

  const getCityData = async (value: string) => {
    try {
      const selectedstate1 = statesData.find(
        (state: any) => state.name === value
      );
      if (selectedstate1) {
        const resp = await request(
          `/member/current/state/${selectedstate1.id}/cities`
        );
        if (resp?.length) {
          const newCommunityList = resp;
          setCityData(newCommunityList);
        }
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const currentDate = dayjs();
  const date18YearsAgo = currentDate.subtract(18, "year");

  const eighteenyearsago = moment().subtract(18, "years");
  const disabledDate = (current: any) => current && current >= eighteenyearsago;

  const CelebrityNameCheck1 = async (name: any) => {
    const payload = {
      method: "POST",
      body: JSON.stringify({
        name,
      }),
    };
    const celebrityNameCheckResponse = await request(
      `/member/current/celebrity-name/check`,
      payload
    );
    setIsCelebrityName(celebrityNameCheckResponse?.nameMatch);

    form.validateFields(["name"]);

    return celebrityNameCheckResponse?.nameMatch;
  };

  const handlePersonalSubmit = async (values: PersonalDetails) => {
    const isCelebrity = await CelebrityNameCheck(values.name);
    if (isCelebrity) {
      return toast.error(
        "Please refrain from using the names of well-known public figures"
      );
    }

    let temp: PersonalDetails = {
      name: values.name,
      gender: values.gender,
      dateOfbirth: values.dateOfbirth,
      height: values.height,
      currentState: values.currentState,
      currentCity: values.currentCity,
      age: age,
      profileFor: values.profileFor,
      maritalStatus: values.maritalStatus,
      noOfChildren: values.noOfChildren,
      emailiddetails: values.emailiddetails?.toLowerCase(),
      phonenodetails: values.phonenodetails,
      isContactSharingAllowed: values.isContactSharingAllowed,
    };

    onSubmit(temp);
  };
  const CelebrityNameCheck = async (name: any) => {
    const payload = {
      method: "POST",
      body: JSON.stringify({
        name,
      }),
    };
    const celebrityNameCheckResponse = await request(
      `/member/current/celebrity-name/check`,
      payload
    );
    setIsCelebrityName(celebrityNameCheckResponse?.nameMatch);

    form.validateFields(["name"]);

    return celebrityNameCheckResponse?.nameMatch;
  };

  const handleDraft = async (values: any) => {
    let obj = form.getFieldsValue();
    const isCelebrity = await CelebrityNameCheck(obj.name);
    if (isCelebrity) {
      return toast.error(
        "Please refrain from using the names of well-known public figures"
      );
    }

    let temp: PersonalDetails = {
      name: obj.name,
      gender: obj.gender,
      dateOfbirth: obj.dateOfbirth,
      height: obj.height,
      currentState: obj.currentState,
      currentCity: obj.currentCity,
      age: age,
      profileFor: obj.profileFor,
      maritalStatus: obj.maritalStatus,
      noOfChildren: obj.noOfChildren,
      emailiddetails: obj.emailiddetails?.toLowerCase(),
      phonenodetails: obj.phonenodetails,
      isContactSharingAllowed: obj.isContactSharingAllowed,
    };
    onDraft(temp);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const calculate_age = (dob: string) => {
    const [day, month, year] = dob.split("-")?.map(Number);

    // Create a Date object for the birthdate
    const birthDate = new Date(year, month - 1, day); // Month is zero-based

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);

    const roundedAge = Math.floor(ageInYears);
    setAge(roundedAge);
    console.log(roundedAge);
    localStorage.setItem("age", roundedAge.toString());
  };

  const handledobchange = (date: any, dateString: any) => {
    if (dateString?.length > 0) {
      calculate_age(dateString);
    } else {
      localStorage.setItem("age", "18");
      setAge(18);
    }
  };

  const [showKids, setShowKids] = useState(false);

  const handleMaritalStatus = (e: any) => {
    if (e !== "SINGLE") {
      setShowKids(true);
    } else {
      setShowKids(false);
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setchecked(e.target.checked);
  };

  const handleFirstName = (e: any) => {
    localStorage.setItem("nname", e?.target.value);
    const name = e.target.value;
    if (name.length === 0) {
      setFullNameError("Enter Name");
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
      setFullNameError("Special characters are not allowed");
    } else {
      setFullNameError("");
    }
  };

  const receivedDOB = initialValues?.dateOfbirth;
  let formattedDOB = null;

  if (receivedDOB) {
    const recievedDobStr = receivedDOB.toString();
    const datePart = recievedDobStr.split("T")[0];
    formattedDOB = datePart;
  }

  const checkIfFieldsAreFilled = (changedValues: any, allValues: any) => {
    // Check if all the required fields are filled
    const requiredFields = [
      "name",
      "gender",
      "phonenodetails",
      "emailiddetails",
      "maritalStatus",
      "height",
      "currentCity",
      "currentState",
      "profileFor",
    ];

    const areAllFieldsFilled = requiredFields.every(
      (field) => allValues[field] && allValues[field].toString().trim() !== ""
    );

    // Enable the button if all required fields are filled, otherwise disable it
    setIsButtonDisabled(!areAllFieldsFilled);
  };

  return (
    <div className="Multistepdesign">
      <Form
        className="personaldetailsForm"
        form={form}
        onFinish={handlePersonalSubmit}
        onValuesChange={checkIfFieldsAreFilled}
        initialValues={{
          profileFor: initialValues?.profileFor,
          name: initialValues?.name,
          dateOfbirth:
            (initialValues?.dateOfbirth && dayjs(formattedDOB, "YYYY-MM-DD")) ||
            "",
          currentState: initialValues?.currentState,
          currentCity: initialValues?.currentCity,
          height: initialValues?.height,
          gender: initialValues?.gender,
          maritalStatus: initialValues?.maritalStatus,
          noOfChildren: initialValues?.noOfChildren,
          emailiddetails: initialValues?.emailiddetails,
          phonenodetails: initialValues?.phonenodetails
            ? initialValues?.phonenodetails
            : "",
          isContactSharingAllowed: initialValues?.isContactSharingAllowed,
        }}
      >
        <h2>Personal Details</h2>
        <Form.Item
          className="form-item"
          label="Profile For"
          name="profileFor"
          rules={[
            { required: true, message: "Profile for is required" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  selfProfileCheck &&
                  value === "SELF" &&
                  completeInitialValues?.status !== "PUBLISH"
                ) {
                  return Promise.reject(
                    new Error("You can only create one profile for yourself.")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => setProfileFor(e?.target?.value)}
          >
            <Radio.Button
              value="SELF"
              disabled={completeInitialValues?.status === "PUBLISH"}
            >
              Self
            </Radio.Button>
            <Radio.Button
              value="OTHERS"
              disabled={completeInitialValues?.status === "PUBLISH"}
            >
              Others
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <div className="sideby">
          <Form.Item
            className="form-item"
            validateStatus={fullNameError ? "error" : ""}
            help={fullNameError}
            label="Full Name"
            name="name"
            rules={[
              { required: true, message: "Name is required" },
              // {
              //   validator(_, value: any) {
              //     if (isCelebrityName) {
              //       return Promise.reject("Celebrity name is not allowed!");
              //     } else {
              //       return Promise.resolve();
              //     }
              //   },
              // },
            ]}
          >
            <Input onChange={handleFirstName} placeholder="Enter Name" />
          </Form.Item>
          <Form.Item></Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Date of Birth"
            name="dateOfbirth"
            rules={[{ required: true, message: "Date of birth is required" }]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              placeholder="Date of Birth"
              disabledDate={disabledDate}
              defaultPickerValue={date18YearsAgo}
              onChange={handledobchange}
            />
          </Form.Item>
          <Form.Item
            className="form-item"
            label="State"
            name="currentState"
            rules={[{ required: true, message: "state is required" }]}
          >
            <Select
              onChange={(value: any) => {
                setselectedstate(
                  statesData?.find(
                    (state: any) => state?.name === selectedstate?.name
                  )
                );
                form.setFieldValue("currentCity", null);
                getCityData(value);
                localStorage.setItem("place", value);
              }}
              value={selectedstate}
              placeholder="Select a state"
              showSearch
              className="select-field overflow-hidden"
            >
              {statesData?.map((state: any) => {
                return (
                  <Select.Option key={state?.id} value={state?.name}>
                    {" "}
                    {state?.name} - {state?.countryName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="City"
            name="currentCity"
            rules={[{ required: true, message: "city is required" }]}
          >
            <Select
              onChange={(value: any) => {
                localStorage.setItem("city", value);
              }}
              value={selectedstate}
              placeholder="Select a city"
              className="select-field overflow-hidden"
              showSearch
              notFoundContent={
                <div className="flex justify-center items-center text-gray-400 bg-slate-50 py-4">
                  Please select the state
                </div>
              }
            >
              {cityData?.map((state: any) => {
                return (
                  <Select.Option key={state?.id} value={state?.name}>
                    {" "}
                    {state?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Height"
            name="height"
            rules={[{ required: true, message: "Height is required" }]}
          >
            <Select placeholder="Select your height" showSearch
               filterOption={(input:any, option:any) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
            >
              
{heightOptions.map((option) => (
        <Select.Option key={option.value} value={option.value} label={option.label}>
          {option.label}
        </Select.Option>
      ))}
      
            </Select>
          </Form.Item>

          <Form.Item
            className="form-item"
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Gender is required" }]}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={(values) => {
                localStorage.setItem("gender", values.target.value);
              }}
            >
              <Radio.Button value="MALE">Male</Radio.Button>
              <Radio.Button value="FEMALE">Female</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            label="Marital status"
            name="maritalStatus"
            className="form-item"
            rules={[
              {
                transform: (v) => v.toUpperCase(),
                required: true,
                message: "Profile for is required",
              },
            ]}
          >
            <Select
              onChange={handleMaritalStatus}
              placeholder="Select marital status"
              className="select-field overflow-hidden"
              showSearch
            >
              <Select.Option value="SINGLE">Single</Select.Option>
              <Select.Option value="DIVORCED">Divorced</Select.Option>
              <Select.Option value="WIDOWED">Widowed</Select.Option>
              <Select.Option value="SEPERATED">Seperated</Select.Option>
              <Select.Option value="OTHER">Other</Select.Option>
            </Select>
          </Form.Item>
          {showKids ? (
            <Form.Item
              className="form-item"
              label="Number of childrens"
              name="noOfChildren"
            >
              <Input />
            </Form.Item>
          ) : (
            <Form.Item></Form.Item>
          )}
        </div>

        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Email"
            name="emailiddetails"
            rules={[
              {
                type: "email",
                message: "The input is not valid a E-mail!",
              },
              { required: true, message: "Email is required" },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            className="form-item phone-input"
            label="Phone Number"
            name="phonenodetails"
            rules={[{ required: true, message: "Phone number is required" }]}
          >
            <PhoneInput country={"in"} />
          </Form.Item>
        </div>
        <Form.Item name="isContactSharingAllowed" valuePropName="checked">
          <Checkbox
            checked={checked}
            onChange={onChange}
            className="checkboxmulti"
          >
            Do you want to show contact details?
          </Checkbox>
        </Form.Item>
        <div className="button-container">
          {flow === "create" ? (
            <Button
              // className={"Next-btn draft"}
              className={
                isButtonDisabled ? "Next-btn draft disabled" : "Next-btn draft"
              }
              disabled={isButtonDisabled}
              type="primary"
              onClick={handleDraft}
            >
              Save draft
            </Button>
          ) : (
            <Button
              className={
                isButtonDisabled ? "Next-btn draft disabled" : "Next-btn draft"
              }
              // className={"Next-btn draft"}
              disabled={isButtonDisabled}
              type="primary"
              onClick={handleDraft}
            >
              Save
            </Button>
          )}
          <Button
            className={isButtonDisabled ? "Next-btn disabled " : "Next-btn"}
            // className={"Next-btn"}
            // disabled={!!usernameError}
            disabled={isButtonDisabled}
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

interface ProfessionalDetails {
  occupation: string;
  educationalDetails: string;
  minIncome: number;
  maxIncome: number;
  employementDetails: string;
  occupationCountry: string;
}

interface ProfessionalDetailsFormProps {
  onSubmit: (details: ProfessionalDetails) => void;
  onDraft: (
    details: ProfessionalDetails,
    personal: PersonalDetails,
    family: FamilyDetails,
    hobbies: Hobbies
  ) => void;
  personalDetails: PersonalDetails;
  familyDetails: FamilyDetails;
  hobbies: Hobbies;
  initialValues: ProfessionalDetails | null;
}

const ProfessionalDetailsForm: React.FC<ProfessionalDetailsFormProps> = ({
  onSubmit,
  onDraft,
  personalDetails,
  familyDetails,
  hobbies,
  initialValues,
}) => {
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const [selectedOccupation, setselectedOccupation] = useState("");
  const [selectedIncome, setIncome] = useState<any>();
  const [selectedCountry, setselectedCountry] = useState("");
  const [selectededucation, setselectededucation] = useState("");
  const [occupationsData, setOccupationsData] = useState<any>();
  const [educationData, setEducationData] = useState<any>();

  const getCommonData = async () => {
    const OccupationResp = await request(
      `/member/current/matrimony-profile/professions`
    );
    if (OccupationResp?.length) {
      const newList = OccupationResp;
      setOccupationsData(newList);
    }
    const resp = await request(
      `/member/current/matrimony-profile/qualifications`
    );
    if (resp?.length) {
      const newList = resp;
      setEducationData(newList);
    }
  };

  useEffect(() => {
    getCommonData();
  }, []);

  const countrydata = countries;
  const selectedCountriesdata = countrydata?.find(
    (country: any) => country.name === selectedCountry
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const selectedOccupationdata = occupationsData.find(
  //   (occupation: any) => occupation.name === selectedOccupation
  // );

  const handleProfessionalSubmit = (values: any) => {
    const temp = selectedIncome ? selectedIncome : "";
    const editTemp = values?.income?.match(/\d+/g);
    const [minIncome1, maxIncome1] =
      flow === "create" ? temp.split("-").map(Number) : editTemp;

    let Obj = {
      educationalDetails: values.educationalDetails,
      employementDetails: values.employementDetails,
      minIncome: parseInt(minIncome1),
      maxIncome: parseInt(maxIncome1),
      occupation: values.occupation,
      occupationCountry: values.occupationCountry,
    };
    onSubmit(Obj);
  };

  const handleDraft = (values: any) => {
    const temp = selectedIncome ? selectedIncome : "";
    // const editTemp = values?.income?.match(/\d+/g);
    const [minIncome1, maxIncome1] = temp.split("-").map(Number);

    let obj = form.getFieldsValue();
    let temp1: ProfessionalDetails = {
      occupation: obj.occupation,
      educationalDetails: obj.educationalDetails,
      minIncome: parseInt(minIncome1),
      maxIncome: parseInt(maxIncome1),
      employementDetails: obj.employementDetails,
      occupationCountry: obj.occupationCountry,
    };

    let temp2: PersonalDetails = {
      name: personalDetails.name,
      gender: personalDetails.gender,
      dateOfbirth: personalDetails.dateOfbirth,
      height: personalDetails.height,
      currentState: personalDetails.currentState,
      currentCity: personalDetails.currentCity,
      profileFor: personalDetails.profileFor,
      age: personalDetails.age,
      maritalStatus: personalDetails.maritalStatus,
      noOfChildren: personalDetails.noOfChildren,
      emailiddetails: personalDetails.emailiddetails,
      phonenodetails: personalDetails.phonenodetails,
      isContactSharingAllowed: personalDetails.isContactSharingAllowed,
    };

    let temp3: FamilyDetails = {
      religion: familyDetails.religion,
      caste: familyDetails.caste,
      subcaste: familyDetails.subcaste,
      rashi: familyDetails.rashi,
      division: familyDetails.division,
      nakshatra: familyDetails.nakshatra,
      gothra: familyDetails.gothra,
      foodhabit: familyDetails.foodhabit,
      familyName: familyDetails.familyName,
    };

    let temp4: Hobbies = {
      hobbies: hobbies.hobbies,
    };
    onDraft(temp1, temp2, temp3, temp4);
  };

  const capitalizeWords = (input: string) => {
    const words = input.split(" ");

    const capitalizedWords = words?.map((word) => {
      if (word?.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    });

    return capitalizedWords.join(" ");
  };

  return (
    <div className="Multistepdesign">
      <Form
        className="personaldetailsForm"
        form={form}
        onFinish={handleProfessionalSubmit}
        initialValues={{
          occupation: initialValues?.occupation
            ? initialValues?.occupation
            : null,
          educationalDetails: initialValues?.educationalDetails,
          income:
            initialValues?.minIncome === 0
              ? "0 - 3 lakhs Per Annum"
              : initialValues?.minIncome === 3
              ? "3-6 lakhs Per Annum"
              : initialValues?.minIncome === 6
              ? "6-9 lakhs Per Annum"
              : initialValues?.minIncome === 9
              ? "9-12 lakhs Per Annum"
              : initialValues?.minIncome === 12
              ? "12-15 lakhs Per Annum"
              : initialValues?.minIncome === 15
              ? "15 - 20 lakhs Per Annum"
              : initialValues?.minIncome === 20
              ? "20 - 25 lakhs Per Annum"
              : initialValues?.minIncome === 25
              ? "25 - 30 lakhs Per Annum"
              : initialValues?.minIncome === 30
              ? "30 - 35 lakhs Per Annum"
              : initialValues?.minIncome === 35
              ? "35 - 40 lakhs Per Annum"
              : initialValues?.minIncome === 40
              ? "40 - 45 lakhs Per Annum"
              : initialValues?.minIncome === 45
              ? "45 - 50 lakhs Per Annum"
              : initialValues?.minIncome === 50
              ? "50 - 60 lakhs Per Annum"
              : initialValues?.minIncome === 60
              ? "60 - 70 lakhs Per Annum"
              : initialValues?.minIncome === 70
              ? "70 - 80 lakhs Per Annum"
              : initialValues?.minIncome === 80
              ? "80 - 90 lakhs Per Annum"
              : initialValues?.minIncome === 90
              ? "90 lakhs - 1 Crore Per Annum"
              : initialValues?.minIncome === 100
              ? "Above 1 Crore Per Annum"
              : null,
          employementDetails: initialValues?.employementDetails,
          occupationCountry: initialValues?.occupationCountry
            ? initialValues?.occupationCountry
            : null,
        }}
      >
        <h2>Professional Details</h2>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Occupation"
            name="occupation"
            rules={[{ required: true, message: "Occupation is required" }]}
          >
            <Select
              showSearch
              onChange={(value) => {
                setselectedOccupation(value);
                localStorage.setItem("occupation", value);
              }}
              value={selectedOccupation}
              optionFilterProp="children"
              placeholder="Select an occupation"
              className="select-field overflow-hidden"
            >
              {occupationsData?.map((occupations: any) => (
                <Select.Option key={occupations.name} value={occupations.name}>
                  {" "}
                  {capitalizeWords(occupations.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Highest Qualification"
            name="educationalDetails"
            rules={[
              { required: true, message: "Highest qualification is required" },
            ]}
          >
            <Select
              showSearch
              onChange={(value) => {
                setselectededucation(value);
                localStorage.setItem("eduDetails", value);
              }}
              value={selectededucation}
              className="select-field overflow-hidden"
              placeholder="Select the highest qualification "
            >
              {educationData?.map((education: any) => (
                <Select.Option key={education.id} value={education.name}>
                  {" "}
                  {education.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Income"
            name="income"
            rules={[{ required: true, message: "Income is required" }]}
          >
            <Select
              onChange={(value) => {
                setIncome(value);
              }}
              className="select-field overflow-hidden"
              placeholder="Select income range"
            >
              <Select.Option value="0-3">0 - 3 lakhs Per Annum</Select.Option>
              <Select.Option value="3-6">3 - 6 lakhs Per Annum</Select.Option>
              <Select.Option value="6-9">6 - 9 lakhs Per Annum</Select.Option>
              <Select.Option value="9-12">9 - 12 lakhs Per Annum</Select.Option>
              <Select.Option value="12-15">
                12 - 15 lakhs Per Annum
              </Select.Option>
              <Select.Option value="15-20">
                15 - 20 lakhs Per Annum
              </Select.Option>
              <Select.Option value="20-25">
                20 - 25 lakhs Per Annum
              </Select.Option>
              <Select.Option value="25-30">
                25 - 30 lakhs Per Annum
              </Select.Option>
              <Select.Option value="30-35">
                30 - 35 lakhs Per Annum
              </Select.Option>
              <Select.Option value="35-40">
                35 - 40 lakhs Per Annum
              </Select.Option>
              <Select.Option value="40-50">
                40 - 50 lakhs Per Annum
              </Select.Option>
              <Select.Option value="50-60">
                50 - 60 lakhs Per Annum
              </Select.Option>
              <Select.Option value="60-70">
                60 - 70 lakhs Per Annum
              </Select.Option>
              <Select.Option value="70-80">
                70 - 80 lakhs Per Annum
              </Select.Option>
              <Select.Option value="80-90">
                80 - 90 lakhs Per Annum
              </Select.Option>
              <Select.Option value="90-100">
                90 lakhs - 1 Crore Per Annum
              </Select.Option>
              <Select.Option value="100-101">
                Above 1 Crore Per Annum
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Employement Details"
            name="employementDetails"
            rules={[
              { required: true, message: "Employment details is required" },
            ]}
          >
            <Input placeholder="Enter current employment details" />
          </Form.Item>
        </div>
        <Form.Item
          className="form-item"
          label="Occupation Country"
          name="occupationCountry"
          rules={[{ required: true, message: "Country is required" }]}
        >
          <Select
            showSearch
            className="select-field overflow-hidden"
            onChange={(value) => {
              setselectedCountry(value.name);
            }}
            value={selectedCountriesdata}
            optionFilterProp="children"
            placeholder="Select country"
          >
            {countrydata?.map((country: any) => (
              <Select.Option key={country.name} value={country.name}>
                {" "}
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="button-container">
          {flow === "create" ? (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save draft
            </Button>
          ) : (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save
            </Button>
          )}
          <Button className="Next-btn" type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

interface FamilyDetails {
  religion: string;
  caste: string;
  subcaste: string;
  rashi: string;
  division: string;
  nakshatra: string;
  gothra: string;
  foodhabit: string;
  familyName: string;
}

interface FamilyDetailsFormProps {
  onSubmit: (details: FamilyDetails) => void;
  onDraft: (details: FamilyDetails, personal: PersonalDetails) => void;
  personalDetails: PersonalDetails;
  initialValues: FamilyDetails | null;
}

const FamilyDetailsForm: React.FC<FamilyDetailsFormProps> = ({
  onSubmit,
  onDraft,
  personalDetails,
  initialValues,
}) => {
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const [selectedReligion, setselectedReligion] = useState("");
  const [selectedCaste, setselectedCaste] = useState("");
  const [selectedRashi, setSelectedRashi] = useState("");
  const [selectedNakshatra, setselectednakshatra] = useState("");
  const [selectedGothra, setselectedGothra] = useState("");
  const [religionDataApi, setReligionDataApi] = useState<boolean>(false);

  const [religionData, setReligionData] = useState<any>();
  const [gothrasData, setGothrasData] = useState<any>();
  const [casteData, setCasteData] = useState<any>();
  const [subcasteData, setSubcasteData] = useState<any>();
  const [rashiData, setRashiData] = useState<any>();
  const [nakshatradata, setNakshatradata] = useState<any>();
  const localStorageUser = JSON.parse(localStorage.getItem("user") || "");

  useEffect(() => {
    if (personalDetails?.profileFor === "SELF") {
      form.setFieldsValue({
        religion: localStorageUser?.member?.religion,
        caste: localStorageUser?.member?.caste,
        subcaste: localStorageUser?.member?.subCaste,
        familyName: localStorageUser?.member?.familyName,
      });
      setselectedReligion(localStorageUser?.member?.religion);
      setselectedCaste(localStorageUser?.member?.caste);
    }
  }, [form]);

  const getCommonData = async () => {
    const GotramResp = await request(
      `/member/current/matrimony-profile/gothras`
    );
    if (GotramResp?.length) {
      const newList = GotramResp;
      setGothrasData(newList);
    }
    const rashiResp = await request(`/member/current/matrimony-profile/rashis`);
    if (rashiResp?.length) {
      const newList = rashiResp;
      setRashiData(newList);
      getReligionData();
    }
  };

  const getReligionData = async () => {
    const resp = await request(`/member/current/matrimony-profile/religion`);
    if (resp?.length) {
      const newCommunityList = resp;
      setReligionData(newCommunityList);
    }
  };
  const getCasteData = async (value: any) => {
    const selectedReligion1 = religionData?.find(
      (religion: any) => religion?.name === value
    );
    const resp = await request(
      `/member/current/matrimony-profile/caste/${selectedReligion1?.religionId}`
    );
    if (resp?.length) {
      const newCommunityList = resp;
      setCasteData(newCommunityList);
    }
  };
  const getSubcasteData = async (value: any) => {
    const selectedcaste1 = casteData?.find(
      (religion: any) => religion?.name === value
    );
    const resp = await request(
      `/member/current/matrimony-profile/sub-caste/${selectedcaste1?.casteId}`
    );
    if (resp?.length) {
      const newCommunityList = resp;
      setSubcasteData(newCommunityList);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getReligionData();
      if (initialValues?.religion) {
        setReligionDataApi(!religionDataApi);
        setselectedReligion(initialValues?.religion);
      }
    };
    fetchData();
    getCommonData();
  }, []);

  useEffect(() => {
    if (initialValues?.religion && religionData) {
      getCasteData(initialValues?.religion);
    }
  }, [religionDataApi]);

  // const selectedReligiondata = religionData?.find(
  //   (religion: any) => religion.name === selectedReligion
  // );

  const handleFamilySubmit = (values: FamilyDetails) => {
    onSubmit(values);
  };

  const handleDraft = (values: any) => {
    let obj = form.getFieldsValue();
    let temp1: FamilyDetails = {
      religion: obj.religion,
      caste: obj.caste,
      subcaste: obj.subcaste,
      rashi: obj.rashi,
      division: obj.division,
      nakshatra: obj.nakshatra,
      gothra: obj.gothra,
      foodhabit: obj.foodhabit,
      familyName: obj.familyName,
    };

    let temp2: PersonalDetails = {
      name: personalDetails.name,
      gender: personalDetails.gender,
      dateOfbirth: personalDetails.dateOfbirth,
      height: personalDetails.height,
      currentState: personalDetails.currentState,
      currentCity: personalDetails.currentCity,
      profileFor: personalDetails.profileFor,
      age: personalDetails.age,
      maritalStatus: personalDetails.maritalStatus,
      noOfChildren: personalDetails.noOfChildren,
      emailiddetails: personalDetails.emailiddetails,
      phonenodetails: personalDetails.phonenodetails,
      isContactSharingAllowed: personalDetails.isContactSharingAllowed,
    };

    onDraft(temp1, temp2);
  };

  return (
    <div className="Multistepdesign">
      <Form
        className="personaldetailsForm"
        form={form}
        onFinish={handleFamilySubmit}
        initialValues={{
          religion: initialValues?.religion ? initialValues?.religion : null,
          caste: initialValues?.caste ? initialValues?.caste : null,
          subcaste: initialValues?.subcaste,
          rashi: initialValues?.rashi,
          nakshatra: initialValues?.nakshatra,
          division: initialValues?.division,
          gothra: initialValues?.gothra,
          familyName: initialValues?.familyName,
          foodhabit: initialValues?.foodhabit,
        }}
      >
        <h2>Family Details</h2>
        <div className="sideby">
          <Form.Item
            label="Religion:"
            name="religion"
            rules={[{ required: true, message: "Religion is required" }]}
          >
            <Select
              showSearch
              placeholder="Please select religion"
              className="select-field overflow-hidden"
              onChange={(value) => {
                setselectedReligion(value);
                setselectedCaste("");
                setCasteData("");
                form.setFieldValue("caste", null);
                getCasteData(value);
              }}
              value={selectedReligion}
            >
              {religionData?.map((religion: any) => (
                <Select.Option
                  key={religion?.religionId}
                  value={religion?.name}
                >
                  {" "}
                  {religion?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Caste"
            name="caste"
            rules={[
              { required: casteData?.length > 0, message: "Caste is required" },
            ]}
          >
            {casteData?.length > 0 ? (
              <Select
                showSearch
                className="select-field overflow-hidden"
                placeholder="Please select caste"
                onChange={(value) => {
                  setselectedCaste(value);
                  form.setFieldValue("subcaste", null);

                  getSubcasteData(value);
                }}
                value={selectedCaste}
                disabled={!selectedReligion || casteData?.length < 1}
              >
                {selectedReligion &&
                  casteData?.map((caste: any, index: any) => (
                    <Option
                      key={caste?.id + index.toString()}
                      value={caste?.name}
                    >
                      {" "}
                      {caste?.name}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input placeholder="Please enter the Caste" />
            )}
          </Form.Item>
        </div>
        {selectedReligion === "Hindu" || initialValues?.rashi ? (
          <>
            <div className="sideby">
              {
                <Form.Item
                  label="Sub Caste"
                  name="subcaste"
                  rules={[
                    {
                      required: subcasteData?.length > 0,
                      message: "Sub-caste is required",
                    },
                  ]}
                >
                  {subcasteData?.length > 0 ? (
                    <Select
                      showSearch
                      className="select-field overflow-hidden"
                      placeholder="Please select Subcaste"
                      value={selectedCaste}
                      disabled={!selectedCaste}
                    >
                      {subcasteData?.map((caste: any) => (
                        <Option key={caste?.id} value={caste?.name}>
                          {" "}
                          {caste?.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Input placeholder="Please enter the Subcaste" />
                  )}
                </Form.Item>
              }

              <Form.Item
                label="Rashi"
                name="rashi"
                rules={[{ required: true, message: "Rashi is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Please select rashi"
                  className="select-field overflow-hidden"
                  onChange={(value) => {
                    setSelectedRashi(value);
                    const selectedRashi1 = rashiData?.find(
                      (rashi: any) => rashi?.name === value
                    );
                    setNakshatradata(selectedRashi1?.nakshatras);
                    form.setFieldValue("nakshatra", null);
                  }}
                  value={selectedRashi}
                >
                  {rashiData?.map((rashi: any) => (
                    <Select.Option key={rashi.id} value={rashi.name}>
                      {" "}
                      {rashi.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="sideby">
              <Form.Item
                label="Nakshatra"
                name="nakshatra"
                rules={[{ required: true, message: "Nakshatra is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Please select nakshatra"
                  onChange={(value) => {
                    setselectednakshatra(value);
                  }}
                  value={selectedNakshatra}
                  className="select-field overflow-hidden"
                  disabled={!selectedRashi}
                >
                  {nakshatradata?.map((nakshatra: any, index: any) => (
                    <Select.Option key={index} value={nakshatra}>
                      {" "}
                      {nakshatra}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Division"
                name="division"
                rules={[{ required: true, message: "Division is required" }]}
              >
                <Input placeholder="Please enter division ex: GM, SC" />
              </Form.Item>
            </div>
            <Form.Item
              label="Gothra"
              name="gothra"
              rules={[{ required: true, message: "Gothra is required" }]}
            >
              <Select
                showSearch
                placeholder="Please select gothra"
                onChange={(value) => {
                  setselectedGothra(value);
                }}
                value={selectedGothra}
                className="select-field overflow-hidden"
              >
                {gothrasData?.map((gothra: any) => (
                  <Select.Option key={gothra.id} value={gothra.name}>
                    {" "}
                    {gothra.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <> </>
        )}
        <div className="sideby">
          <Form.Item
            label="Family Name"
            name="familyName"
            rules={[{ required: true, message: "Family Name is required" }]}
          >
            <Input placeholder="Please enter family name" />
          </Form.Item>
          <Form.Item
            label="Food Habits"
            name="foodhabit"
            rules={[{ required: true, message: "Food habits is required" }]}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={(value) => {
                localStorage.setItem("food", value?.target.value);
              }}
            >
              <Radio.Button value="VEGETARIAN">Vegetarian</Radio.Button>
              <Radio.Button value="NON_VEGETARIAN">Non-vegetarian</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="button-container">
          {flow === "create" ? (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save draft
            </Button>
          ) : (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save
            </Button>
          )}
          <Button className="Next-btn" type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

//hobbies interface

interface Hobbies {
  hobbies: string[];
}

interface HobbiesDetailsFormProps {
  onSubmit: (details: Hobbies) => void;
  onDraft: (
    details: Hobbies,
    personal: PersonalDetails,
    family: FamilyDetails
  ) => void;
  personalDetails: PersonalDetails;
  familyDetails: FamilyDetails;
  initialValues: Hobbies | null;
}

//Hobbies Page

const HobbiesDetailsForm: React.FC<HobbiesDetailsFormProps> = ({
  onSubmit,
  onDraft,
  personalDetails,
  familyDetails,
  initialValues,
}) => {
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const { CheckableTag } = Tag;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const creativeCategories = [
    {
      icon: <img alt="" src={icons.writing} />,
      hobby: "Writing",
    },
    {
      icon: <img alt="" src={icons.cooking} />,
      hobby: "Cooking",
    },
    {
      icon: <img alt="" src={icons.singing} />,
      hobby: "Singing",
    },
    {
      icon: <img alt="" src={icons.photography} />,
      hobby: "Photography",
    },
    {
      icon: <img alt="" src={icons.instrument} />,
      hobby: "Playing Instruments",
    },
    {
      icon: <img alt="" src={icons.art} />,
      hobby: "Painting",
    },
    {
      icon: <img alt="" src={icons.DIY} />,
      hobby: "DIY crafts",
    },
    {
      icon: <img alt="" src={icons.dancing} />,
      hobby: "Dancing",
    },
    {
      icon: <img alt="" src={icons.acting} />,
      hobby: "Acting",
    },
    {
      icon: <img alt="" src={icons.poetry} />,
      hobby: "Poetry",
    },
    {
      icon: <img alt="" src={icons.gardening} />,
      hobby: "Gardening",
    },
    {
      icon: <img alt="" src={icons.blogging} />,
      hobby: "Blogging",
    },
    {
      icon: <img alt="" src={icons.content} />,
      hobby: "Content creation",
    },
    {
      icon: <img alt="" src={icons.designing} />,
      hobby: "Designing",
    },
    {
      icon: <img alt="" src={icons.doodling} />,
      hobby: "Doodling",
    },
    {
      icon: <img alt="" src={icons.pets} />,
      hobby: "Pets",
    },
    {
      icon: <img alt="" src={icons.foodie} />,
      hobby: "Foodie",
    },
    {
      icon: <img alt="" src={icons.vegan} />,
      hobby: "Vegan",
    },
    {
      icon: <img alt="" src={icons.news} />,
      hobby: "News & Politics",
    },
    {
      icon: <img alt="" src={icons.socialService} />,
      hobby: "Social service",
    },
    {
      icon: <img alt="" src={icons.entre} />,
      hobby: "Entrepreneurship",
    },
    {
      icon: <img alt="" src={icons.homeDecor} />,
      hobby: "Home Decor",
    },
    {
      icon: <img alt="" src={icons.invest} />,
      hobby: "Investments",
    },
    {
      icon: <img alt="" src={icons.fashionBeauty} />,
      hobby: "Fashion & Beauty",
    },
  ];

  const funCategories = [
    {
      icon: <img alt="" src={icons.movies} />,
      hobby: "Movies",
    },
    {
      icon: <img alt="" src={icons.music} />,
      hobby: "Music",
    },
    {
      icon: <img alt="" src={icons.travel} />,
      hobby: "Travelling",
    },
    {
      icon: <img alt="" src={icons.reading} />,
      hobby: "Reading",
    },
    {
      icon: <img alt="" src={icons.sports} />,
      hobby: "Sports",
    },
    {
      icon: <img alt="" src={icons.social} />,
      hobby: "Social Media",
    },
    {
      icon: <img alt="" src={icons.gaming} />,
      hobby: "Gaming",
    },
    {
      icon: <img alt="" src={icons.bingewatching} />,
      hobby: "Binge-watching",
    },
    {
      icon: <img alt="" src={icons.biking} />,
      hobby: "Biking",
    },
    {
      icon: <img alt="" src={icons.clubbing} />,
      hobby: "Clubbing",
    },
    {
      icon: <img alt="" src={icons.shopping} />,
      hobby: "Shopping",
    },

    {
      icon: <img alt="" src={icons.standup} />,
      hobby: "Stand ups",
    },
  ];

  const fitnessCategories = [
    {
      icon: <img alt="" src={icons.running} />,
      hobby: "Runnning",
    },
    {
      icon: <img alt="" src={icons.walking} />,
      hobby: "Walking",
    },
    {
      icon: <img alt="" src={icons.cycling} />,
      hobby: "Cycling",
    },
    {
      icon: <img alt="" src={icons.yoga} />,
      hobby: "Yoga & Meditation",
    },
    {
      icon: <img alt="" src={icons.hiking} />,
      hobby: "Trekking",
    },
    {
      icon: <img alt="" src={icons.workingOut} />,
      hobby: "Working Out",
    },
    {
      icon: <img alt="" src={icons.swimming} />,
      hobby: "Swimming",
    },
  ];

  const handleHobbiesSubmit = (values: Hobbies) => {
    values["hobbies"] = selectedTags;
    onSubmit(values);
  };

  const handleHobbiesDraft = (values: any) => {
    let temp1: Hobbies = {
      hobbies: selectedTags,
    };
    let temp2: PersonalDetails = {
      name: personalDetails.name,
      gender: personalDetails.gender,
      dateOfbirth: personalDetails.dateOfbirth,
      height: personalDetails.height,
      currentState: personalDetails.currentState,
      currentCity: personalDetails.currentCity,
      profileFor: personalDetails.profileFor,
      age: personalDetails.age,
      maritalStatus: personalDetails.maritalStatus,
      noOfChildren: personalDetails.noOfChildren,
      emailiddetails: personalDetails.emailiddetails,
      phonenodetails: personalDetails.phonenodetails,
      isContactSharingAllowed: personalDetails.isContactSharingAllowed,
    };
    let temp3: FamilyDetails = {
      religion: familyDetails.religion,
      caste: familyDetails.caste,
      subcaste: familyDetails.subcaste,
      rashi: familyDetails.rashi,
      division: familyDetails.division,
      nakshatra: familyDetails.nakshatra,
      gothra: familyDetails.gothra,
      foodhabit: familyDetails.foodhabit,
      familyName: familyDetails.familyName,
    };
    onDraft(temp1, temp2, temp3);
  };

  const [selectedTags, setSelectedTags] = useState<string[]>(
    initialValues?.hobbies ? initialValues?.hobbies : []
  );

  const handleTagChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <>
      <div className="Multistepdesign">
        <Form form={form} onFinish={handleHobbiesSubmit}>
          <h2>Hobbies</h2>
          <h3>
            <b>Creative</b>
          </h3>
          <Form.Item>
            {creativeCategories?.map((tag) => (
              <CheckableTag
                key={tag.hobby}
                checked={selectedTags.includes(tag.hobby)}
                onChange={(checked) => handleTagChange(tag.hobby, checked)}
              >
                <div className="tag-content">
                  {tag.icon}
                  {tag.hobby}
                </div>
              </CheckableTag>
            ))}
          </Form.Item>
          <h3>
            <b>Fun</b>
          </h3>
          <Form.Item>
            {funCategories?.map((tag) => (
              <CheckableTag
                key={tag.hobby}
                checked={selectedTags.includes(tag.hobby)}
                onChange={(checked) => handleTagChange(tag.hobby, checked)}
              >
                {/* <img src={tag.icon} /> */}
                <div className="tag-content">
                  {tag.icon}
                  {tag.hobby}
                </div>
              </CheckableTag>
            ))}
          </Form.Item>
          <h3>
            <b>Fitness</b>
          </h3>
          <Form.Item>
            {fitnessCategories?.map((tag) => (
              <CheckableTag
                key={tag.hobby}
                checked={selectedTags.includes(tag.hobby)}
                onChange={(checked) => handleTagChange(tag.hobby, checked)}
              >
                <div className="tag-content">
                  {tag.icon}
                  {tag.hobby}
                </div>
              </CheckableTag>
            ))}
          </Form.Item>

          <div className="button-container">
            {flow === "create" ? (
              <Button
                className="Next-btn draft"
                type="primary"
                onClick={handleHobbiesDraft}
              >
                Save draft
              </Button>
            ) : (
              <Button
                className="Next-btn draft"
                type="primary"
                onClick={handleHobbiesDraft}
              >
                Save
              </Button>
            )}
            <Button className="Next-btn" type="primary" htmlType="submit">
              Next
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

interface LookingForDetails {
  religionPreference: string;
  castePreference: string;
  educationPreference: string;
  location: string;
  maxHeight: number;
  minHeight: number;
  foodPreference: string;

  // caste: string[];
}

interface LookingForDetailsFormProps {
  onSubmit: (details: LookingForDetails) => void;
  onDraft: (
    details: LookingForDetails,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails,
    employement: ProfessionalDetails,
    hobbies: Hobbies
  ) => void;
  personalDetails: PersonalDetails;
  familyDetails: FamilyDetails;
  hobbies: Hobbies;
  professionalDetails: ProfessionalDetails;
  initialValues: LookingForDetails | null;
}

const LookingForDetailsForm: React.FC<LookingForDetailsFormProps> = ({
  onSubmit,
  onDraft,
  personalDetails,
  familyDetails,
  hobbies,
  professionalDetails,
  initialValues,
}) => {
  interface Caste {
    id: number;
    name: string;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ReligionEntry {
    id: number;
    name: string;
    castealias: string;
    castes: Caste[];
  }
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const [selectedReligion, setselectedReligion] = useState("");
  const [religionData, setReligionData] = useState<any>();
  const [statesData, setStatesData] = useState<any>();
  const [casteData, setCasteData] = useState<any>();
  const [noCaste, setNoCaste] = useState<boolean>(false);
  const [religionDataApi, setReligionDataApi] = useState<boolean>(false);
  const [educationData, setEducationData] = useState<any>();

  const getCommonData = async () => {
    const resp = await request(
      `/member/current/matrimony-profile/qualifications`
    );
    if (resp?.length) {
      const newList = resp;
      setEducationData(newList);
    }
  };

  const getReligionData = async () => {
    const resp = await request(`/member/current/matrimony-profile/religion`);
    if (resp?.length) {
      setNoCaste(false);
      const newCommunityList = resp;
      setReligionData(newCommunityList);
      setReligionData((prevState: any) => [
        ...prevState,
        { id: "noPreferences", name: "noPreferences" },
      ]);
    } else {
      setNoCaste(true);
    }
  };
  const getStatesData = async () => {
    const resp = await request(`/member/current/states`);
    if (resp?.length) {
      const newList = resp;
      setStatesData(newList);
      setStatesData((prevState: any) => [
        ...prevState,
        { id: "noPreferences", name: "noPreferences" },
      ]);
    }
  };
  const getCasteData = async (value: any) => {
    const selectedReligion1 = religionData?.find(
      (religion: any) => religion?.name === value
    );
    const resp = await request(
      `/member/current/matrimony-profile/caste/${selectedReligion1?.religionId}`
    );
    if (resp?.length) {
      const newCommunityList = resp;
      setNoCaste(false);
      setCasteData(newCommunityList);
      setCasteData((prevState: any) => [
        ...prevState,
        { id: "noPreferences", name: "no Preferences" },
      ]);
    } else {
      setNoCaste(true);
      setCasteData([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getReligionData();
      if (initialValues?.religionPreference) {
        setReligionDataApi(!religionDataApi);
        setselectedReligion(initialValues?.religionPreference);
      }
    };
    fetchData();
    getStatesData();
    getCommonData();
  }, []);

  useEffect(() => {
    if (initialValues?.religionPreference && religionData) {
      getCasteData(initialValues?.religionPreference);
    }
  }, [religionDataApi]);

  const handleLookingForSubmit = (values: LookingForDetails) => {
    onSubmit(values);
  };

  const handleDraft = (values: any) => {
    let obj = form.getFieldsValue();
    let temp1: LookingForDetails = {
      religionPreference: obj.religionPreference,
      castePreference: obj.castePreference,
      educationPreference: obj.educationPreference,
      location: obj.location,
      maxHeight: obj.maxHeight,
      minHeight: obj.minHeight,
      foodPreference: obj.foodPreference,
    };

    let temp2: PersonalDetails = {
      name: personalDetails.name,
      gender: personalDetails.gender,
      dateOfbirth: personalDetails.dateOfbirth,
      height: personalDetails.height,
      currentState: personalDetails.currentState,
      currentCity: personalDetails.currentCity,
      profileFor: personalDetails.profileFor,
      age: personalDetails.age,
      maritalStatus: personalDetails.maritalStatus,
      noOfChildren: personalDetails.noOfChildren,
      emailiddetails: personalDetails.emailiddetails,
      phonenodetails: personalDetails.phonenodetails,
      isContactSharingAllowed: personalDetails.isContactSharingAllowed,
    };

    let temp3: FamilyDetails = {
      religion: familyDetails.religion,
      caste: familyDetails.caste,
      subcaste: familyDetails.subcaste,
      rashi: familyDetails.rashi,
      division: familyDetails.division,
      nakshatra: familyDetails.nakshatra,
      gothra: familyDetails.gothra,
      foodhabit: familyDetails.foodhabit,
      familyName: familyDetails.familyName,
    };

    let temp4: ProfessionalDetails = {
      occupation: professionalDetails.occupation,
      educationalDetails: professionalDetails.educationalDetails,
      minIncome: professionalDetails.minIncome,
      maxIncome: professionalDetails.maxIncome,
      employementDetails: professionalDetails.employementDetails,
      occupationCountry: professionalDetails.occupationCountry,
    };

    let temp5: Hobbies = {
      hobbies: hobbies.hobbies,
    };

    onDraft(temp1, temp2, temp3, temp4, temp5);
  };

  return (
    <div className="Multistepdesign">
      <Form
        className="personaldetailsForm"
        form={form}
        onFinish={handleLookingForSubmit}
        initialValues={{
          religionPreference: initialValues?.religionPreference || undefined,
          castePreference: initialValues?.castePreference || [],
          educationPreference: initialValues?.educationPreference || [],
          maxHeight: initialValues?.maxHeight || undefined,
          minHeight: initialValues?.minHeight || undefined,
          foodPreference: initialValues?.foodPreference || undefined,
          location: initialValues?.location || undefined,
        }}
      >
        <h2>Looking For</h2>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Religion Preference"
            name="religionPreference"
            rules={[
              { required: true, message: "Religion preference is required" },
            ]}
          >
            <Select
              showSearch
              placeholder="Please select religion"
              onChange={(value) => {
                setselectedReligion(value);
                form.resetFields(["castePreference"]);
                getCasteData(value);
              }}
              value={selectedReligion}
              className="select-field overflow-hidden"
            >
              {religionData?.map((religion: any) => (
                <Select.Option key={religion.id} value={religion.name}>
                  {" "}
                  {religion.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item "
            label="Caste Preference"
            name="castePreference"
            rules={[
              {
                required: !noCaste,
                message: "Caste preference is required",
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              maxTagCount={"responsive"}
              placeholder="Please select caste"
              className="select-field "
              disabled={noCaste}
            >
              {noCaste ? (
                <></>
              ) : (
                <>
                  {selectedReligion &&
                    !noCaste &&
                    casteData?.map((caste: any) => (
                      <Option key={caste?.id} value={caste?.name}>
                        {caste?.name}
                      </Option>
                    ))}
                </>
              )}
            </Select>
          </Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Education Preference"
            name="educationPreference"
            rules={[
              { required: true, message: "Education preference is required" },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              maxTagCount={"responsive"}
              placeholder="Select education preferences"
              className="select-field"
            >
              {educationData?.map((education: any) => (
                <Select.Option key={education.name} value={education.name}>
                  {" "}
                  {education.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="State Preference"
            name="location"
            rules={[
              { required: true, message: "state preference is required" },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              maxTagCount={"responsive"}
              optionFilterProp="children"
              placeholder="Select a state"
              className="select-field"
            >
              {statesData?.map((state: any) => (
                <Select.Option key={state.name} value={state.name}>
                  {" "}
                  {state.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="sideby">
          <Form.Item
            className="form-item"
            label="Min Height Preference"
            name="minHeight"
            rules={[
              { required: true, message: "Height preference is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const maxHeight = getFieldValue("maxHeight");
                  if (value && maxHeight && value >= maxHeight) {
                    return Promise.reject(
                      new Error("Min Height must be less than Max Height")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="Select height range"
              className="select-field overflow-hidden"
            >
              <Select.Option value={135}>4'5" or 135cm</Select.Option>
              <Select.Option value={137}>4'6" or 137cm</Select.Option>
              <Select.Option value={140}>4'7" or 140cm</Select.Option>
              <Select.Option value={142}>4'8" or 142cm</Select.Option>
              <Select.Option value={145}>4'9" or 145cm</Select.Option>
              <Select.Option value={147}>4'10" or 147cm</Select.Option>
              <Select.Option value={149}>4'11" or 149cm</Select.Option>
              <Select.Option value={152}>5' or 152cm</Select.Option>
              <Select.Option value={155}>5'1" or 155cm</Select.Option>
              <Select.Option value={157}>5'2" or 157cm</Select.Option>
              <Select.Option value={160}>5'3" or 160cm</Select.Option>
              <Select.Option value={163}>5'4" or 163cm</Select.Option>
              <Select.Option value={165}>5'5" or 165cm</Select.Option>
              <Select.Option value={167}>5'6" or 167cm</Select.Option>
              <Select.Option value={170}>5'7" or 170cm</Select.Option>
              <Select.Option value={172}>5'8" or 172cm</Select.Option>
              <Select.Option value={175}>5'9" or 175cm</Select.Option>
              <Select.Option value={177}>5'10" or 177cm</Select.Option>
              <Select.Option value={180}>5'11" or 180cm</Select.Option>
              <Select.Option value={182}>6' or 182cm</Select.Option>
              <Select.Option value={185}>6'1" or 185cm</Select.Option>
              <Select.Option value={187}>6'2" or 187cm</Select.Option>
              <Select.Option value={190}>6'3" or 190cm</Select.Option>
              <Select.Option value={193}>6'4" or 193cm</Select.Option>
              <Select.Option value={195}>6'5" or 195cm</Select.Option>
              <Select.Option value={197}>6'6" or 197cm</Select.Option>
              <Select.Option value={200}>6'7" or 200cm</Select.Option>
              <Select.Option value={202}>6'8" or 202cm</Select.Option>
              <Select.Option value={205}>6'9" or 205cm</Select.Option>
              <Select.Option value={208}>6'10" or 208cm</Select.Option>
              <Select.Option value={210}>6'11" or 210cm</Select.Option>
              <Select.Option value={213}>7' or 213cm and above</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Max Height Preference"
            name="maxHeight"
            rules={[
              { required: true, message: "Height preference is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const minHeight = getFieldValue("minHeight");
                  if (value && minHeight && value <= minHeight) {
                    return Promise.reject(
                      new Error("Max Height must be greater than Min Height")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              placeholder="Select height range"
              className="select-field overflow-hidden"
            >
              <Select.Option value={135}>4'5" or 135cm</Select.Option>
              <Select.Option value={137}>4'6" or 137cm</Select.Option>
              <Select.Option value={140}>4'7" or 140cm</Select.Option>
              <Select.Option value={142}>4'8" or 142cm</Select.Option>
              <Select.Option value={145}>4'9" or 145cm</Select.Option>
              <Select.Option value={147}>4'10" or 147cm</Select.Option>
              <Select.Option value={149}>4'11" or 149cm</Select.Option>
              <Select.Option value={152}>5' or 152cm</Select.Option>
              <Select.Option value={155}>5'1" or 155cm</Select.Option>
              <Select.Option value={157}>5'2" or 157cm</Select.Option>
              <Select.Option value={160}>5'3" or 160cm</Select.Option>
              <Select.Option value={163}>5'4" or 163cm</Select.Option>
              <Select.Option value={165}>5'5" or 165cm</Select.Option>
              <Select.Option value={167}>5'6" or 167cm</Select.Option>
              <Select.Option value={170}>5'7" or 170cm</Select.Option>
              <Select.Option value={172}>5'8" or 172cm</Select.Option>
              <Select.Option value={175}>5'9" or 175cm</Select.Option>
              <Select.Option value={177}>5'10" or 177cm</Select.Option>
              <Select.Option value={180}>5'11" or 180cm</Select.Option>
              <Select.Option value={182}>6' or 182cm</Select.Option>
              <Select.Option value={185}>6'1" or 185cm</Select.Option>
              <Select.Option value={187}>6'2" or 187cm</Select.Option>
              <Select.Option value={190}>6'3" or 190cm</Select.Option>
              <Select.Option value={193}>6'4" or 193cm</Select.Option>
              <Select.Option value={195}>6'5" or 195cm</Select.Option>
              <Select.Option value={197}>6'6" or 197cm</Select.Option>
              <Select.Option value={200}>6'7" or 200cm</Select.Option>
              <Select.Option value={202}>6'8" or 202cm</Select.Option>
              <Select.Option value={205}>6'9" or 205cm</Select.Option>
              <Select.Option value={208}>6'10" or 208cm</Select.Option>
              <Select.Option value={210}>6'11" or 210cm</Select.Option>
              <Select.Option value={213}>7' or 213cm and above</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item"
            label="Food Preference"
            name="foodPreference"
            rules={[{ required: true, message: "Food preference is required" }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="VEGETARIAN" className="mb-1">
                Vegetarian
              </Radio.Button>
              <Radio.Button value="NON_VEGETARIAN">Non-vegetarian</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="button-container">
          {flow === "create" ? (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save draft
            </Button>
          ) : (
            <Button
              className="Next-btn draft"
              type="primary"
              onClick={handleDraft}
            >
              Save
            </Button>
          )}
          <Button className="Next-btn" type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

//contact details
interface ContactDetails {
  images: File[];
  pdfs: File[];
  removedImages: File[];
  aboutMe: string;
  show: string;
  status: string;
  showThumbnail: boolean;
  associateWith: string;
  communityIds: string[];
}

interface ContactDetailsFormProps {
  onSubmit: (details: ContactDetails) => void;
  onDraft: (
    details: ContactDetails,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails,
    hobbies: Hobbies,
    professionalDetails: ProfessionalDetails,
    lookingFor: LookingForDetails
  ) => void;
  personalDetails: PersonalDetails;
  familyDetails: FamilyDetails;
  hobbies: Hobbies;
  professionalDetails: ProfessionalDetails;
  lookingFor: LookingForDetails;
  initialValues: ContactDetails | null;
}

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  onSubmit,
  onDraft,
  personalDetails,
  familyDetails,
  hobbies,
  professionalDetails,
  lookingFor,
  initialValues,
}) => {
  const flow = localStorage.getItem("flow");
  const [form] = Form.useForm();
  const [photosForm, setPhotosForm] = useState(false);
  const [checked, setchecked] = useState(false);
  const [Thumbnail, setShowThumbnail] = useState(false);
  // const [thumbnailFile, setThumnailFile] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedPDFFiles, setSelectedPDFFiles] = useState<File[]>([]);
  const [isUploadDisabled, setIiUploadDisabled] = useState<number>(0);
  const [oldImages, setOldImages] = useState<File[]>(
    initialValues?.images ? initialValues?.images : []
  );
  const [removedImages, setRemovedImages] = useState<File[]>([]);
  const [aboutMeText, setAboutMeText] = useState(initialValues?.aboutMe || "");
  const [aboutMeData, setAboutMeData] = useState<any>(aboutMeJsonData);
  const [backendtext, setBackendText] = useState("");
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [selectedCommunities, setSelectedCommunities] = useState<any>([]);
  const [selectedVisibility, setSelectedVisibility] = useState<boolean>(
    initialValues?.associateWith === "OPEN_TO_ALL"
  );
  const [communityData, setCommunityData] = useState<any>();
  const onChange = (e: CheckboxChangeEvent) => {
    setchecked(e.target.checked);
  };

  const handleContactDetailsSubmit = (values: any) => {
    values["images"] = selectedFiles;
    values["removedImages"] = removedImages;
    values["pdfs"] = selectedPDFFiles;
    values["aboutMe"] = aboutMeText;
    // const initial= initialValues?.status

    if (
      values?.communityIds?.includes("noCommunity") ||
      values?.communityIds?.includes("None")
    ) {
      values.communityIds = [];
      values.associateWith = true;
    } else {
      values.associateWith = false;
    }
    values.status = "PUBLISH";
    onSubmit(values);
  };

  const handleDraft = (values: any) => {
    // let obj = form.getFieldsValue();
    let temp1: ContactDetails = {
      images: selectedFiles,
      removedImages: removedImages,
      pdfs: selectedPDFFiles,
      aboutMe: aboutMeText,
      show: initialValues?.show ? initialValues?.show : "EVERYONE",
      status: "DRAFT",
      associateWith: initialValues?.associateWith
        ? initialValues?.associateWith
        : "",
      communityIds:
        initialValues?.associateWith === "ONLY_COMMUNITY" &&
        initialValues?.communityIds?.length !== 0
          ? initialValues?.communityIds
          : [],
      showThumbnail: false,
    };
    let temp2: PersonalDetails = {
      name: personalDetails.name,
      gender: personalDetails.gender,
      dateOfbirth: personalDetails.dateOfbirth,
      height: personalDetails.height,
      currentState: personalDetails.currentState,
      currentCity: personalDetails.currentCity,
      profileFor: personalDetails.profileFor,
      age: personalDetails.age,
      maritalStatus: personalDetails.maritalStatus,
      noOfChildren: personalDetails.noOfChildren,
      emailiddetails: personalDetails.emailiddetails,
      phonenodetails: personalDetails.phonenodetails,
      isContactSharingAllowed: personalDetails.isContactSharingAllowed,
    };
    let temp3: FamilyDetails = {
      religion: familyDetails.religion,
      caste: familyDetails.caste,
      subcaste: familyDetails.subcaste,
      rashi: familyDetails.rashi,
      division: familyDetails.division,
      nakshatra: familyDetails.nakshatra,
      gothra: familyDetails.gothra,
      foodhabit: familyDetails.foodhabit,
      familyName: familyDetails.familyName,
    };
    let temp4: Hobbies = {
      hobbies: hobbies.hobbies,
    };
    let temp5: ProfessionalDetails = {
      occupation: professionalDetails.occupation,
      educationalDetails: professionalDetails.educationalDetails,
      minIncome: professionalDetails.minIncome,
      maxIncome: professionalDetails.maxIncome,
      employementDetails: professionalDetails.employementDetails,
      occupationCountry: professionalDetails.occupationCountry,
    };

    let temp6: LookingForDetails = {
      religionPreference: lookingFor.religionPreference,
      castePreference: lookingFor.castePreference,
      educationPreference: lookingFor.educationPreference,
      location: lookingFor.location,
      maxHeight: lookingFor.maxHeight,
      minHeight: lookingFor.minHeight,
      foodPreference: lookingFor.foodPreference,
    };
    onDraft(temp1, temp2, temp3, temp4, temp5, temp6);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    let selectedImages = selectedFiles.filter((file) =>
      file.type.startsWith("image/")
    );

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      if (
        file.type.startsWith("image/") &&
        selectedImages?.length < 5 - oldImages?.length
      ) {
        selectedImages.push(file);
      } else if (
        file.type.startsWith("image/") &&
        selectedImages?.length >= 5 - oldImages?.length
      ) {
        toast.error("Maximum five images allowed");
      }
    }
    // let newArray = [];
    files.forEach((item: any) => {
      const formattedSize: number = item?.size / (1024 * 1024);
      if (item.type.startsWith("image/") && formattedSize > 10) {
        toast.error(
          `${item.name} ${
            item.type.startsWith("image") && formattedSize > 10
              ? "Exceeds 10 megabytes"
              : "Exceeds 50 megabytes"
          }   `
        );
      }
    });
    setSelectedFiles([...selectedImages]);
    const isUploadDisabled = selectedImages?.length;
    setIiUploadDisabled(isUploadDisabled);
  };

  const handlePDFFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files: any = Array.from(event.target.files ?? []);
    setSelectedPDFFiles(files);
  };

  const completeSurvey = (survey?: any) => {
    setPhotosForm(true);
  };

  const handleDelete = (index: number) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const HandleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const getAboutUs = async () => {
    // let token = localStorage.getItem("token");
    let reqBody = {
      method: "POST",
      body: JSON.stringify({
        name: localStorage.getItem("nname"),
        gender: localStorage.getItem("gender"),
        age: localStorage.getItem("age"),
        state: localStorage.getItem("place"),
        city: localStorage.getItem("city"),
        occupation: localStorage.getItem("occupation"),
        educationDetails: localStorage.getItem("eduDetails"),
        foodHabit: localStorage.getItem("food"),
      }),
    };
    // const resp = await axios.post(
    //   `${BASE_URL}/member/current/matrimony-profile/about/me`,
    //   reqBody,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    const resp = await request(
      `/member/current/matrimony-profile/about/me`,
      reqBody
    );
    if (resp) {
      setBackendText(resp);
      setAboutMeText(resp);
    } else {
    }
  };

  const handleReqChange = (e: any) => {
    if (e === "ONLY_TO_REQUESTED_PEOPLE") {
      setShowThumbnail(true);
    } else {
      setShowThumbnail(false);
    }
  };

  const getCommunityData = async () => {
    const resp = await request(`/member/current/communities-list`);
    if (resp) {
      const newCommunityList = resp;
      setCommunityData(newCommunityList);
      setCommunityData((prevState: any) => [
        ...prevState,
        { communityId: "noCommunity", name: "None" },
      ]);
    }
  };

  useEffect(() => {
    if (flow === "create" || initialValues?.aboutMe === "") {
      getAboutUs();
    }
    getCommunityData();
  }, []);

  const handleCommunityChange = (value: any) => {
    if (value) {
      // console.log("selected value", value);
      if (value.includes("noCommunity")) {
        setSelectedCommunities([]);
        form.setFieldsValue({ communityIds: ["noCommunity"] });
      } else {
        setSelectedCommunities(value);
      }
    }
  };

  return (
    <div className="Multistepdesign">
      {photosForm ? (
        <div>
          {initialValues?.images && oldImages && (
            <div style={{ marginBottom: "20px" }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {oldImages?.map((data: any, index: any) => (
                  <div className="mr-5" style={{ position: "relative" }}>
                    <img
                      className="image"
                      src={data}
                      alt=""
                      style={{
                        borderRadius: "10px",
                        transition: "transform 0.3s ease",
                        height: "130px",
                        width: "auto",
                        margin: "10px",
                      }}
                    />
                    <button
                      className="bi-trash-fill text-red-700 text-2xl existingDelete"
                      onClick={(event) => {
                        oldImages.splice(
                          oldImages.findIndex((item) => item === data),
                          1
                        );
                        setRemovedImages([...removedImages, data]);
                      }}
                    ></button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <Form
            className="personaldetailsForm"
            form={form}
            onFinish={handleContactDetailsSubmit}
            initialValues={{
              show: initialValues?.show,
              showThumbnail: initialValues?.showThumbnail,
              // associateWith:
              //   initialValues?.associateWith === "OPEN_TO_ALL" ? true : false,
              communityIds:
                initialValues?.associateWith === "OPEN_TO_ALL"
                  ? "noCommunity"
                  : initialValues?.communityIds,
            }}
          >
            <div>
              <div className="flex flex-col ">
                <div
                  className={selectedFiles?.length > 0 ? "image-preview" : ""}
                >
                  <Form.Item
                    className="flex justify-center items-center images-item"
                    // style={{ height: "auto" }}
                    rules={[{ required: true, message: "Photos are required" }]}
                  >
                    {selectedFiles?.length > 0 && (
                      <div>
                        <ul className="flex gap-5 w-full flex-wrap justify-center h-auto">
                          {selectedFiles?.map((file, index) => (
                            <li
                              key={index}
                              className=" my-3 relative"
                              style={{
                                width: `calc(100% / ${selectedFiles?.length} - 5%)`,
                              }}
                            >
                              {file.type.startsWith("image/") ? (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  className="postImage h-[150px] w-full border border-gray-400"
                                />
                              ) : (
                                ""
                              )}
                              <button
                                className="bi bi-trash-fill text-red-700 text-2xl selectfile-delete-icon"
                                onClick={(event) => {
                                  handleDelete(index);
                                  event.stopPropagation();
                                }}
                              ></button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div
                      className="MatrimonyBrowseButton flex justify-center items-center cursor-pointer"
                      onClick={(event) => {
                        HandleClick();
                        event.stopPropagation();
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        ref={hiddenFileInput}
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                        disabled={isUploadDisabled >= 6 ? true : false}
                      />
                      <div className="py-3">Add Photos</div>
                    </div>
                  </Form.Item>
                </div>

                <div className="p-4 flex">
                  <div className="w-full md:w-1/2 p-4">
                    <h2 className="text-xl font-semibold mb-2">Do's</h2>
                    <ul className="list-disc ml-4 mb-4">
                      <li className="mb-2">
                        <span className="text-green-500">
                          <i className="bi bi-check-circle"></i>
                        </span>{" "}
                        Your Photo should be front facing and your entire face
                        should be visible.
                      </li>
                      <li className="mb-2">
                        <span className="text-green-500">
                          <i className="bi bi-check-circle"></i>
                        </span>{" "}
                        Ensure that your Photo is recent and not with a group.
                      </li>
                      <li className="mb-2">
                        <span className="text-green-500">
                          <i className="bi bi-check-circle"></i>
                        </span>{" "}
                        Ensure that your Photo is in portrait view.
                      </li>
                      <li className="mb-2">
                        <span className="text-green-500">
                          <i className="bi bi-check-circle"></i>
                        </span>{" "}
                        You can upload up to 5 Photos to your Profile.
                      </li>
                      <li className="mb-4">
                        <span className="text-green-500">
                          <i className="bi bi-check-circle"></i>
                        </span>{" "}
                        Each Photo must be less than 10 MB in size and must be
                        in one of the following formats: jpg, png.
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 p-4">
                    <h2 className="text-xl font-semibold mb-2">Don'ts</h2>
                    <ul className="list-disc ml-4">
                      <li className="mb-2">
                        <span className="text-red-500">
                          <i className="bi bi-x-circle"></i>
                        </span>{" "}
                        Don't upload Watermarked, digitally enhanced, morphed
                        Photos or Photos with your personal information.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="sideby">
                <Form.Item
                  name="pdfs"
                  className="form-item"
                  label="Upload biodata"
                >
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handlePDFFileChange}
                  />
                </Form.Item>
                <Form.Item
                  className="form-item"
                  label="Show to"
                  name="show"
                  rules={[
                    {
                      required: true,
                      message: "Select profile type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select option"
                    onChange={handleReqChange}
                    className="select-field overflow-hidden"
                  >
                    <Select.Option value="ONLY_TO_REQUESTED_PEOPLE">
                      Only to requested people
                    </Select.Option>
                    <Select.Option value="EVERYONE">Everyone</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="sideby">
                <Form.Item
                  className="form-item"
                  label="Link to Community"
                  name="communityIds"
                  rules={[
                    {
                      required: true,
                      message: "community select is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    maxTagCount={"responsive"}
                    placeholder="Please select the communities to be linked"
                    // disabled={selectedVisibility}
                    onChange={(value) => {
                      handleCommunityChange(value);
                    }}
                    value={
                      initialValues?.communityIds
                        ? initialValues?.communityIds
                        : selectedCommunities
                    }
                    className="select-field"
                  >
                    {communityData?.map((community: any) => (
                      <Select.Option
                        key={community?.communityId}
                        value={community?.communityId}
                        disabled={
                          community?.block || community?.communityBlocked
                        }
                      >
                        {" "}
                        {community?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            {Thumbnail ? (
              <Form.Item name="showThumbnail" valuePropName="checked">
                <Checkbox
                  checked={checked}
                  onChange={onChange}
                  className="checkboxmulti"
                >
                  Do you want to show thumbnail?
                </Checkbox>
              </Form.Item>
            ) : (
              ""
            )}
            <div className="button-container">
              {flow === "create" && (
                <Button
                  className="Next-btn draft"
                  type="primary"
                  onClick={handleDraft}
                >
                  Save draft
                </Button>
              )}

              <Button className="Next-btn" type="primary" htmlType="submit">
                Complete
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <>
          <Survey.Survey
            json={aboutMeData}
            value={aboutMeText}
            data={{
              aboutMe: aboutMeText,
            }}
            onValueChanged={(survey: any, options: any) => {
              // console.log("values", survey);
              setAboutMeText(survey?.valuesHash?.aboutMe);
            }}
            onComplete={(survey: any) => {}}
          />
          <div className="flex justify-end">
            <Button
              className="Next-btn"
              type="primary"
              onClick={async () => {
                try {
                  const abusiveResult = await CheckBadWordsExist(aboutMeText);
                  if (abusiveResult?.status === true) {
                    if (
                      !abusiveResult?.profanity &&
                      !abusiveResult.words?.length
                    ) {
                      completeSurvey();
                    } else if (abusiveResult?.profanity) {
                      let resultString = abusiveResult.words.join(" ");
                      toast.error(
                        `Not allowed to write abusive content ${resultString} `
                      );
                    }
                  } else {
                    toast.error(
                      abusiveResult?.message || "Something went wrong"
                    );
                  }
                } catch (error) {
                  // console.log(error);
                  toast.error("An error occurred while checking for bad words");
                }
              }}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

// MultiStepForm Component

interface MultiStepFormProps {
  onClose: () => void;
  draftId: string;
}
const MultiStepForm: React.FC<MultiStepFormProps> = ({ onClose, draftId }) => {
  const [loader, setLoader] = useState<Boolean>(true);
  const [initialVals, setInitialVals] = useState({});
  const [showStatus, setShowStatus] = useState("");
  const [status, setStatus] = useState("");
  const [personalFormInitial, setPersonalFormInitial] =
    useState<PersonalDetails | null>(null);
  const [familyFormInitial, setFamilyFormInitial] =
    useState<FamilyDetails | null>(null);
  const [professionalInitial, setProfessionalInitial] =
    useState<ProfessionalDetails | null>(null);
  const [hobbiesInitial, setHobbiesInitial] = useState<Hobbies | null>(null);
  const [lookingForDetailsInitial, setLookingForDetailsInitial] =
    useState<LookingForDetails | null>(null);
  const [contactDetailsInitial, setContactDetailsInitial] =
    useState<ContactDetails | null>(null);

  function calculateAge(dateString: string): string {
    const birthDate = new Date(dateString);
    console.log(birthDate, "date");
    const currentDate = new Date();

    const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);

    const roundedAge = Math.floor(ageInYears);
    console.log("roundedAge", roundedAge);

    return roundedAge.toString();
  }

  const getUser = async () => {
    try {
      // const resp = await axios.get(
      //   `${BASE_URL}/member/current/matrimony-profile/draft/${draftId}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      const apiDraftId = localStorage.getItem("apiDraftId");
      console.log("apiDraftId", apiDraftId);
      const resp = await request(
        `/member/current/matrimony-profile/draft/${apiDraftId}`
      );
      console.log("resp -associateWith", resp);
      if (resp) {
        localStorage.setItem("associateWith", resp?.associateWith);
        localStorage.setItem("communityIds", resp?.communityIds[0] || []);
        localStorage.setItem("nname", resp?.member?.name);
        localStorage.setItem("gender", resp?.member?.gender);
        localStorage.setItem("age", calculateAge(resp?.member?.dob));
        localStorage.setItem("place", resp?.member?.currentState);
        localStorage.setItem("occupation", resp?.educationDetails?.occupation);
        localStorage.setItem(
          "eduDetails",
          resp?.educationDetails?.educationDetails
        );
        localStorage.setItem("food", resp?.foodHabit);
        setShowStatus(resp?.show);
        setStatus(resp?.status);
        //Setting initial values Obj
        let initialValsobj = {
          aboutMe: resp?.aboutMe ? resp?.aboutMe : "",
          id: resp?.id ? resp?.id : "",
          userId: resp?.userId ? resp?.userId : "",
          profileFor: resp?.profileFor,
          foodHabit: resp?.foodHabit ? resp?.foodHabit : "",
          show: resp?.show,
          status: resp?.status,
          associateWith: resp?.associateWith ? resp?.associateWith : "",
          communityIds: resp?.communityIds ? resp?.communityIds : [],
          member: {
            name: resp?.member?.name ? resp?.member?.name : "",
            dob: resp?.member?.dob ? resp?.member?.dob : "",
            currentState: resp?.member?.currentState
              ? resp?.member?.currentState
              : "",
            currentCity: resp?.member?.currentCity
              ? resp?.member?.currentCity
              : "",
            gender: resp?.member?.gender ? resp?.member?.gender : "",
            height: resp?.member?.height ? resp?.member?.name : 0,
          },
          contactDetails: {
            phoneNumber: resp?.contactDetails?.phoneNumber
              ? resp?.contactDetails?.phoneNumber
              : "",
            emailId: resp?.contactDetails?.emailId
              ? resp?.contactDetails?.emailId
              : "",
            contactSharingAllowed: resp?.contactDetails?.contactSharingAllowed
              ? resp?.contactDetails?.contactSharingAllowed
              : "",
          },
          maritalStatus: {
            status: resp?.maritalStatus?.status
              ? resp?.maritalStatus?.status
              : "SINGLE",
            noOfChildren: resp?.maritalStatus?.noOfChildren
              ? resp?.maritalStatus?.noOfChildren
              : 0,
          },
          educationDetails: {
            occupation: resp?.educationDetails?.occupation
              ? resp?.educationDetails?.occupation
              : "",
            educationalDetails: resp?.educationDetails?.educationDetails
              ? resp?.educationDetails?.educationDetails
              : "",
            income: resp?.educationDetails?.income
              ? resp?.educationDetails?.income
              : 0,
            employementDetails: resp?.educationDetails?.employementDetails
              ? resp?.educationDetails?.employementDetails
              : "",
            ocuupationCountry: resp?.educationDetails?.ocuupationCountry
              ? resp?.educationDetails?.ocuupationCountry
              : "",
          },
          familyDetails: {
            religion: resp?.familyDetails?.religion
              ? resp?.familyDetails?.religion
              : "",
            caste: resp?.familyDetails?.caste ? resp?.familyDetails?.caste : "",
            subcaste: resp?.familyDetails?.subCaste
              ? resp?.familyDetails?.subCaste
              : "",
            rashi: resp?.familyDetails?.rashi ? resp?.familyDetails?.rashi : "",
            division: resp?.familyDetails?.division
              ? resp?.familyDetails?.division
              : "",
            nakshatra: resp?.familyDetails?.nakshathra
              ? resp?.familyDetails?.nakshathra
              : "",
            gothra: resp?.familyDetails?.gothra
              ? resp?.familyDetails?.gothra
              : "",
            foodhabit: resp?.foodHabit ? resp?.foodHabit : "",
            familyName: resp?.familyDetails?.familyName
              ? resp?.familyDetails?.familyName
              : "",
          },
          lookingFor: {
            castePreference: resp?.lookingFor?.castePreference
              ? resp?.lookingFor?.castePreference
              : "",
            educationPreference: resp?.lookingFor?.educationPreference
              ? resp?.lookingFor?.educationPreference
              : "",
            location: resp?.lookingFor?.state ? resp?.lookingFor?.state : "",
            maxHeight: resp?.lookingFor?.maxHeight
              ? resp?.lookingFor?.maxHeight
              : 0,
            minHeight: resp?.lookingFor?.minHeight
              ? resp?.lookingFor?.minHeight
              : 0,
            foodPreference: resp?.lookingFor?.foodPreference
              ? resp?.lookingFor?.foodPreference
              : "",
          },
          hobbies: resp?.hobbies,
        };
        setInitialVals(initialValsobj);
        // localStorage.setItem("userMatrimonyIntialValue",initialValsobj?.toString())
        const ageLocal = localStorage.getItem("age");
        const ageInt = ageLocal !== null ? parseInt(ageLocal) : 0;

        // Setting personal details page initial values
        let personalInitial = {
          profileFor: resp?.profileFor,
          name: resp?.member?.name ? resp?.member?.name : "",
          age: resp?.age ? resp?.age : ageInt,
          dateOfbirth: resp?.member?.dob ? resp?.member?.dob : "",
          currentState: resp?.member?.currentState
            ? resp?.member?.currentState
            : "",
          currentCity: resp?.member?.currentCity
            ? resp?.member?.currentCity
            : "",
          height: resp?.member?.height ? resp?.member?.height : 0,
          gender: resp?.member?.gender ? resp?.member?.gender : "",
          maritalStatus: resp?.maritalStatus?.status
            ? resp?.maritalStatus?.status
            : "",
          noOfChildren: resp?.maritalStatus?.noOfChildren
            ? resp?.maritalStatus?.noOfChildren
            : 0,
          emailiddetails: resp?.contactDetails?.emailId
            ? resp?.contactDetails?.emailId
            : "",
          phonenodetails: resp?.contactDetails?.phoneNumber
            ? resp?.contactDetails?.phoneNumber
            : "",
          isContactSharingAllowed: resp?.contactDetails?.contactSharingAllowed
            ? resp?.contactDetails?.contactSharingAllowed
            : "",
        };
        setPersonalFormInitial(personalInitial);

        // Setting family details initial values
        let familyInitial = {
          religion: resp?.familyDetails?.religion
            ? resp?.familyDetails?.religion
            : "",
          caste: resp?.familyDetails?.caste ? resp?.familyDetails?.caste : "",
          subcaste: resp?.familyDetails?.subCaste
            ? resp?.familyDetails?.subCaste
            : "",
          rashi: resp?.familyDetails?.rashi ? resp?.familyDetails?.rashi : "",
          division: resp?.familyDetails?.division
            ? resp?.familyDetails?.division
            : "",
          nakshatra: resp?.familyDetails?.nakshathra
            ? resp?.familyDetails?.nakshathra
            : "",
          gothra: resp?.familyDetails?.gothra
            ? resp?.familyDetails?.gothra
            : "",
          foodhabit: resp?.foodHabit ? resp?.foodHabit : "",
          familyName: resp?.familyDetails?.familyName
            ? resp?.familyDetails?.familyName
            : "",
        };
        setFamilyFormInitial(familyInitial);

        // Setting hobbies initialValue
        let hobbiesData = {
          hobbies: resp?.hobbies,
        };

        setHobbiesInitial(hobbiesData);

        let eduInitial = {
          occupation: resp?.educationDetails?.occupation
            ? resp?.educationDetails?.occupation
            : "",
          educationalDetails: resp?.educationDetails?.educationDetails
            ? resp?.educationDetails?.educationDetails
            : "",
          minIncome: resp?.educationDetails?.minIncome
            ? resp?.educationDetails?.minIncome
            : 0,
          maxIncome: resp?.educationDetails?.maxIncome
            ? resp?.educationDetails?.maxIncome
            : 0,
          employementDetails: resp?.educationDetails?.employmentDetails
            ? resp?.educationDetails?.employmentDetails
            : "",
          occupationCountry: resp?.educationDetails?.occupationCountry
            ? resp?.educationDetails?.occupationCountry
            : "",
        };
        setProfessionalInitial(eduInitial);

        // Setting looking for initialValue
        let lookingInitial = {
          religionPreference: resp?.lookingFor?.religion
            ? resp?.lookingFor?.religion
            : "",
          castePreference: resp?.lookingFor?.castePreference
            ? resp?.lookingFor?.castePreference
            : "",
          educationPreference: resp?.lookingFor?.educationPreference
            ? resp?.lookingFor?.educationPreference
            : null,
          location: resp?.lookingFor?.location
            ? resp?.lookingFor?.location
            : "",
          minHeight: resp?.lookingFor?.minHeight
            ? resp?.lookingFor?.minHeight
            : 0,
          maxHeight: resp?.lookingFor?.maxHeight
            ? resp?.lookingFor?.maxHeight
            : 0,
          foodPreference: resp?.lookingFor?.food ? resp?.lookingFor?.food : "",
        };
        setLookingForDetailsInitial(lookingInitial);

        //Setting final page detailsx

        let lastInitial = {
          images: resp?.profileUrl ? resp?.profileUrl : [],
          removedImages: [],
          pdfs: resp?.bioDataDocument ? resp?.bioDataDocument : [],
          aboutMe: resp?.aboutMe ? resp?.aboutMe : "",
          show: resp?.show ? resp?.show : "",
          status: resp?.status ? resp?.status : "",
          associateWith: resp?.associateWith ? resp?.associateWith : "",
          communityIds: resp?.communityIds ? resp?.communityIds : [],
          showThumbnail: resp?.thumbnailImage ? resp?.thumbnailImage : false,
        };
        setContactDetailsInitial(lastInitial);

        setLoader(false);
      }
    } catch {}
  };

  useEffect(() => {
    if (draftId) {
      localStorage.setItem("flow", "edit");
      getUser();
    } else {
      setLoader(false);
      localStorage.setItem("flow", "create");
      // write an empty initial values
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [showProfessionalForm, setShowProfessionalForm] =
    useState<boolean>(false);
  const [showFamilyForm, setShowFamilyForm] = useState<boolean>(false);
  const [showLookingForForm, setShowLookingForForm] = useState<boolean>(false);
  const [showContactDetailsForm, setshowContactDetailsForm] =
    useState<boolean>(false);
  const [showMaritalDetailsForm, setshowMaritalDetailsForm] =
    useState<boolean>(false);
  const [showHobbiesrForm, setshowHobbiesForm] = useState<boolean>(false);
  const [progress, setProgress] = useState<string>("page1");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createPopup, setCreatePopup] = useState<boolean>(true);
  const [personalDetails, setPersonalDetails] =
    useState<PersonalDetails | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [professionalDetails, setProfessionalDetails] =
    useState<ProfessionalDetails | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [familyDetails, setFamilyDetails] = useState<FamilyDetails | null>(
    null
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lookingForDetails, setLookingForDetails] =
    useState<LookingForDetails | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hobbiesDetails, setHobbiesDetails] = useState<Hobbies | null>(null);
  const [formDataToSend, setFormDataToSend] = useState({
    profileFor: "",
    member: {},
    educationDetails: {},
    hobbies: {},
    maritalStatus: {},
    lookingFor: {},
    familyDetails: {},
    contactDetails: {},
    show: "",
  });

  useEffect(() => {
    const res = getToken();
  }, [progress]);

  const handlePersonalDetailsSubmit = (details: PersonalDetails) => {
    setPersonalDetails(details);
    setShowFamilyForm(true);
    const agestr = localStorage.getItem("age");
    const updatedFormDataToSend = {
      ...formDataToSend,
      profileFor: details.profileFor,
      member: {
        name: details.name,
        gender: details.gender,
        dob: details.dateOfbirth,
        currentState: details.currentState,
        currentCity: details.currentCity,
        height: details.height,
      },
      contactDetails: {
        emailId: details.emailiddetails,
        phoneNumber: details.phonenodetails,
        contactSharingAllowed: details.isContactSharingAllowed,
      },
      maritalStatus: {
        status: details.maritalStatus,
        noOfChildren: details.noOfChildren,
      },
      age: agestr !== null ? parseInt(agestr) : 0,
      // associateWith: details.associateWith ? "OPEN_TO_ALL" : "ONLY_COMMUNITY",
      // communityIds: details.associateWith ? [] : details.communityIds,

      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    setFormDataToSend(updatedFormDataToSend);
    setProgress("page2");
  };

  const handlePersonalDetailsDraft = (details: PersonalDetails) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const dobTest = getDateAndTime(
      details?.dateOfbirth?.toISOString()?.split(".")[0]?.split("T")[0]
    );
    const updatedDraftFormData = {
      show: flow === "create" ? "EVERYONE" : showStatus,
      status: status ? status : "DRAFT",
      thumbnailImage: false,
      profileFor: details.profileFor || "OTHERS",
      member: {
        name: details.name || "",
        gender: details.gender || "",
        // dob: dayjs(details.dateOfbirth).add(330, "minutes").toDate() || "",
        dob: getDateAndTime(
          details?.dateOfbirth?.toISOString()?.split(".")[0]?.split("T")[0]
        ),
        currentState: details.currentState || "",
        currentCity: details.currentCity || "",
        height: details.height || 0,
      },
      contactDetails: {
        emailId: details.emailiddetails || "",
        phoneNumber: details.phonenodetails || "",
        contactSharingAllowed: details.isContactSharingAllowed || false,
      },
      maritalStatus: {
        status: details.maritalStatus || "SINGLE",
        noOfChildren: details.noOfChildren || 0,
      },

      // associateWith:  "OPEN_TO_ALL",
      // communityIds: []

      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    setProgress("page2");
    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );
          if (resp?.status === 200) {
            toast.success("Profile drafted!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          } else {
          }
        } else {
          const apiDraftId = localStorage.getItem("apiDraftId");
          const resp = await multiFormRequest(
            // `/member/current/matrimony-profile/update/${draft}`,
            `/member/current/matrimony-profile/update/${apiDraftId}`,

            imageFormData,
            "put"
          );
          if (resp?.status === 200) {
            toast.success("Profile updated successfully!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }

      // localStorage.removeItem("draftId");
      localStorage.removeItem("place");
      localStorage.removeItem("gender");
    };
    onFinish();
  };

  const handleProfessionalDetailsSubmit = (details: ProfessionalDetails) => {
    setProfessionalDetails(details);
    setshowMaritalDetailsForm(true);
    const updatedFormDataToSend = {
      ...formDataToSend,
      educationDetails: {
        occupation: details.occupation,
        educationDetails: details.educationalDetails,
        minIncome: details.minIncome,
        maxIncome: details.maxIncome,
        employmentDetails: details.employementDetails,
        occupationCountry: details.occupationCountry,
      },
    };

    setFormDataToSend(updatedFormDataToSend);
    setProgress("page5");
  };

  const handleProfessionalDetailsDraft = (
    details: ProfessionalDetails,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails,
    hobbies: Hobbies
  ) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const updatedDraftFormData = {
      show: flow === "create" ? "EVERYONE" : showStatus,
      status: status ? status : "DRAFT",
      thumbnailImage: false,
      educationDetails: {
        occupation: details.occupation,
        educationDetails: details.educationalDetails,
        minIncome: details?.minIncome,
        maxIncome: details?.maxIncome,
        employmentDetails: details.employementDetails,
        occupationCountry: details.occupationCountry,
      },
      profileFor: personalDetails.profileFor,
      member: {
        name: personalDetails.name,
        gender: personalDetails.gender,
        // dob: dayjs(personalDetails.dateOfbirth).add(330, "minutes").toDate(),
        dob: getDateAndTime(
          personalDetails?.dateOfbirth
            ?.toISOString()
            ?.split(".")[0]
            ?.split("T")[0]
        ),
        currentState: personalDetails.currentState,
        currentCity: personalDetails.currentCity,
        height: personalDetails.height,
      },
      contactDetails: {
        emailId: personalDetails.emailiddetails,
        phoneNumber: personalDetails.phonenodetails,
        contactSharingAllowed: personalDetails.isContactSharingAllowed,
      },
      maritalStatus: {
        status: personalDetails.maritalStatus,
        noOfChildren: personalDetails.noOfChildren,
      },
      familyDetails: {
        religion: familyDetails.religion,
        division: familyDetails.division,
        caste: familyDetails.caste,
        subCaste: familyDetails.subcaste,
        rashi: familyDetails.rashi,
        gothra: familyDetails.gothra,
        nakshathra: familyDetails.nakshatra,
        familyName: familyDetails.familyName,
      },
      foodHabit: familyDetails.foodhabit,
      hobbies: hobbies.hobbies,
      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );
          if (resp?.status === 200) {
            toast.success("Profile drafted!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );
          if (resp?.status === 200) {
            toast.success("Profile drafted!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }
    };
    onFinish();
  };

  const handleFamilyDetailsSubmit = (details: FamilyDetails) => {
    setFamilyDetails(details);
    const infoArray: string[] = [];

    infoArray.push("religion");
    details.caste ? infoArray.push("caste") : console.log("no caste");
    details.subcaste ? infoArray.push("subCaste") : console.log("no caste");

    if (details.religion === "Hindu") {
      infoArray.push("rashi");
      infoArray.push("nakshatra");
      infoArray.push("gothra");
      infoArray.push("division");
    }
    const updatedFormDataToSend = {
      ...formDataToSend,
      familyDetails: {
        religion: details.religion,
        division: details.division,
        caste: details.caste,
        subCaste: details.subcaste,
        rashi: details.rashi,
        gothra: details.gothra,
        nakshathra: details.nakshatra,
        familyName: details.familyName,
      },
      foodHabit: details.foodhabit,
      formFields: infoArray,
    };

    setFormDataToSend(updatedFormDataToSend);
    setshowHobbiesForm(true);
  };

  const handleFamilyDetailsDraft = (
    details: FamilyDetails,
    personalDetails: PersonalDetails
  ) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const updatedDraftFormData = {
      show: flow === "create" ? "EVERYONE" : showStatus,
      status: status ? status : "DRAFT",
      thumbnailImage: false,
      foodHabit: details.foodhabit,
      familyDetails: {
        religion: details.religion,
        division: details.division,
        caste: details.caste,
        subCaste: details.subcaste,
        rashi: details.rashi,
        gothra: details.gothra,
        nakshathra: details.nakshatra,
        familyName: details.familyName,
      },
      profileFor: personalDetails.profileFor,
      member: {
        name: personalDetails.name,
        gender: personalDetails.gender,
        // dob: dayjs(personalDetails.dateOfbirth).add(330, "minutes").toDate(),
        dob: getDateAndTime(
          personalDetails?.dateOfbirth
            ?.toISOString()
            ?.split(".")[0]
            ?.split("T")[0]
        ),
        currentState: personalDetails.currentState,
        currentCity: personalDetails.currentCity,
        height: personalDetails.height,
      },
      contactDetails: {
        emailId: personalDetails.emailiddetails,
        phoneNumber: personalDetails.phonenodetails,
        contactSharingAllowed: personalDetails.isContactSharingAllowed,
      },
      maritalStatus: {
        status: personalDetails.maritalStatus,
        noOfChildren: personalDetails.noOfChildren,
      },

      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );
          if (resp?.status === 200) {
            toast.success("Profile drafted!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );
          if (resp?.status === 200) {
            toast.success("Profile drafted!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }
    };
    onFinish();
  };

  const handleLookingForDetailsSubmit = (details: LookingForDetails) => {
    setLookingForDetails(details);
    setshowContactDetailsForm(true);
    const updatedFormDataToSend = {
      ...formDataToSend,
      lookingFor: {
        religion: details.religionPreference,
        castePreference: details.castePreference,
        minHeight: details.minHeight,
        maxHeight: details.maxHeight,
        educationPreference: details.educationPreference,
        food: details.foodPreference,
        location: details.location,
      },
    };

    setFormDataToSend(updatedFormDataToSend);
    setProgress("page6");
  };

  const handleLookingForDetailsDraft = (
    details: LookingForDetails,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails,
    employement: ProfessionalDetails,
    hobbies: Hobbies
  ) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const updatedDraftFormData = {
      show: flow === "create" ? "EVERYONE" : showStatus,
      status: status ? status : "DRAFT",
      thumbnailImage: false,
      hobbies: hobbies.hobbies,
      lookingFor: {
        religion: details.religionPreference,
        castePreference: details.castePreference,
        educationPreference: details.educationPreference,
        food: details.foodPreference,
        minHeight: details.minHeight,
        maxHeight: details.maxHeight,
        location: details.location,
      },
      foodHabit: familyDetails.foodhabit,
      familyDetails: {
        religion: familyDetails.religion,
        division: familyDetails.division,
        caste: familyDetails.caste,
        subCaste: familyDetails.subcaste,
        rashi: familyDetails.rashi,
        gothra: familyDetails.gothra,
        nakshathra: familyDetails.nakshatra,
        familyName: familyDetails.familyName,
      },
      profileFor: personalDetails.profileFor,
      member: {
        name: personalDetails.name,
        gender: personalDetails.gender,
        // dob: dayjs(personalDetails.dateOfbirth).add(330, "minutes").toDate(),
        dob: getDateAndTime(
          personalDetails?.dateOfbirth
            ?.toISOString()
            ?.split(".")[0]
            ?.split("T")[0]
        ),
        currentState: personalDetails.currentState,
        currentCity: personalDetails.currentCity,
        height: personalDetails.height,
      },
      contactDetails: {
        emailId: personalDetails.emailiddetails,
        phoneNumber: personalDetails.phonenodetails,
        contactSharingAllowed: personalDetails.isContactSharingAllowed,
      },
      educationDetails: {
        occupation: employement.occupation,
        educationDetails: employement.educationalDetails,
        minIncome: employement?.minIncome,
        maxIncome: employement?.maxIncome,
        employmentDetails: employement.employementDetails,
        occupationCountry: employement.occupationCountry,
      },
      maritalStatus: {
        status: personalDetails.maritalStatus,
        noOfChildren: personalDetails.noOfChildren,
      },
      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );
          if (resp?.status === 200) {
            localStorage.removeItem("flow");
            toast.success("Profile drafted!");
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );

          if (resp?.status === 200) {
            localStorage.removeItem("flow");
            toast.success("Profile drafted!");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }
    };
    onFinish();
  };

  const navigate = useNavigate();

  const handleContactDetailsSubmit = (details: ContactDetails) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");
    setContactDetails(details);
    const updatedFormDataToSend = {
      ...formDataToSend,

      show: details.show,
      status: "PUBLISH",
      thumbnailImage: details.showThumbnail,
      aboutMe: details.aboutMe,
      removeImages: details.removedImages,
      associateWith: details.associateWith ? "OPEN_TO_ALL" : "ONLY_COMMUNITY",
      communityIds: details.associateWith ? [] : details.communityIds,
    };
    setFormDataToSend(updatedFormDataToSend);

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        if (details.images) {
          details.images.forEach((file, index) => {
            imageFormData.append(`files`, new Blob([file]), file.name);
          });
        }

        if (details.pdfs) {
          details.pdfs.forEach((file, index) => {
            imageFormData.append(`pdf`, new Blob([file]), file.name);
          });
        }

        const blob = new Blob([JSON.stringify(updatedFormDataToSend)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );

          if (resp?.status === 200) {
            // navigate("/kyc");
            const name = resp?.data?.member?.name;
            const matrimonyId = resp?.data?.id;
            const profileUrl = resp?.data?.profileUrl;
            const app_uid = resp?.data?.userId;
            try {
              // Create user on Firestore
              const newUserRef = doc(db, "users", matrimonyId);
              await setDoc(newUserRef, {
                member: { name },
                matrimonyId,
                app_uid,
                profileUrl,
              });

              // Create empty user chats on Firestore
              await setDoc(doc(db, "recentChats", matrimonyId), {});

              // navigate("/");
            } catch (err) {
              console.error(err);
            }
            const CustomToast = () => (
              <div>
                Profile created!{" "}
                <span
                  onClick={() => {
                    navigate(`/matrimony/matrimony-profile/${resp?.data?.id}`);
                  }}
                  style={{ cursor: "pointer", color: "brown" }}
                >
                  Click here
                </span>{" "}
                to view
              </div>
            );
            toast.success(<CustomToast />);
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );
          if (resp?.status === 200) {
            if (
              resp?.data?.status === "PUBLISH" &&
              resp?.data?.status === contactDetailsInitial?.status
            ) {
              await updateDoc(doc(db, "users", resp?.data?.id), {
                [resp?.data?.id + ".member.name"]: resp?.data?.member?.name,
                [resp?.data?.id + ".profileUrl"]: resp?.data?.profileUrl,
              });
              const CustomToast = () => (
                <div>
                  Profile updated!{" "}
                  <span
                    onClick={() => {
                      navigate(`/matrimony/matrimony-profile/${resp?.id}`);
                    }}
                    style={{ cursor: "pointer", color: "brown" }}
                  >
                    Click here
                  </span>{" "}
                  to view
                </div>
              );
              toast.success(<CustomToast />);
              // navigate("/kyc");
              onClose();
              setCreatePopup(false);
            }

            if (
              resp?.data?.status === "PUBLISH" &&
              resp?.data?.status !== contactDetailsInitial?.status
            ) {
              const name = resp?.data?.member?.name;
              const matrimonyId = resp?.data?.id;
              const profileUrl = resp?.data?.profileUrl;
              const app_uid = resp?.data?.userId;
              try {
                const res = await getDoc(doc(db, "users", matrimonyId));
                // console.log(res.exists());
                // Create user on Firestore for chats
                if (!res.exists()) {
                  const newUserRef = doc(db, "users", matrimonyId);
                  await setDoc(newUserRef, {
                    member: { name },
                    app_uid,
                    matrimonyId,
                    profileUrl,
                  });

                  // Create empty user chats on Firestore
                  await setDoc(doc(db, "recentChats", matrimonyId), {});
                }

                // navigate("/");
              } catch (err) {
                console.error(err);
              }
              const CustomToast = () => (
                <div>
                  Profile updated!{" "}
                  <span
                    onClick={() => {
                      navigate(
                        `/matrimony/matrimony-profile/${resp?.data?.id}`
                      );
                    }}
                    style={{ cursor: "pointer", color: "brown" }}
                  >
                    Click here
                  </span>{" "}
                  to view
                </div>
              );
              toast.success(<CustomToast />);
              // navigate("/kyc");
              onClose();
              setCreatePopup(false);
            }
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }

      localStorage.removeItem("nname");
      localStorage.removeItem("gender");
      localStorage.removeItem("age");
      localStorage.removeItem("place");
      localStorage.removeItem("occupation");
      localStorage.removeItem("eduDetails");
      localStorage.removeItem("food");
      localStorage.removeItem("flow");
    };
    onFinish();

    setProgress("page7");
    onClose();
  };

  const handleContactDetailsDraft = (
    details: ContactDetails,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails,
    hobbies: Hobbies,
    professionalDetails: ProfessionalDetails,
    lookingFor: LookingForDetails
  ) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const updatedDraftFormData = {
      hobbies: hobbies.hobbies,
      foodHabit: familyDetails.foodhabit,
      familyDetails: {
        religion: familyDetails.religion,
        division: familyDetails.division,
        caste: familyDetails.caste,
        subCaste: familyDetails.subcaste,
        rashi: familyDetails.rashi,
        gothra: familyDetails.gothra,
        nakshathra: familyDetails.nakshatra,
        familyName: familyDetails.familyName,
      },
      profileFor: personalDetails.profileFor,
      member: {
        name: personalDetails.name,
        gender: personalDetails.gender,
        // dob: dayjs(personalDetails.dateOfbirth).add(330, "minutes").toDate(),
        dob: getDateAndTime(
          personalDetails?.dateOfbirth
            ?.toISOString()
            ?.split(".")[0]
            ?.split("T")[0]
        ),
        currentState: personalDetails.currentState,
        currentCity: personalDetails.currentCity,
        height: personalDetails.height,
      },
      contactDetails: {
        emailId: personalDetails.emailiddetails,
        phoneNumber: personalDetails.phonenodetails,
        contactSharingAllowed: personalDetails.isContactSharingAllowed,
      },
      maritalStatus: {
        status: personalDetails.maritalStatus,
        noOfChildren: personalDetails.noOfChildren,
      },
      educationDetails: {
        occupation: professionalDetails.occupation,
        educationDetails: professionalDetails.educationalDetails,
        minIncome: professionalDetails?.minIncome,
        maxIncome: professionalDetails?.maxIncome,
        employmentDetails: professionalDetails.employementDetails,
        occupationCountry: professionalDetails.occupationCountry,
      },
      lookingFor: {
        religion: lookingFor.religionPreference,
        castePreference: lookingFor.castePreference,
        educationPreference: lookingFor.educationPreference,
        food: lookingFor.foodPreference,
        minHeight: lookingFor.minHeight,
        maxHeight: lookingFor.maxHeight,
        location: lookingFor.location,
      },
      show: "EVERYONE",
      status: "DRAFT",
      thumbnailImage: false,
      aboutMe: details.aboutMe,
      removeImages: details.removedImages,
      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        if (details.images) {
          details.images.forEach((file, index) => {
            imageFormData.append(`files`, new Blob([file]), file.name);
          });
        }

        if (details.pdfs) {
          details.pdfs.forEach((file, index) => {
            imageFormData.append(`pdf`, new Blob([file]), file.name);
          });
        }

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );

          if (resp?.status === 200) {
            const CustomToast = () => (
              <div>
                Profile created!{" "}
                <span
                  onClick={() => {
                    navigate(`/matrimony/matrimony-profile/${resp?.data?.id}`);
                  }}
                  style={{ cursor: "pointer", color: "brown" }}
                >
                  Click here
                </span>{" "}
                to view
              </div>
            );
            toast.success(<CustomToast />);
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );

          if (resp?.status === 200) {
            toast.success("Profile created!");
            localStorage.removeItem("flow");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        console.error(error.response.data);
        toast.error(error.response.data?.message || "Something went Wrong");
      }

      localStorage.removeItem("nname");
      localStorage.removeItem("gender");
      localStorage.removeItem("age");
      localStorage.removeItem("place");
      localStorage.removeItem("occupation");
      localStorage.removeItem("eduDetails");
      localStorage.removeItem("food");
    };
    onFinish();
  };

  const handleHobbiesDetailsSubmit = (details: Hobbies) => {
    setHobbiesDetails(details);
    setShowProfessionalForm(true);
    const updatedFormDataToSend = {
      ...formDataToSend,
      hobbies: details.hobbies,
    };

    setProgress("page4");

    setFormDataToSend(updatedFormDataToSend);
    // setProgress(42);
  };

  const handleHobbiesDetailsDraft = (
    details: Hobbies,
    personalDetails: PersonalDetails,
    familyDetails: FamilyDetails
  ) => {
    const flow = localStorage.getItem("flow");
    const draft = localStorage.getItem("draftId");

    const updatedDraftFormData = {
      // ...draftFormDataToSend,
      show: flow === "create" ? "EVERYONE" : showStatus,
      status: status ? status : "DRAFT",

      hobbies: details.hobbies,
      foodHabit: familyDetails.foodhabit,
      familyDetails: {
        religion: familyDetails.religion,
        division: familyDetails.division,
        caste: familyDetails.caste,
        subCaste: familyDetails.subcaste,
        rashi: familyDetails.rashi,
        gothra: familyDetails.gothra,
        nakshathra: familyDetails.nakshatra,
        familyName: familyDetails.familyName,
      },
      profileFor: personalDetails.profileFor,
      member: {
        name: personalDetails.name,
        gender: personalDetails.gender,
        // dob: dayjs(personalDetails.dateOfbirth).add(330, "minutes").toDate(),
        dob: getDateAndTime(
          personalDetails?.dateOfbirth
            ?.toISOString()
            ?.split(".")[0]
            ?.split("T")[0]
        ),
        currentState: personalDetails.currentState,
        currentCity: personalDetails.currentCity,
        height: personalDetails.height,
      },
      contactDetails: {
        emailId: personalDetails.emailiddetails,
        phoneNumber: personalDetails.phonenodetails,
        contactSharingAllowed: personalDetails.isContactSharingAllowed,
      },
      maritalStatus: {
        status: personalDetails.maritalStatus,
        noOfChildren: personalDetails.noOfChildren,
      },
      associateWith: localStorage?.getItem("associateWith") || "OPEN_TO_ALL",
      communityIds: localStorage?.getItem("communityIds") || [],
    };

    const onFinish = async () => {
      try {
        let imageFormData = new FormData();

        const blob = new Blob([JSON.stringify(updatedDraftFormData)], {
          type: "application/json",
        });

        imageFormData.append("data", blob);

        if (flow === "create") {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/create`,
            imageFormData,
            "post"
          );

          if (resp?.status === 200) {
            localStorage.removeItem("flow");
            toast.success("Profile drafted!");
            onClose();
            setCreatePopup(false);
          }
        } else {
          const resp = await multiFormRequest(
            `/member/current/matrimony-profile/update/${draft}`,
            imageFormData,
            "put"
          );

          if (resp?.status === 200) {
            localStorage.removeItem("flow");
            toast.success("Profile drafted!");
            onClose();
            setCreatePopup(false);
          }
        }
      } catch (error: any) {
        toast.error(error.response.data?.message || "Something went Wrong");
      }
    };
    onFinish();
  };

  const pageClickHandler = (page: string) => {
    switch (page) {
      case "1":
        setProgress("page1");
        setShowFamilyForm(false);
        setshowHobbiesForm(false);
        setShowProfessionalForm(false);
        setshowMaritalDetailsForm(false);
        setshowContactDetailsForm(false);
        break;
      case "2":
        setProgress("page2");
        setShowFamilyForm(true);
        setshowHobbiesForm(false);
        setShowProfessionalForm(false);
        setshowMaritalDetailsForm(false);
        setshowContactDetailsForm(false);
        break;
      case "3":
        setProgress("page3");
        setShowFamilyForm(true);
        setshowHobbiesForm(true);
        setShowProfessionalForm(false);
        setshowMaritalDetailsForm(false);
        setshowContactDetailsForm(false);
        break;
      case "4":
        setProgress("page4");
        setShowFamilyForm(true);
        setshowHobbiesForm(true);
        setShowProfessionalForm(true);
        setshowMaritalDetailsForm(false);
        setshowContactDetailsForm(false);
        break;
      case "5":
        setProgress("page5");
        setShowFamilyForm(true);
        setshowHobbiesForm(true);
        setShowProfessionalForm(true);
        setshowMaritalDetailsForm(true);
        setshowContactDetailsForm(false);
        break;
      case "6":
        setProgress("page6");
        setShowFamilyForm(true);
        setshowHobbiesForm(true);
        setShowProfessionalForm(true);
        setshowMaritalDetailsForm(true);
        setshowContactDetailsForm(true);
        break;
      case "7":
        setProgress("page7");

        break;
      default:
        console.log("def");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {loader && !personalFormInitial ? (
        <div className="loader">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <div className="formwrapper">
          {/* <Progress page={progress} /> */}
          <div className="">
            <div
              className="m-2 p-2 cursor-pointer backIcon"
              onClick={() => {
                localStorage.removeItem("flow");
                localStorage.removeItem("nname");
                localStorage.removeItem("place");
                localStorage.removeItem("food");
                localStorage.removeItem("eduDetails");
                localStorage.removeItem("gender");
                localStorage.removeItem("age");
                localStorage.removeItem("occupation");
                localStorage.removeItem("draftId");
                onClose();
              }}
            >
              <Tooltip
                title="Changes may not be saved. Save draft to continue later"
                autoAdjustOverflow
                placement="right"
              >
                <img src={back} alt="" className="w-10" />
              </Tooltip>
            </div>
          </div>
          <MultiStepProgressBar
            page={progress}
            onPageClickHandler={pageClickHandler}
          />
          {!showFamilyForm ? (
            <PersonalDetailsForm
              onSubmit={handlePersonalDetailsSubmit}
              onDraft={handlePersonalDetailsDraft}
              initialValues={personalFormInitial}
              completeInitialValues={initialVals}
            />
          ) : !showHobbiesrForm ? (
            <FamilyDetailsForm
              onSubmit={handleFamilyDetailsSubmit}
              onDraft={handleFamilyDetailsDraft}
              personalDetails={personalDetails!}
              initialValues={familyFormInitial}
            />
          ) : !showProfessionalForm ? (
            <HobbiesDetailsForm
              onSubmit={handleHobbiesDetailsSubmit}
              onDraft={handleHobbiesDetailsDraft}
              personalDetails={personalDetails!}
              familyDetails={familyDetails!}
              initialValues={hobbiesInitial}
            />
          ) : !showMaritalDetailsForm ? (
            <ProfessionalDetailsForm
              onSubmit={handleProfessionalDetailsSubmit}
              onDraft={handleProfessionalDetailsDraft}
              personalDetails={personalDetails!}
              familyDetails={familyDetails!}
              hobbies={hobbiesDetails!}
              initialValues={professionalInitial}
            />
          ) : // : !showLookingForForm ? (
          //   <MaritalStatusDetailsForm />
          // )
          !showContactDetailsForm ? (
            <LookingForDetailsForm
              onSubmit={handleLookingForDetailsSubmit}
              onDraft={handleLookingForDetailsDraft}
              personalDetails={personalDetails!}
              familyDetails={familyDetails!}
              hobbies={hobbiesDetails!}
              professionalDetails={professionalDetails!}
              initialValues={lookingForDetailsInitial}
            />
          ) : (
            <ContactDetailsForm
              onSubmit={handleContactDetailsSubmit}
              onDraft={handleContactDetailsDraft}
              personalDetails={personalDetails!}
              familyDetails={familyDetails!}
              hobbies={hobbiesDetails!}
              professionalDetails={professionalDetails!}
              lookingFor={lookingForDetails!}
              initialValues={contactDetailsInitial}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
