import React from "react";
import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

type Props = {
  matrimonyID: any;
};

const PassportKyc = (props: Props) => {
  const [form] = Form.useForm();
  const currentDate = dayjs();
  const navigate = useNavigate();
  const matrimonyID = props?.matrimonyID;
  const disabledDate = (current: any) => {
    return current && current.year() >= 2006;
  };

  const onFinish = async (values: any) => {
    try {
      const {
        passportNo,
        fullname,
        surname,
        countryCode,
        dob,
        passportType,
        termsCondition,
      } = values;
      if (!termsCondition) {
        form.setFields([
          {
            name: "termsCondition",
            errors: ["Please accept the terms and conditions!"],
          },
        ]);
        return;
      } else {
        toast.error("KYC request failed to send to admin approval!");
        console.log("PassportKYC Values===>", values);
        navigate(-1)
      }
    } catch (error: any) {
      toast.error("KYC request failed to send to admin approval!");
      console.log("PassportKYC Error===>", error);
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="kycForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="kycFormItem"
      >
        <Form.Item
          className="formitem"
          label="Passport No"
          name="passportNo"
          rules={[
            {
              required: true,
              message: "Please enter your passport number!",
            },
            {
              validator(_, value) {
                const panRegex = /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/;
                if (panRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter valid passport!")
                );
              },
            },
          ]}
        >
          <Input className="h-11" maxLength={8} />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Full Name"
          name="fullname"
          rules={[{ required: true, message: "Please input your fullname!" }]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Surname"
          name="surname"
          rules={[{ required: true, message: "Please input your surname!" }]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Coutry Code"
          name="countryCode"
          rules={[{ required: true, message: "Please input your surname!" }]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Date of Birth"
          name="dob"
          rules={[
            {
              required: true,
              message: "Please select your date of birth!",
            },
          ]}
        >
          <DatePicker
            className="h-11"
            format={"DD-MM-YYYY"}
            defaultPickerValue={currentDate.subtract(18, "year")}
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Passport Type"
          name="passportType"
          rules={[
            {
              required: true,
              message: "Please select your passport type!",
            },
          ]}
        >
          <Select
            // onChange={(e) => setType(e)}
            className="h-11"
          >
            <Select.Option className="selectOptions" value="personal">
              Personal
            </Select.Option>
            <Select.Option className="selectOptions" value="service">
              Service
            </Select.Option>
            <Select.Option className="selectOptions" value="diplomatic">
              Diplomatic
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Date of Expiry"
          name="expiryDate"
          rules={[
            {
              required: true,
              message: "Please select your passport expiry date!",
            },
          ]}
        >
          <DatePicker
            className="h-11"
            format={"DD-MM-YYYY"}
            defaultPickerValue={currentDate}
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
          />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="MRZ 1"
          name="mrz1"
          rules={[
            {
              required: true,
              message: "Please enter your MRZ-1!",
            },
            {
              validator(_, value) {
                const panRegex = /^[A-Z0-9<]{2}[A-Z<]{3}[A-Z0-9<]{39}$^/;
                if (panRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please enter valid MRZ-1!"));
              },
            },
          ]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="MRZ 2"
          name="mrz2"
          rules={[
            {
              required: true,
              message: "Please enter your MRZ-2!",
            },
            {
              validator(_, value) {
                const panRegex =
                  /^[A-Z0-9<]{9}[0-9]{1}[A-Z<]{3}[0-9]{6}[0-9]{1}[FM<]{1}[0-9]{6}[0-9]{1}[A-Z0-9<]{14}[0-9]{1}[0-9]{1}$^/;
                if (panRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please enter valid MRZ-2!"));
              },
            },
          ]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          name="termsCondition"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions!",
            },
          ]}
        >
          <Checkbox>
            <span className="text-[20px]">Terms & Conditions</span>
            <span style={{ color: "#ff4d4f" }}>*</span>
          </Checkbox>
        </Form.Item>

        <div className="Save-btn">
          <Form.Item
            wrapperCol={{ offset: 8, span: 24 }}
            className="flex justify-end gap-3 buttonsDiv"
          >
            <Button
              htmlType="submit"
              className="createButton"
              type="primary"
              style={{ marginTop: "-1px", height: "45px" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PassportKyc;
