import React, { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import feedsinactive from "../assets/newUiImages/feedsinactive.svg";
import kyc from "../assets/newUiImages/kyc.svg";
import { useNavigate } from "react-router-dom";
import home from "../assets/newUiImages/home.svg";
import homeactive from "../assets/newUiImages/homeactive.svg";
import matrimonyactive from "../assets/newUiImages/matrimonyactive.svg";
import newsactive from "../assets/newUiImages/newsactive.svg";
import communityactive from "../assets/newUiImages/communityactive.svg";
import searcIconActive from "../assets/newUiImages/Findroot2.svg";
import searcIconInactive from "../assets/newUiImages/FindRoute.svg";
import chat from "../assets/newUiImages/chat.svg";
import chatactive from "../assets/newUiImages/chat green.svg";
import community from "../assets/newUiImages/community.svg";
import matrimony from "../assets/newUiImages/matrimony.svg";
import news from "../assets/newUiImages/news.svg";
import navlogo from "../assets/newUiImages/navlogo.svg";
import { request, clearCache } from "../Utils/request";
import msgBrown from "../assets/newUiImages/msgBrown.svg";
import msgGreen from "../assets/newUiImages/msgGreen.svg";
import logout from "../assets/newUiImages/logout.svg";
import FooterMobile from "../Components/Footer/FooterMobile";
import bell from "../assets/newUiImages/bell.svg";
import bellActive from "../assets/newUiImages/bellActive.svg";
import { isMobile } from "../Utils/mobileSettings";
import KYC from "../assets/newUiImages/kycActive.svg";
import feedsGreen from "../assets/newUiImages/feeds green.svg";
import { signOut } from "firebase/auth";
import { isIOS } from "react-device-detect";
import { toast } from "react-toastify";

const Home: React.FC<{
  isLeftNavVisible: boolean;
  toggleLeftNav: () => void;
}> = ({ isLeftNavVisible, toggleLeftNav }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Feeds");
  const [userInfo, setUserInfo] = useState<any>({});
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);
  const [profileUrl, setProfileUrl] = useState<string>("");

  const divRef = useRef<HTMLDivElement>(null);
  const leftnavref = useRef<HTMLDivElement>(null);
  const requestRef = useRef<HTMLDivElement>(null);
  const sentRef = useRef<HTMLDivElement>(null);

  const currentRole = localStorage.getItem("role");
  const accessToken = localStorage.getItem("token");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        // Click occurred outside the div
      }
    };
    const handleClickRequestPopupOutside = (event: MouseEvent) => {
      if (
        requestRef.current &&
        !requestRef.current.contains(event.target as Node)
      ) {
      }
    };

    const handleClickSentpopupOutside = (event: MouseEvent) => {
      if (sentRef.current && !sentRef.current.contains(event.target as Node)) {
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("click", handleClickRequestPopupOutside);
    window.addEventListener("click", handleClickSentpopupOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("click", handleClickRequestPopupOutside);
      window.removeEventListener("click", handleClickSentpopupOutside);
    };
  }, []);
  const closeLeftNav = () => {
    if (isLeftNavVisible === true) {
      toggleLeftNav(); // Close the left nav if it's open
    }
  };
  const fetchDeviceToken = async () => {
    try {
        const deviceToken =  localStorage.getItem("deviceToken");
        const deviceOsVersion =  localStorage.getItem("deviceOsVersion") || "0";
        const deviceType = localStorage.getItem("deviceType")
        const payload = {
          method: "POST",
          body: JSON.stringify({
            deviceToken: deviceToken,
            deviceType:deviceType ,
            deviceOsVersion: parseInt(deviceOsVersion),
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        };
        // toast.success("TOKEN is "+deviceToken+" version "+deviceOsVersion+" type "+deviceType)
        request(`/member/current/device-token/delete`, payload).then(resp => {
          // Temporarily store the device values
      const tempStorage = {
        deviceToken: deviceToken,
        deviceOsVersion: deviceOsVersion,
        deviceType: deviceType
      };

      // Clear local storage
          localStorage.clear();

           // Restore the device values
      localStorage.setItem("deviceToken", tempStorage.deviceToken || "");
      localStorage.setItem("deviceOsVersion", tempStorage.deviceOsVersion);
      localStorage.setItem("deviceType", tempStorage.deviceType || "");

          clearCache();
          // toast.success("RESPONSE: " + isIOS + JSON.stringify(resp));
    navigate('/login');

        })

    } catch (error: any) {
      console.error("Error fetching device token: " + error.message);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserInfo(JSON.parse(user || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const onLogClick = () => {
    const data = document.getElementById("logId")?.innerText;
    if (data === "Logout") {
      signOut(auth)
        .then(() => {
          // localStorage.clear();
          // clearCache();
          const deviceToken = localStorage.getItem("deviceToken")
          const deviceType = localStorage.getItem("deviceType")
          if (deviceToken?.length && (deviceType === "ANDROID" || deviceType === "IOS"))
          // if (typeof window?.AndroidJSBridge?.getDeviceToken === "function" || isIOS)
             {
            fetchDeviceToken()
          }else{
            localStorage.clear();
          clearCache();
    navigate('/login');

          }
        })
        .catch((error) => {
          console.error("ERROR", error);
        });
    }
    // window.location.pathname = "/login";
  };

  const adminLeftOpt = [
    {
      name: "KYC",
      imageLink: `${kyc}`,
      selectedImageLink: `${KYC}`,
      linkTo: "admin",
    },
    {
      name: "Community Settings",
      imageLink: `${community}`,
      selectedImageLink: `${communityactive}`,
      linkTo: "/adminSettings",
    },
    // {
    //   name: "User Settings",
    //   imageLink: `${community}`,
    //   selectedImageLink: `${communityactive}`,
    //   linkTo: "/adminUserSettings",
    // },
  ];

  const mobileLeftOpt = [
    {
      name: "Feeds",
      imageLink: `${feedsinactive}`,
      selectedImageLink: `${feedsGreen}`,
      linkTo: "/feeds",
    },
    {
      name: "Community",
      imageLink: `${community}`,
      selectedImageLink: `${communityactive}`,
      linkTo: "/community",
    },
    {
      name: "Find your roots",
      imageLink: `${searcIconInactive}`,
      selectedImageLink: `${searcIconActive}`,
      linkTo: "/rootSearch",
    },
    {
      name: "Matrimony",
      imageLink: `${matrimony}`,
      selectedImageLink: `${matrimonyactive}`,
      linkTo: "/matrimony",
    },
    {
      name: "News",
      imageLink: `${news}`,
      selectedImageLink: `${newsactive}`,
      linkTo: "/news",
    },
    {
      name: "Message",
      imageLink: `${msgBrown}`,
      selectedImageLink: `${msgGreen}`,
      linkTo: "/requests",
    },
    {
      name: "Notifications",
      imageLink: `${bell}`,
      selectedImageLink: `${bellActive}`,
      linkTo: "/notification",
    },

    {
      name: "Matrimony talks",
      imageLink: `${chat}`,
      selectedImageLink: `${chatactive}`,
      linkTo: "/chat",
    },
  ];

  const webLeftOpt = [
    {
      name: "Home",
      imageLink: `${home}`,
      selectedImageLink: `${homeactive}`,
      linkTo: "/",
    },
    {
      name: "Feeds",
      imageLink: `${feedsinactive}`,
      selectedImageLink: `${feedsGreen}`,
      linkTo: "/feeds",
    },
    {
      name: "Community",
      imageLink: `${community}`,
      selectedImageLink: `${communityactive}`,
      linkTo: "/community",
    },
    {
      name: "Find your roots",
      imageLink: `${searcIconInactive}`,
      selectedImageLink: `${searcIconActive}`,
      linkTo: "/rootSearch",
    },
    {
      name: "Matrimony",
      imageLink: `${matrimony}`,
      selectedImageLink: `${matrimonyactive}`,
      linkTo: "/matrimony",
    },
    {
      name: "News",
      imageLink: `${news}`,
      selectedImageLink: `${newsactive}`,
      linkTo: "/news",
    },
    {
      name: "Matrimony talks",
      imageLink: `${chat}`,
      selectedImageLink: `${chatactive}`,
      linkTo: "/chat",
    },
  ];
  var leftMenuOptions: any = [];
  if (currentRole === "ADMIN") {
    leftMenuOptions = adminLeftOpt;
  } else {
    if (!isMobile) {
      leftMenuOptions = webLeftOpt;
    } else if (isMobile) {
      leftMenuOptions = mobileLeftOpt;
    }
  }

  const handlehover = (optionName: string) => {
    setHoveredOption(optionName);
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    const profileUrl = localStorage.getItem("profileUrl");
    if (user) {
      setUserInfo(JSON.parse(user || ""));
      setProfileUrl(profileUrl || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    const path = window.location.pathname;
    const option =
      leftMenuOptions.find(
        (item: any) =>
          item?.linkTo === path || path?.includes(item.name.toLocaleLowerCase())
      )?.name || "Home";
    setSelectedOption(option);
  }, [window.location.pathname]);

  const getOauthState = () => {
    const status = localStorage.getItem("status");
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        localStorage.setItem("currentPath", location.pathname);
        if (status === "401") {
          let token = await user.getIdToken(true);
          localStorage.setItem("token", token);
          const meApiResp = await request("/member/current/me");

          if (meApiResp?.id) {
            localStorage.setItem("id", meApiResp?.id);
            localStorage.setItem("status", "200");
            const path: any = localStorage.getItem("currentPath");
            navigate(path);
            window.location.reload();
          } else {
            console.log("something went wrong");
          }
        }
      } else {
        console.log("user not exist in firebase");
      }
    });
  };

  useEffect(() => {
    getOauthState();
  }, []);
  return (
    <div
      className={`rounded-lg shadow-lg bg-white flex flex-col items-center justify-center leftNavAndHeaderWrapper ${
        isLeftNavVisible ? "leftNavVisible" : "leftNavHide"
      }`}
      onClick={toggleLeftNav}
    >
      <nav className="navbar" ref={leftnavref}>
      {currentRole === "ADMIN" ?
      
      <div className="navimg showlogodesktop">
        <img src={navlogo} alt="" />
      </div>
      :
        <Link to={`${process.env.REACT_APP_NAVIGATION_URL}`}>
          <div className="navimg showlogodesktop">
            <img src={navlogo} alt="" />
          </div>
        </Link>

      }

        <div className="showlogomobile">
          {accessToken ? (
            <Link to={`/profile/${localStorage.getItem("id")}`}>
              {profileUrl !== "null" && profileUrl !== "" ? (
                <div
                  ref={divRef}
                  className=" profile capitalize relative cursor-pointer flex items-center justify-center showlogomobile"
                  onClick={() => {
                    navigate(`/profile/${localStorage.getItem("id")}`);
                  }}
                >
                  <img
                    src={profileUrl}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />

                  <div className="namediv">
                    <span className="cursor-default">
                      {localStorage?.getItem("name") ||
                        userInfo?.email?.substring(
                          0,
                          userInfo?.email?.indexOf("@")
                        ) ||
                        userInfo?.displayName}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  ref={divRef}
                  style={{
                    backgroundColor:
                      localStorage.getItem("profileColorCode") || "#fde3cf",
                  }}
                  className=" profile capitalize relative cursor-pointer bg-[#fde3cf] text-white w-10 h-10 p-1 rounded-full  flex items-center justify-center "
                  onClick={() => {
                    closeLeftNav();
                  }}
                >
                  <span className="substring">
                    {" "}
                    {localStorage?.getItem("name")?.substring(0, 1) ||
                      userInfo?.displayName?.substring(0, 1) ||
                      userInfo?.email?.substring(0, 1)}
                  </span>

                  <div className="namediv">
                    <span className="cursor-default text-lg">
                      {localStorage?.getItem("name") ||
                        userInfo?.email?.substring(
                          0,
                          userInfo?.email?.indexOf("@")
                        ) ||
                        userInfo?.displayName}
                    </span>
                  </div>
                </div>
              )}
            </Link>
          ) : (
            <></>
          )}
        </div>
        {accessToken ? <div className="hrline"></div> : <></>}
        <div className="listnavbar">
          {leftMenuOptions?.map((option: any, index: any) => (
            <div
              className={`text-base font-medium navoptions ${
                option.name === selectedOption ? "selected" : ""
              }`}
              onMouseEnter={() => handlehover(option.name)} // Pass the option name on hover
              onMouseLeave={() => handlehover("")} // Reset the hovered option on mouse leave
              onClick={() => {
                setSelectedOption(option.name);
                navigate(option.linkTo);
              }}
              style={{ cursor: "pointer" }}
              key={index}
            >
              <div>
                <img
                  alt="Img"
                  style={{ width: currentRole === "ADMIN" ? "50px" : "" }}
                  src={
                    option.name === hoveredOption ||
                    option.name === selectedOption
                      ? option.selectedImageLink
                      : option.imageLink
                  }
                />
                <p>{option.name}</p>
              </div>
            </div>
          ))}
          {accessToken ? (
            <div
              className="showlogomobile setalignlogout"
              style={{ marginTop: "20px" ,width:"fit-content"}}
              onClick={() => {
                onLogClick();
                closeLeftNav();
              }}
            >
              <img src={logout} alt="" className=" logoutimg" />
              <div
                id="logId"
                // onClick={() => {
                //   onLogClick();
                //   closeLeftNav();
                // }}
                // to={"/login"}
                className=" border border-blue-400 rounded-xl text-center text-xs "
              >
                Logout
              </div>
            </div>
          ) : (
            <>
              {window.location.pathname !== "/login" &&
              window.location.pathname !== "/signUp" ? (
                <ul className="linksDiv">
                  <Link
                    id="logId"
                    onClick={onLogClick}
                    to={"/login"}
                    className="Logintxt"
                  >
                    {accessToken?.length ? "Logout" : "Login"}
                  </Link>
                  <span>|</span>
                  <li>
                    <Link to={"/signUp"} className="signuptxt">
                      Sign up
                    </Link>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </>
          )}
          <div></div>
        </div>
      </nav>

      <div className="showfootermobile">
        <FooterMobile />
      </div>
    </div>
  );
};

export default Home;
