import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap-icons/font/bootstrap-icons.css";
import { store } from "./store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChatContextProvider } from "./Context/ChatContext";
import { UserMatrimonyContextProvider } from "./Context/UserMatrimonyContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="1001237783919-28c8i0q2256tttr4de9fqcmqj28cj15n.apps.googleusercontent.com">
        <UserMatrimonyContextProvider>
          <ChatContextProvider>
            <App />
          </ChatContextProvider>
        </UserMatrimonyContextProvider>
      </GoogleOAuthProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
