import React from "react";
import { Link } from "react-router-dom";
import "./FooterMobile.css";

interface FooterMobileProps {
  label: string;
  linkTo?: string;
  id: string;
}

const FooterMobile = () => {
  const FooterLinks: FooterMobileProps[] = [
    {
      label: "About Us",
      linkTo: process.env.REACT_APP_NAVIGATION_URL + "aboutUs",
      id: "aboutUs",
    },
    {
      label: "Privacy Policy",
      linkTo: process.env.REACT_APP_NAVIGATION_URL + "privacyPolicy",
      id: "privacyPolicy",
    },
  ];

  const FooterLinksSecond: FooterMobileProps[] = [
    {
      label: "Terms of Use",
      linkTo: process.env.REACT_APP_NAVIGATION_URL + "termsOfUse",
      id: "termsOfUse",
    },
    {
      label: "Contact Us",
      linkTo: process.env.REACT_APP_NAVIGATION_URL + "ContactUs",
      id: "ContactUs",
    },
  ];
  return (
    <div className="footer">
      <div className="footerRow">
        <div className="footerCol">
          <div className="footerUl">
            <ul className="">
              {FooterLinks?.map((footerItem, index) => (
                <li key={index}>
                  <Link className="" to={`${footerItem?.linkTo}`}>
                    {footerItem?.label}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="">
              {FooterLinksSecond.map((footerItem, index) => (
                <li key={index}>
                  <Link className="" to={`${footerItem?.linkTo}`}>
                    {footerItem?.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footerCol copyrightWrap">
          <div className="copyright">
            <p className="">Copyright &#169; 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
