import React from "react";
import "./Header.css";
import { useNavigate } from "react-router";

type SearchResult = {
  categoryName: string;
  categoryId: string;
};

type SearchResultsProps = {
  results: SearchResult[];
  queryText: string;
  setShowSearchResults: Function;
  setSelectedCategoryId: Function;
  clearSearchText: Function;
  handleTriggerSearch: Function;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  setShowSearchResults,
  clearSearchText,
  queryText,
  setSelectedCategoryId,
  handleTriggerSearch,
}) => {
  const navigate = useNavigate();
  console.log(results);
  return (
    <div className="search-results">
      <ul>
        {results?.length > 0 ? (
          results?.map((result, index) => (
            <li
              key={index}
              className="cursor-pointer searchResultItem"
              onClick={() => {
                // navigate(`/communityPreview/${result?.categoryId}`);
                console.log("search result clicked");

                setSelectedCategoryId(result?.categoryId);
                setShowSearchResults(false);
                handleTriggerSearch();
                // clearSearchText();
              }}
            >
              {queryText}
              <span className="text-gray-500">
                {" in "}
                {result?.categoryName}
              </span>
            </li>
          ))
        ) : (
          <li
            className="py-1 text-lg text-slate-500"
            onClick={() => {
              setShowSearchResults(false);
              clearSearchText();
            }}
          >
            No result
          </li>
        )}
      </ul>
    </div>
  );
};

export default SearchResults;
