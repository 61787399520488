import React, {ReactNode, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import loader from '../assets/loader-new.gif';

type Props = {
  children?: ReactNode;
onRefresh:() => void
};

const PullToRefreshComponent: React.FC<Props> = (props) => {
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);

  const handleRefresh = async (): Promise<void> => {
    setToggleRefresh(!toggleRefresh);
    props?.onRefresh()
  };

  return (
    <PullToRefresh
      onRefresh={handleRefresh}
      refreshingContent={
        <div className="flex flex-row items-center justify-center">
          <img
            src={loader}
            style={{ width: '80px', height: '80px' }}
            alt=""
            className="mt-5"
          />
        </div>
      }
      pullingContent={<></>}
    >
      <>{props?.children}</>
    </PullToRefresh>
  );
};

export default PullToRefreshComponent;
