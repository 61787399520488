import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { Modal } from "antd";
import * as Survey from "survey-react";
import { Button, Form, Input, Select, Switch, ConfigProvider } from "antd";
import TextArea from "antd/es/input/TextArea";
import Loader from "../Loader/Loader";
import "./EditCommunityPopup.css";
import religionForm from "../Commuinty/religionForm.json";
import { isMobile } from "../../Utils/mobileSettings";
import { toast } from "react-toastify";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { getToken } from "../../Utils/timeago";
import { multiFormRequest } from "../../Utils/request";

interface createCommunityPopupProps {
  onCancelClick: Function;
  communityCategory: any[];
  onSaveClick: Function;
  id: string;
  communityData: any;
  religionFormData:any;
}

const EditCommunityPopup = (props: createCommunityPopupProps) => {
  const [selectedcategoryid, setselectedcategoryid] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [name, setName] = useState("");
  const [values, setValues] = useState({});
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [imageName, setImageName] = useState<string>("");
  const [bannerImageName, setBannerImageName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [postByAdmin, setPostByAdmin] = useState(false);
  const hiddenBannerFileInput = useRef<HTMLInputElement>(null);
  const [formDataObject, setFormDataObject] = useState();
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();




  useEffect(() => {
    setImageName(props?.communityData?.communityProfileUrl);
    setBannerImageName(props?.communityData?.communityBannerUrl);
    try {
      props?.communityData?.surveyJSFormData?.communityData &&
      setFormDataObject(
        JSON.parse(props?.communityData?.surveyJSFormData?.communityData)
      );
    } catch(error) {
      setFormDataObject(props?.communityData?.surveyJSFormData?.communityData)
    }
    
  }, []);


  const categoriesOptions = props?.communityCategory?.map((category: any) => (
    <Select.Option
      className="selectOptions"
      key={category?.id}
      value={category?.id}
    >
      {category.name}
    </Select.Option>
  ));

  const handleselectchange = (value: any) => {
    setselectedcategoryid(value);
  };

  const handleBannerImageClick = () => {
    hiddenBannerFileInput?.current?.click();
  };
  const HandleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const completeSurvey = (survey: any) => {
    let sur = JSON.stringify(survey.valuesHash);

    let imageFormData = new FormData();

    if (selectedFiles) {
      selectedFiles.forEach((item) => {
        imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
      });
    }

    const id = localStorage.getItem("id");
    if (id) {
      const blob = new Blob(
        [
          JSON.stringify({
            name: name || props?.communityData?.name,
            description: description || props?.communityData?.description,
            type: type || props?.communityData.type,
            adminId: id,
            categoryId: selectedcategoryid,
            surveyJSFormData: {
              communityData: sur,
            },
            postsOnlyByAdmin: postByAdmin || props?.communityData?.postsOnlyByAdmin,
          }),
        ],
        {
          type: "application/json",
        }
      );
      imageFormData.append("data", blob);
    }

    setLoader(true);

    postSurveyData(imageFormData);
  };

  const postSurveyData = async (imageFormData: any) => {
    try {
      const response = await multiFormRequest(
        `/member/current/update/community/${props?.communityData.id}`,
        imageFormData,
        "put"
      );
      if (response) {
        props.onCancelClick();
        props?.onSaveClick();
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };

  const onFinish = async (values: any) => {
    const button = document.getElementById("submitButton")?.innerText;
    let imageFormData = new FormData();
    const nameAbusiveWord = await CheckBadWordsExist(values?.CommunityName);
    const descriptionAbusiveWord = await CheckBadWordsExist(
      values?.description
    );

    if (nameAbusiveWord?.status && descriptionAbusiveWord?.status) {
      if (
        !nameAbusiveWord?.profanity &&
        !descriptionAbusiveWord?.profanity &&
        !descriptionAbusiveWord?.words?.length &&
        !nameAbusiveWord?.words?.length
      ) {
        if (button === "Next") {
          handleReligionForm(values);
        } else {
          if (selectedFiles) {
            selectedFiles.forEach((item) => {
              imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
            });
          }
          const id = localStorage.getItem("id");
          if (id) {
            const blob = new Blob(
              [
                JSON.stringify({
                  name: values?.CommunityName,

                  description: values?.description,
                  type: values?.type,
                  postsOnlyByAdmin: values?.postsOnlyByAdmin,
                  //   type: values.type,

                  //   adminId: id,
                  //   categoryId: selectedcategoryid,
                }),
              ],
              {
                type: "application/json",
              }
            );
            imageFormData.append("data", blob);
          }

          setLoader(true);
          try {
            const response = await multiFormRequest(
              `/member/current/update/community/${props?.id}`,
              imageFormData,
              "put"
            );
            if (response) {
              // toast.success("Community Details Updated successfully");
              props.onCancelClick();
              props?.onSaveClick();
              setLoader(false);
            }
          } catch (error) {
            console.error("Error:", error);
            toast.error("Community Details Update failed");
            setLoader(false);
          }
        }
      } else if (
        nameAbusiveWord?.profanity &&
        descriptionAbusiveWord?.profanity
      ) {
        setLoader(false);
        toast.error("Not allowed to write abusive content");
      } else {
        setLoader(false);
        if (nameAbusiveWord?.profanity) {
          toast.error("Not allowed to write abusive content in community name");
        } else if (descriptionAbusiveWord?.profanity) {
          toast.error("Not allowed to write abusive content in description");
        }
      }
    } else {
      setLoader(false);
      toast.error(
        `${
          nameAbusiveWord.status === false
            ? nameAbusiveWord?.message
            : descriptionAbusiveWord?.message || "Something went wrong"
        }`
      );
    }
  };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = Array.from(event.target.files ?? []);

    if (files && files[0]) {
      // Update the profile image state if it's a profile image
      if (type === "profileImage") {
        setImageName(URL.createObjectURL(files[0]));
      }

      // Update the banner image state if it's a banner image
      if (type === "bannerImage") {
        setBannerImageName(URL.createObjectURL(files[0]));
      }

      // Replace the existing image data in selectedFiles or add a new image data
      const updatedFiles = selectedFiles.filter(
        ([fileType]) => fileType !== type
      );
      updatedFiles.push([type, files[0]]);
      setSelectedFiles(updatedFiles);
    }
  };

  const onBannerImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = Array.from(event.target.files ?? []);

    setSelectedFiles([...selectedFiles, [type, files[0]]]);

    if (event.target.files && event.target.files[0]) {
      setBannerImageName(event?.target?.files[0]?.name);
    }
  };


  const handleReligionForm = (values: any) => {
    let obj = form.getFieldsValue();
    setDescription(values?.description);
    setName(values?.CommunityName);
    setType(values?.type);

    setShowSurvey(true);

    setValues(obj);
  };


  return (
    <div className="communityPopup" id="community">
      <Modal
        title={<div className="popupTitle">Edit Community Details</div>}
        // centered
        style={{ top: "5px" }}
        open
        onOk={() => {}}
        onCancel={() => {
          props.onCancelClick();
        }}
        footer
        className="modalPopup"
      >
        <div className="relative">
          {loader && (
            <div className="loaderWrapper">
              <Loader />
            </div>
          )}
          {showSurvey ? (
            <Survey.Survey
              json={props?.religionFormData}
              data={formDataObject}
              onComplete={completeSurvey}
              showCompletedpage={false}
            />
          ) : (
            <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#7d8742",
                borderRadius: 10,
                colorPrimaryBorder: "#7d8742",
              },
            }}
          >
            <Form
              className="communityForm"
              // onChange={formValidation}
              initialValues={{
                CommunityName: props?.communityData?.name,
                profileImage: props?.communityData?.communityProfileUrl,
                bannerImage: props?.communityData?.communityBannerUrl,
                description: props?.communityData?.description,
                type: props?.communityData?.type,
                communityCategory: props?.communityData?.categoryName,
                postsOnlyByAdmin: props?.communityData?.postsOnlyByAdmin 
              }}
              onFinish={(values) => {
                onFinish(values);
              }}
              name="createForm"
            >
              <Form.Item
                className="labelName"
                style={{ marginBottom: 16 }}
                label="Community Name:"
                name="CommunityName"
                rules={[
                  { required: true, message: "Community Name cannot be empty" },
                ]}
              >
                <Input
                  className="inputBox popupWidth"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <div>
                <Form.Item
                  className="labelName community-image "
                  style={{ marginBottom: 16 }}
                  label={
                    isMobile ? (
                      <div className="flex flex-row items-center justify-between w-full">
                        <div>Community Profile Image</div>
                        <div
                          className="popupImageButton  float-right"
                          // type="primary"
                          onClick={HandleClick}
                        >
                          {/* <i className="bi bi-camera-fill"></i> */}
                          <span className="text-sm text-[#686f2e]">EDIT</span>
                          <input
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e: any) => {
                              onImageChange(e, "profileImage");
                            }}
                            ref={hiddenFileInput}
                            type="file"
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    ) : (
                      "Community Profile Image:"
                    )
                  }
                  name="profileImage"
                  rules={[
                    { required: true, message: "Profile Image is required" },
                  ]}
                >
                  {/* <Input
                    className="inputBox inputBoxForImage"
                    style={{ width: "100%" }}
                    value={imageName}
                  /> */}

                  <img
                    src={imageName}
                    className="EditProfileImage"
                    onClick={HandleClick}
                  />
                  {isMobile ? null : (
                    <Button
                      className="BrowseButton popupImageButton  float-right"
                      type="primary"
                      onClick={HandleClick}
                    >
                      Browse
                      <input
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e: any) => {
                          onImageChange(e, "profileImage");
                        }}
                        ref={hiddenFileInput}
                        type="file"
                        style={{ display: "none" }}
                      />
                    </Button>
                  )}
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className="labelName community-image ml-[2px]"
                  style={{ marginBottom: 16 }}
                  label={
                    isMobile ? (
                      <div className="flex flex-row items-center justify-between w-full">
                        <div>Community Banner Image</div>
                        <div
                          className=" popupImageButton float-right"
                          // type="primary"
                          onClick={handleBannerImageClick}
                        >
                          {/* <i className="bi bi-camera-fill"></i> */}
                          <span className="text-sm text-[#686f2e]">EDIT</span>

                          <input
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => onImageChange(e, "bannerImage")}
                            ref={hiddenBannerFileInput}
                            type="file"
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    ) : (
                      "Community Banner Image:"
                    )
                  }
                  name="bannerImage"
                >
                  {/* <Input
                    className="inputBox inputBoxForImage"
                    style={{ width: "100%" }}
                    value={bannerImageName}
                  /> */}
                  <img src={bannerImageName} className="EditBannerImage" />
                  {isMobile ? null : (
                    <Button
                      className="BrowseButton popupImageButton float-right"
                      type="primary"
                      onClick={handleBannerImageClick}
                    >
                      Browse
                      <input
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => onImageChange(e, "bannerImage")}
                        ref={hiddenBannerFileInput}
                        type="file"
                        style={{ display: "none" }}
                      />
                    </Button>
                  )}
                </Form.Item>
              </div>

              <Form.Item
                className="labelName"
                style={{ marginBottom: 16 }}
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Description cannot be empty" },
                ]}
              >
                <TextArea
                  className="communityDescription popupWidth"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                className="labelName"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Community type:"
                name="type"
                rules={[{ required: true, message: "Select community type" }]}
              >
                <Select className="communityDropDown popupWidth">
                  <Select.Option className="selectOptions" value="PRIVATE">
                    Private
                  </Select.Option>
                  <Select.Option className="selectOptions" value="PUBLIC">
                    Public
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                className="labelName popupWidth ml-[2px]"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Community Category:"
                rules={[
                  { required: true, message: "Select community category" },
                ]}
                name="communityCategory"
              >
                <Select
                  disabled
                  className="communityDropDown popupWidth"
                  onChange={handleselectchange}
                  value={selectedcategoryid}
                >
                  {categoriesOptions}
                </Select>
              </Form.Item>

              <Form.Item
                className="labelName popupWidth ml-[2px]"
                wrapperCol={{ offset: 0, span: 16 }}
                label="Allow only admin to post:"
                name="postsOnlyByAdmin"
              >
                <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className="switch w-14 bg-[#804d31]"
                              defaultChecked={props?.communityData?.postsOnlyByAdmin}
                              onChange={()=>setPostByAdmin(!postByAdmin)}
                            />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 21 }}>
                <Button
                  id="submitButton"
                  className="popupButton"
                  type="primary"
                  htmlType="submit"
                >
                  {props?.communityData?.surveyJSFormData?.communityData
                    ? "Next"
                    : "Save"}
                </Button>
              </Form.Item>
            </Form>
            </ConfigProvider>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EditCommunityPopup;
