import React, { useEffect, useState } from "react";
import { Button, Modal, Alert } from "antd";
import { toast } from "react-toastify";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";
import community from "../../assets/images/community-connect.jpg";
import { TIMEAGO } from "../../Utils/timeago";
import RejectComponent from "../RejectComponent/RejectComponent";
import NoRequest from "./NoRequest";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import PullToRefresh from "react-simple-pull-to-refresh";
import newloader from "../../assets/loader-new.gif";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {};

// eslint-disable-next-line no-empty-pattern
const CommunityRequest = ({}: Props) => {
  const [receivedData, setReceivedData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [specificData, setSpecificData] = useState<any>([]);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState(false);
  const [showRejectComponent, setShowRejectComponent] =
    useState<Boolean>(false);
  const navigate = useNavigate();

  const getReceivedData = async (page?: number) => {
    try {
      const resp = await request(
        `/member/current/community/joining/requests?page=${page || 0}&size=10`
      );
      if (resp.content.length === 0) {
        setHasMore(false);
      }
      // console.log(page,"CommunityRequest======>", resp.content);
      if (page === 0) {
        setReceivedData(resp?.content);
        setLoading(false);
      } else if (resp.content.length) {
        let oldData = [...receivedData];
        oldData = [...oldData, ...resp?.content];
        setReceivedData(oldData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReceivedData(currentPage);
  }, [currentPage, toggleRefresh]);

  const OnApproveORRejectClick = async (
    data: any,
    type: string,
    message?: string
  ) => {
    const body = {
      method: "PUT",
      body: JSON.stringify({
        userId: data?.userId,
        communityId: data?.communityId,
        ...(type === "reject" && { reason: message }),
        status: type === "approve" ? "APPROVE" : "REJECT",
      }),
    };

    if (type) {
      setLoader(true);
      let abusiveResult;
      if (message) {
        abusiveResult = await CheckBadWordsExist(message);
      }
      if (
        type === "approve" ||
        (!abusiveResult?.profanity && !abusiveResult?.words?.length)
      ) {
        const res = await request("/member/current/membership", body);
        if (res) {
          if (type === "approve" && res?.communityId) {
            toast.success("Approval processed successfully");
          } else if (type === "reject" && res?.communityId) {
            toast.success("Rejection processed successfully");
          }

          const filteredState = receivedData.filter(
            (item: any) =>
              !(
                item.userId === data?.userId &&
                item.communityId === data?.communityId
              )
          );
          setReceivedData(filteredState);
          setShowRejectComponent(false);
        }
        setLoader(false);
      } else if (abusiveResult?.profanity) {
        setLoader(false);
        toast.error("Prohibited from generating harmful content.");
      } else if (type !== "approve" && !(abusiveResult?.status === true)) {
        setLoader(false);
        toast.error(abusiveResult?.message || "Something went wrong");
      }
    }
  };

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    setToggleRefresh(!toggleRefresh);
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <section className="">
      <Alert
      type="info"
      message={<div style={{fontWeight:"600"}}>Manage Community Join Requests</div>}
      description={(
        <div>
          <p>Handle requests from users who want to join your community.</p>
          <p>
            <strong>Note:</strong> If no requests are shown, check back later for new ones.
          </p>
        </div>
      )}
      showIcon
      className="my-2"
    />
        <div className="thumbnailRowForThree requestbox">
          {loading ? (
            <div className="loader mt-20">
              <Loader />
            </div>
          ) : (
            loader ? 
              <div className="loader">
                <Modal className="loaderModal" open centered footer>
                  <Loader />
                </Modal>
              </div>
              :
            <>
              {!receivedData?.length ? (
                <div className="text-xl font-medium text-center w-full">
                  <NoRequest />
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={receivedData?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={<></>}
                  style={{ overflow: "visible" }}
                >
                  <div>
                    {receivedData?.map((data, index) => (
                      <div
                        className="thumbnailRowForThreeCol"
                        key={index}
                        style={{
                          display: "flex",
                          padding: "0 10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {data?.communityProfileUrl ? (
                          <img
                            src={data?.communityProfileUrl}
                            alt=""
                            className="reveived-comm-icon"
                          />
                        ) : (
                          <img
                            src={community}
                            alt=""
                            className="reveived-comm-icon"
                          />
                        )}

                        <div className="thumbnailDescription fontSize !text-black">
                          <span>
                            You have received a request from{" "}
                            <strong
                              className="font-medium text-[#6d3a21] cursor-pointer"
                              onClick={() => {
                                navigate(`/profile/${data?.userId}`);
                              }}
                            >
                              {" "}
                              {data?.name}
                            </strong>{" "}
                            to join{" "}
                            <strong
                              className="font-medium text-[#6d3a21] cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/communityPreview/${data?.communityId}`,
                                  {
                                    state: { from: 0 },
                                  }
                                );
                              }}
                            >
                              {" "}
                              {data?.communityName}
                            </strong>
                            <div className="mt-2">
                              <TIMEAGO timestamp={data?.createdAt || ""} />
                            </div>
                          </span>
                        </div>
                        <div className="buttonRow">
                          <Button
                            onClick={() => {
                              OnApproveORRejectClick(data, "approve");
                            }}
                            className="approve-btn border border-green-500 text-green-500  font-medium m-1"
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={() => {
                              setShowRejectComponent(true);
                              setSpecificData(data);
                            }}
                            className="reject-btn border-red-500 text-red-500 font-medium m-1"
                          >
                            Reject
                          </Button>
                        </div>
                        {showRejectComponent ? (
                          <div className="" key={index}>
                            <RejectComponent
                              onCancelClick={() => {
                                setShowRejectComponent(false);
                              }}
                              onRejectClick={(rejectText: any) => {
                                OnApproveORRejectClick(
                                  specificData,
                                  "reject",
                                  rejectText
                                );
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              )}
            </>
          )}
        </div>
      </section>
    </PullToRefreshComponent>
  );
};

export default CommunityRequest;
