import React, { useEffect, useState } from "react";
import Loader from "../Components/Loader/Loader";
import image from "../assets/images/imageplaceholder.png";
import { request } from "../Utils/request";
type Props = {};

function formatDate(date: string) {
  const parsedDate = new Date(date);
  const now = new Date();
  const timeDifference = now.getTime() - parsedDate.getTime();
  const milliseconds = 60 * 60 * 1000;
  if (timeDifference < milliseconds) {
    const hoursago = Math.floor(timeDifference / (60 * 1000));
    return `${hoursago} hours ago`;
  } else {
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth();
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
const News = (props: Props) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    /**
     * Clears the localStorage if the token is falsy.
     */
    if (!token) {
      localStorage.clear();
    }
    getNewsResp();
  }, []);

  const getNewsResp = async () => {
    setLoader(true);
    const newsResp = await fetch(`${BASE_URL}/news/current`);
    const newsData = await newsResp.json(); // Convert response to JSON
    setData(newsData?.data?.news.articles);
    setLoader(false);
  };

  const handleArticleClick = (url: string) => {
    // window.open(url, "_blank");
  };

  return (
    <div className="newsComponent">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          {loader ? (
            <>
              <div className="flex justify-center items-center pt-8">
                <Loader />
              </div>
            </>
          ) : (
            <div className="tabPanelBox">
              <div className="thumbnailBox">
                <div className="thumbnailRowForThree">
                  {data?.map((article: any, index) => (
                    <a
                      className="thumbnailRowForThreeCol cursor-pointer"
                      key={article.title}
                      onClick={() => handleArticleClick(article?.url)}
                      href={article?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="thumbnailImg news ">
                        {article?.urlToImage ? (
                          <img src={article?.urlToImage} alt="" />
                        ) : (
                          <img
                            src={image}
                            alt=""
                            className="thumbnailImg news object-none"
                          />
                        )}
                      </div>
                      <div className="thumbnailTitle">{article?.title}</div>
                      <div className="thumbnailDescription">
                        <p> {article?.description}</p>
                      </div>
                      <div className="sourcedataBox">
                        <div className="sourceName">
                          {article?.source?.name}
                        </div>
                        <div className="sourceDate">
                          {formatDate(article?.publishedAt)}
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default News;
