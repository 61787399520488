import React, { useEffect, useState } from "react";
// import "../Header/Header.css";
import { useNavigate } from "react-router";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";

type SearchResultsProps = {
  queryText: string;
  setShowSearchResults: Function;
  setSelectedOption: Function;
  clearSearchText: Function;
};

type selectOption = {
  searchTerm: string;
  searchAcross: string;
  operator: string;
};

const RootSearchDropdown: React.FC<SearchResultsProps> = ({
  setShowSearchResults,
  clearSearchText,
  queryText,
  setSelectedOption,
}) => {
  const navigate = useNavigate();
  const [searchDropDownResults, setsearchDropDownResults] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);

  const keyDisplayMap: Record<string, string> = {
    originCity: "Origin City",
    originState: "Origin State",
    currentCity: "Current City",
    currentState: "Current State",
    religion: "Religion",
    caste: "Caste",
    subCaste: "Sub-Caste",
    familyName: "Family Name",
    name: "Name",
  };

  const displayKeyMap = [
    {
      key: "originCity",
      value: "Origin City",
    },
    {
      key: "originState",
      value: "Origin State",
    },
    {
      key: "currentCity",
      value: "Current City",
    },
    {
      key: "currentState",
      value: "Current State",
    },
    {
      key: "religion",
      value: "Religion",
    },
    {
      key: "caste",
      value: "Caste",
    },
    {
      key: "subCaste",
      value: "Sub-Caste",
    },
    {
      key: "familyName",
      value: "Family Name",
    },
    {
      key: "name",
      value: "Name",
    },
  ];

  const findKeyValue = (data: any, key: any) => {
    console.log("data", data, key);
    let res = data?.find(
      (item: any) =>
        item?.key?.toUpperCase() === key?.toUpperCase() ||
        item?.value?.toUpperCase() === key?.toUpperCase()
    );
    console.log("res", res);
    return res;
  };

  useEffect(() => {
    console.log(queryText);
    const getDropdownValues = async () => {
      setLoader(true);
      try {
        const response = await request(
          `/member/current/search-by-root/category?searchTerm=${queryText}`
        );
        if (typeof response === "object") {
          const filteredResults = Object.entries(response)
            .filter(([key, value]) => value === true)
            .map(([key]) => ({
              label: keyDisplayMap[key],
              values: keyDisplayMap[key].includes("City")
                ? response[key.slice(0, -1) + "ies"]
                : response[key + "s"], // Assuming the values are stored in the corresponding key + 's' array
            }));

          setsearchDropDownResults(filteredResults);
          console.log("Filtered results", filteredResults);
        } else {
          setsearchDropDownResults([]);
        }
      } catch (error) {
        console.error("Error fetching search dropdown values:", error);
        setsearchDropDownResults([]);
      }
      setLoader(false);
    };

    getDropdownValues();
  }, [queryText]);
  return (
    <div className="absolute bg-white w-4/5 mt-16 ml-5 p-2 rounded-lg max-h-60 overflow-y-auto z-10 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
      {loader ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <ul>
          {searchDropDownResults?.length > 0 ? (
            searchDropDownResults?.map(
              (searchDropDownResult: any, index: any) => (
                <React.Fragment key={index}>
                  {searchDropDownResult.values ? (
                    searchDropDownResult.values.map(
                      (value: any, valueIndex: any) => (
                        <li
                          key={`${index}-${valueIndex}`}
                          className="cursor-pointer searchResultItem"
                          onClick={() => {
                            setSelectedOption((prevState: selectOption[]) => [
                              ...prevState,
                              {
                                searchTerm: value,
                                searchAcross: findKeyValue(
                                  displayKeyMap,
                                  searchDropDownResult.label
                                )?.key,
                                searchAcrossDisplayName:
                                  searchDropDownResult.label,
                                operator: "OR",
                              },
                            ]);
                            clearSearchText();
                            setShowSearchResults(false);
                          }}
                        >
                          <span className="text-gray-500">Search </span>
                          {value}
                          <span className="text-gray-500"> across </span>
                          {searchDropDownResult.label}
                        </li>
                      )
                    )
                  ) : (
                    <li
                      key={index}
                      className="cursor-pointer searchResultItem"
                      onClick={() => {
                        setSelectedOption((prevState: selectOption[]) => [
                          ...prevState,
                          {
                            searchTerm: "",
                            searchAcross: findKeyValue(
                              displayKeyMap,
                              searchDropDownResult.label
                            )?.key,
                            searchAcrossDisplayName: searchDropDownResult.label,
                            operator: "AND",
                          },
                        ]);
                        clearSearchText();
                        setShowSearchResults(false);
                      }}
                    >
                      <span className="text-gray-500">Search </span>
                      {searchDropDownResult.label}
                    </li>
                  )}
                </React.Fragment>
              )
            )
          ) : (
            <li
              className="py-1 text-lg text-slate-500"
              onClick={() => {
                setShowSearchResults(false);
                clearSearchText();
              }}
            >
              No result
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default RootSearchDropdown;
