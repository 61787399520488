import React, { createContext, useContext, useState } from "react";

interface ChatContextType {
  chatData: any;
  setChatDataFunction: (data: any) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error(
      "useChatContext must be used within an ChatContextProvider"
    );
  }
  return context;
};

interface ChatContextProviderProps {
  children: React.ReactNode;
}

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
}) => {
  const [chatData, setChatData] = useState<any>(null);

  const setChatDataFunction = (data: any) => {
    setChatData(data);
  };

  return (
    <ChatContext.Provider value={{ chatData, setChatDataFunction }}>
      {children}
    </ChatContext.Provider>
  );
};
