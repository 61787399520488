import React from "react";
import { Card } from "antd";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import gothram from "../../assets/matrimonyIcons/gothram.svg";
import birthStar from "../../assets/matrimonyIcons/birth star.svg";
import caste from "../../assets/matrimonyIcons/caste.svg";
import contactNo from "../../assets/matrimonyIcons/contact no..svg";
import cookingSkills from "../../assets/matrimonyIcons/cooking skills.svg";
import dosham from "../../assets/matrimonyIcons/dosham.svg";
import eatingHabits from "../../assets/matrimonyIcons/eating habits.svg";
import education from "../../assets/matrimonyIcons/education.svg";
import height from "../../assets/matrimonyIcons/height.svg";
import kids from "../../assets/matrimonyIcons/kids.svg";
import location from "../../assets/matrimonyIcons/location.svg";
import maritalStatus from "../../assets/matrimonyIcons/marital status.svg";
import profile from "../../assets/matrimonyIcons/profile.svg";
import raasi2 from "../../assets/matrimonyIcons/raasi_2.svg";
import religion from "../../assets/matrimonyIcons/religion.svg";
import workOrProfession from "../../assets/matrimonyIcons/work or profession.svg";
import edit from "../../assets/matrimonyIcons/edit.svg";
import icons from "../../assets/hobbiesIcons/icons";
import "./MatrimonyInfoCard.css";
import { isTablet } from "../../Utils/mobileSettings";

const creativeCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.writing} />,
    hobby: "Writing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.cooking} />,
    hobby: "Cooking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.singing} />,
    hobby: "Singing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.photography} />,
    hobby: "Photography",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.instrument} />,
    hobby: "Playing Instruments",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.art} />,
    hobby: "Painting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.DIY} />,
    hobby: "DIY crafts",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.dancing} />,
    hobby: "Dancing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.acting} />,
    hobby: "Acting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.poetry} />,
    hobby: "Poetry",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.poetry} />,
    hobby: "Book restoration",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.gardening} />,
    hobby: "Gardening",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.blogging} />,
    hobby: "Blogging",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.content} />,
    hobby: "Content creation",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.designing} />,
    hobby: "Designing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.doodling} />,
    hobby: "Crocheting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.doodling} />,
    hobby: "Doodling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.pets} />,
    hobby: "Pets",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.foodie} />,
    hobby: "Foodie",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.vegan} />,
    hobby: "Vegan",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.news} />,
    hobby: "News & Politics",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.socialService} />,
    hobby: "Social service",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.entre} />,
    hobby: "Entrepreneurship",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.homeDecor} />,
    hobby: "Home Decor",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.invest} />,
    hobby: "Investments",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.fashionBeauty} />,
    hobby: "Fashion & Beauty",
  },
];

const funCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.movies} />,
    hobby: "Movies",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.music} />,
    hobby: "Music",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.travel} />,
    hobby: "Travelling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.reading} />,
    hobby: "Reading",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.sports} />,
    hobby: "Sports",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.social} />,
    hobby: "Social Media",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.gaming} />,
    hobby: "Gaming",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.bingewatching} />,
    hobby: "Binge-watching",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.biking} />,
    hobby: "Biking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.clubbing} />,
    hobby: "Clubbing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.shopping} />,
    hobby: "Shopping",
  },
  // {
  //   icons: icons,
  //   hobby: "Theatre & Events"
  // },
  // {
  //   icons: icons,
  //   hobby: "Anime"
  // },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.standup} />,
    hobby: "Stand ups",
  },
];

const fitnessCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.running} />,
    hobby: "Runnning",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.walking} />,
    hobby: "Walking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.cycling} />,
    hobby: "Cycling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.yoga} />,
    hobby: "Yoga & Meditation",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.hiking} />,
    hobby: "Trekking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.workingOut} />,
    hobby: "Working Out",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.swimming} />,
    hobby: "Swimming",
  },
];

function getIconForHobby(hobby: string) {
  const allCategories = [
    ...creativeCategories,
    ...funCategories,
    ...fitnessCategories,
  ];

  const category = allCategories?.find((item) => item.hobby === hobby);
  return category ? category.icon : null;
}

const capitalizeWords = (input: string) => {
  const words = input?.split(" ");

  const capitalizedWords = words?.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word;
    }
  });

  return capitalizedWords?.join(" ");
};

function renderField(icon: any, label: any, value: any) {
  return (
    <div
      className={
        isTablet
          ? "w-full text-lg p-2 flex items-center"
          : "w-1/3 text-lg p-2 flex items-center"
      }
    >
      <img src={icon} alt="" style={{ height: "20px" }} className="mr-2" />
      <span className="font-semibold"> {label}&nbsp;&nbsp;</span>{" "}
      <span className="whitespace-nowrap">
        {value ? (
          capitalizeWords(value)
        ) : (
          <p className="text-lg blur-sm">XXXX</p>
        )}
      </span>
    </div>
  );
}

function convertHeightToFeetAndInches(heightInCms: number) {
  const inches = heightInCms / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  // Adjust for the case where remainingInches is 12
  if (remainingInches === 12) {
    return `${feet + 1}' 0''`;
  }

  return `${feet}' ${remainingInches}''`;
}

interface ProfileData {
  formFields: string[];
  aboutMe: string;
  id: string;
  userId: string;
  profileFor: string;
  member: {
    name: string;
    currentState: string;
    currentCity: string;
    gender: string;
    dob: string;
    height: number | null;
    profileUrl: string | null;
  };
  age: number | null;
  foodHabit: string | null;
  educationDetails: any;
  maritalStatus: {
    status: string;
    noOfChildren: number;
  };
  profileUrl: string[] | null;
  familyDetails: {
    religion: string;
    caste: string;
    subCaste: string;
    rashi: string;
    gothra: string;
    nakshathra: string;
    foodHabits: string;
    division: string;
    familyName: string;
  };
  lookingFor: {
    castePreference: string[];
    educationPreference: string[];
    location: string[];
    minHeight: number;
    maxHeight: number;
    food: string;
    religion: string;
  };
  contactDetails: {
    emailId: string;
    phoneNumber: string;
    contactSharingAllowed: boolean;
  };
  isActive: boolean;
  hobbies: string[];
  bioDataDocument: string | null;
  createdAt: string;
  yourProfileMatchingScores: any;
  show: string;
  user: boolean;
  request: string;
}

interface MatrimonyInfoCardProps {
  profileData: ProfileData;
}

const MatrimonyInfoCard: React.FC<MatrimonyInfoCardProps> = ({
  profileData,
}) => {
  const getColorForPercentage = (percentage: any) => {
    if (percentage > 70) {
      return "#32CD32";
    } else if (percentage >= 40 && percentage <= 70) {
      return "#F7C900";
    } else {
      return "#FF5733";
    }
  };
  return (
    <div>
      <Card className="m-2 p-2 rounded-3xl w-full">
        <div className="horizontal-sections">
          <div className="about border-b border-gray pb-2 wrapColumn">
            <h4 className="text-xl flex items-center m-2 p-2 mt-0 pt-0 font-bold ml-0 pl-0">
              About&nbsp;
              {capitalizeWords(profileData?.member?.name)}
            </h4>
            <div className="flex flex-wrap text-gray-600">
              <div className="w-full text-lg p-2 flex ">
                <img
                  src={edit}
                  alt=""
                  style={{ height: "20px" }}
                  className="mr-2 mt-2"
                />
                <span className="font-semibold">&nbsp;</span>{" "}
                <span className="">
                  {profileData?.aboutMe ? (
                    profileData?.aboutMe
                  ) : (
                    <p className="text-lg"></p>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="about border-b border-gray pb-2">
            <h4 className="text-xl flex items-center m-2 p-2 mt-0 pt-0 font-bold ml-0 pl-0">
              Religious Background
            </h4>
            <div className="flex flex-wrap text-gray-600">
              {profileData?.formFields?.includes("religion") &&
                renderField(
                  religion,
                  "Religion",
                  profileData?.familyDetails?.religion
                )}

              {profileData?.formFields?.includes("caste") &&
                renderField(
                  caste,
                  "Caste",
                  profileData?.familyDetails?.caste?.toLowerCase()
                )}

              {profileData?.formFields?.includes("subCaste") &&
                renderField(
                  gothram,
                  "Sub Caste",
                  profileData?.familyDetails?.subCaste?.toLowerCase()
                )}

              {profileData?.formFields?.includes("rashi") &&
                renderField(raasi2, "Rashi", profileData?.familyDetails?.rashi)}

              {profileData?.formFields?.includes("gothra") &&
                renderField(
                  gothram,
                  "Gothra",
                  profileData?.familyDetails?.gothra
                )}

              {profileData?.formFields?.includes("nakshatra") &&
                renderField(
                  birthStar,
                  "Nakshathra",
                  profileData?.familyDetails?.nakshathra
                )}

              {profileData?.foodHabit === "NON_VEGETARIAN" &&
                renderField(cookingSkills, "Food Habits", "Non vegetarian")}

              {profileData?.foodHabit === "VEGETARIAN" &&
                renderField(cookingSkills, "Food Habits", "Vegetarian")}

              {profileData?.formFields?.includes("division") &&
                renderField(
                  dosham,
                  "Division",
                  profileData?.familyDetails?.division
                )}

              {renderField(
                profile,
                "Family Name",
                profileData?.familyDetails?.familyName
              )}
            </div>
          </div>
          <div className="professional border-b border-gray pb-2 wrapColumn">
            <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
              Professional Details
            </h4>
            <div className="flex flex-wrap text-gray-600">
              <div className="w-1/3 text-lg p-2 ">
                {renderField(
                  education,
                  "Education",
                  profileData?.educationDetails?.educationDetails
                )}
                {renderField(
                  workOrProfession,
                  "Employment",
                  profileData?.educationDetails?.employmentDetails
                )}
              </div>
            </div>
          </div>

          <div className="hobbies pb-2 border-b border-gray wrapColumn">
            <h4 className="text-xl flex items-center p-2 m-2 font-bold pl-0 ml-0">
              Hobbies
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {profileData?.hobbies?.map((hobby, index) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/5 text-lg p-2 "
                  key={index}
                >
                  <div className="w-9 text-lg p-2 flex items-center">
                    {getIconForHobby(hobby)}&nbsp;&nbsp;
                    <span className="whitespace-nowrap">
                      {hobby ? hobby : <p className="text-lg blur-sm">XXXX</p>}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {profileData?.contactDetails && (
            <div className="contact border-b border-gray pb-2 wrapColumn">
              <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
                Contact
              </h4>
              <div className="flex flex-wrap text-gray-600">
                {profileData?.contactDetails?.emailId ? (
                  <div
                    className={
                      isTablet
                        ? "w-full text-lg p-2 flex items-center"
                        : "w-1/3 text-lg p-2 flex items-center"
                    }
                  >
                    <img
                      src={profile}
                      alt=""
                      style={{ height: "20px" }}
                      className="mr-2"
                    />
                    <span className="font-semibold"> Email:&nbsp;</span>{" "}
                    {profileData?.contactDetails?.emailId}
                  </div>
                ) : (
                  <div
                    className={
                      isTablet
                        ? "w-full text-lg p-2 flex items-center"
                        : "w-1/3 text-lg p-2 flex items-center"
                    }
                  >
                    <img
                      src={profile}
                      alt=""
                      style={{ height: "20px" }}
                      className="mr-2"
                    />
                    <span className="font-semibold"> Email:&nbsp;</span> XXX
                  </div>
                )}

                {profileData?.contactDetails?.phoneNumber &&
                profileData?.contactDetails?.contactSharingAllowed ? (
                  <div
                    className={
                      isTablet
                        ? "w-full text-lg p-2 flex items-center"
                        : "w-1/3 text-lg p-2 flex items-center"
                    }
                  >
                    <img
                      src={contactNo}
                      alt=""
                      style={{ height: "20px" }}
                      className="mr-2"
                    />
                    <span className="font-semibold"> Phone Number:&nbsp;</span>
                    {" +"}
                    {profileData.contactDetails.phoneNumber}
                  </div>
                ) : (
                  <div
                    className={
                      isTablet
                        ? "w-full text-lg p-2 flex items-center"
                        : "w-1/3 text-lg p-2 flex items-center"
                    }
                  >
                    <img
                      src={contactNo}
                      alt=""
                      style={{ height: "20px" }}
                      className="mr-2"
                    />
                    <span className="font-semibold"> Phone Number:&nbsp;</span>{" "}
                    XXX
                  </div>
                )}
              </div>
            </div>
          )}

          {profileData?.lookingFor && (
            <div className="preferences border-b border-gray pb-2 wrapColumn">
              <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
                Preferences
              </h4>
              <div className="flex flex-wrap text-gray-600">
                {/* {renderField(
                  religion,
                  "Caste Preference",
                  profileData?.lookingFor?.castePreference
                )} */}
                <div
                  className={
                    isTablet
                      ? "w-full text-lg p-2 flex items-center"
                      : "w-1/3 text-lg p-2 flex items-center"
                  }
                >
                  <img
                    src={religion}
                    alt=""
                    style={{ height: "20px" }}
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    Religious Preference &nbsp;&nbsp;
                  </span>
                  <span className="whitespace-nowrap">
                    {profileData?.lookingFor?.religion ? (
                      <>{profileData?.lookingFor?.religion}</>
                    ) : (
                      <p className="text-lg blur-sm">XXXX</p>
                    )}
                  </span>
                </div>
                <div
                  className={
                    isTablet
                      ? "w-full text-lg p-2 flex items-center"
                      : "w-1/3 text-lg p-2 flex items-center"
                  }
                >
                  <img
                    src={caste}
                    alt=""
                    style={{ height: "20px" }}
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    Caste Preference &nbsp;&nbsp;
                  </span>
                  <span className="whitespace-nowrap">
                    {profileData?.lookingFor?.castePreference ? (
                      <>
                        {profileData?.lookingFor?.castePreference?.map(
                          (value: any, index: number) => (
                            <div className="whitespace-nowrap" key={index}>
                              {value}
                              {index !==
                                profileData.lookingFor.castePreference?.length -
                                  1 && ","}
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <p className="text-lg blur-sm">XXXX</p>
                    )}
                  </span>
                </div>

                {/* {renderField(
                  education,
                  "Education Preference",
                  profileData?.lookingFor?.educationPreference
                )} */}
                <div
                  className={
                    isTablet
                      ? "w-full text-lg p-2 flex items-center"
                      : "w-1/3 text-lg p-2 flex items-center"
                  }
                >
                  <img
                    src={education}
                    alt=""
                    style={{ height: "20px" }}
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    Education Preference &nbsp;&nbsp;
                  </span>
                  <span className="whitespace-nowrap">
                    {profileData?.lookingFor?.educationPreference ? (
                      <>
                        {profileData?.lookingFor?.educationPreference?.map(
                          (value: any, index: number) => (
                            <div className="whitespace-nowrap">
                              {value}
                              {index !==
                                profileData.lookingFor.educationPreference
                                  .length -
                                  1 && ","}
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <p className="text-lg blur-sm">XXXX</p>
                    )}
                  </span>
                </div>
                <div
                  className={
                    isTablet
                      ? "w-full text-lg p-2 flex items-center"
                      : "w-1/3 text-lg p-2 flex items-center"
                  }
                >
                  <img
                    src={height}
                    alt=""
                    style={{ height: "20px" }}
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    {" "}
                    Height Preference&nbsp;
                  </span>{" "}
                  <span className="whitespace-nowrap">
                    {profileData?.lookingFor?.minHeight &&
                    profileData?.lookingFor?.maxHeight ? (
                      <>
                        {convertHeightToFeetAndInches(
                          profileData?.lookingFor?.minHeight
                        )}
                        {/* {`(${profileData?.lookingFor?.minHeight}cms)`} */}
                        &nbsp; - &nbsp;
                        {convertHeightToFeetAndInches(
                          profileData?.lookingFor?.maxHeight
                        )}
                        {/* {`(${profileData?.lookingFor?.maxHeight}cms)`} */}
                      </>
                    ) : (
                      <p className="text-lg blur-sm">XXXX</p>
                    )}
                  </span>
                </div>

                {profileData?.lookingFor?.food === "NON_VEGETARIAN" &&
                  renderField(eatingHabits, "Food Habits", "Non vegetarian")}

                {profileData?.lookingFor?.food === "VEGETARIAN" &&
                  renderField(eatingHabits, "Food Habits", "Vegetarian")}

                {/* {renderField(
                  location,
                  "Location Preference",
                  profileData?.lookingFor?.location
                )} */}

                <div
                  className={
                    isTablet
                      ? "w-full text-lg p-2 flex items-center"
                      : "w-1/3 text-lg p-2 flex items-center"
                  }
                >
                  <img
                    src={location}
                    alt=""
                    style={{ height: "20px" }}
                    className="mr-2"
                  />
                  <span className="font-semibold">
                    Location Preference &nbsp;&nbsp;
                  </span>
                  <span className="whitespace-nowrap">
                    {profileData?.lookingFor?.location ? (
                      <>
                        {profileData?.lookingFor?.location?.map(
                          (value: any, index: number) => (
                            <div className="whitespace-nowrap" key={index}>
                              {value}
                              {index !==
                                profileData.lookingFor.location.length - 1 &&
                                ","}
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <p className="text-lg blur-sm">XXXX</p>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          {profileData?.maritalStatus && (
            <div className="status border-b border-gray pb-2">
              <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
                Marital Status
              </h4>
              <div className="flex flex-wrap text-gray-600">
                {renderField(
                  maritalStatus,
                  "Marital Status",
                  profileData?.maritalStatus?.status?.toLowerCase()
                )}
                {profileData?.maritalStatus?.noOfChildren !== 0 &&
                  renderField(
                    kids,
                    "No of Children",
                    profileData?.maritalStatus?.noOfChildren?.toString()
                  )}
              </div>
            </div>
          )}
          {profileData?.bioDataDocument && (
            <div className="Bio Data ">
              <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
                Bio Data file
              </h4>
              <div className="flex flex-wrap text-gray-600">
                <a
                  href={profileData?.bioDataDocument || ""}
                  download="Bio-PDF-document.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="bg-[#7d8742] text-white rounded-lg p-2">
                    Download Biodata file
                  </button>
                </a>
              </div>
            </div>
          )}
          {profileData?.yourProfileMatchingScores?.length > 0 && (
            <>
              <h4 className="text-xl flex items-center p-2 m-2 font-bold ml-0 pl-0">
                See how well your{" "}
                {profileData?.yourProfileMatchingScores?.length > 1
                  ? "profiles match"
                  : "profile matches"}{" "}
                {profileData?.member?.name}'s profile
              </h4>
              <div className="matchingProfilesContainer">
                {profileData?.yourProfileMatchingScores?.map(
                  (profile: any, index: any) => (
                    <div className="flex flex-col my-4 matchingProfiles">
                      <div className="flex flex-row items-center">
                        {profile?.profileUrl?.length > 0 ? (
                          <img
                            src={profile?.profileUrl[0]}
                            alt="profile pic"
                            className="matchprofileImage"
                            style={{ aspectRatio: 3 / 2 }}
                          />
                        ) : (
                          <img
                            src={profilePlaceholder}
                            alt="profile pic"
                            className="matchprofileImage"
                            // style={{height:"100px",width:"110px",borderRadius:"50%",objectFit:"cover"}}
                          />
                        )}
                        <div className="flex flex-col  w-[90%] mx-5">
                          <span className="matrimonyheading">
                            Match Score{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {Math.floor(profile.matchingPercentage)}%
                            </span>
                          </span>
                          <div style={{ width: "100%" }}>
                            <div
                              className="matching-bar"
                              style={{
                                width: `${profile?.matchingPercentage}%`,
                                backgroundColor: `${getColorForPercentage(
                                  profile?.matchingPercentage
                                )}`,
                                height: "10px",
                                borderRadius: "10px",
                              }}
                            ></div>
                          </div>
                        </div>
                        {/* {profileData?.profileUrl  ?
            (profileData?.show !==
              "ONLY_TO_REQUESTED_PEOPLE" ||
            profileData?.user ||
            profileData?.request === "APPROVE" ? (
              <img
              src={profileData?.profileUrl[0]}
              alt="profile pic"
              className="matchprofileImage"
              // style={{height:"100px",width:"110px",borderRadius:"50%",objectFit:"cover"}}
            />):(
              <img
              src={profileData?.profileUrl[0]}
              alt="profile pic"
              className="blur-sm matchprofileImage"
              // style={{height:"100px",width:"110px",borderRadius:"50%",objectFit:"cover"}}
            />
            ))
                :
                <img 
                src={profilePlaceholder}
                alt="profile pic"
                style={{height:"100px",width:"110px",borderRadius:"50%",objectFit:"cover"}}/>
                } */}
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default MatrimonyInfoCard;
