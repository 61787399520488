import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import { Button, Modal, Form, Select, Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import "./AdminPage.css"
import newloader from "../../assets/loader-new.gif"

const { Search } = Input;


function ActiveCommunitiesComponent(){

    const [activeSearchText, setActiveSearchText] = useState("");
  const [activeSearchResults, setActiveSearchResults] = useState([]);
  const [activeMemberStatus, setActiveMemberStatus] = useState<any>([]);
  const [finalActiveMemberStatus, setFinalActiveMemberStatus] = useState<any>([]);
  const [activeCommunities, setActiveCommunities] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);


  const fetchData = async (page?: number) => {
    try {
      const resp = await request(`/admin/community/search?block=false&page=${page}&size=10`);
      if (!resp?.status) {
        if (!resp?.content || resp?.content?.length === 0) {
          if (page === 0) {
            setActiveCommunities(resp?.content);
          }
          setHasMore(false);
        } else {
          if (page === 0) {
            setActiveCommunities(resp?.content);
            if (resp?.content?.length === 0) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }else {
            setActiveCommunities([...activeCommunities, ...resp?.content]);
          }
        }
      } else {
       setLoading(false);
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleActiveSearch = async (value: string) => {
    try {
      const resp = await request(`/admin/community/search?communityName=${value}&block=false&page=${currentPage}&size=10`);
      if (resp) {
        if (resp?.content?.length === 0) {
          setHasMore(false);
        }
        if (currentPage === 0) {
          setActiveSearchResults(resp?.content);
          if (resp?.content?.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          let oldResults:any = [...activeSearchResults];
          oldResults = [...oldResults, ...resp?.content];
          setActiveSearchResults(oldResults);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const onAddButtonClick = async () => {
    setLoading(true)
    const activeData = Object.values(finalActiveMemberStatus);
    const addButtonPayload = {
      method: "PATCH",
      body:  JSON.stringify(activeData)
      ,
      type: "application/json",
    };
      const response = await request(`/admin/community/block-unblock`,addButtonPayload)
      if(response?.length){
        setActiveMemberStatus([]);
        setFinalActiveMemberStatus([]);
      toast.success("Community Status Updated");
      setCurrentPage(0)
      fetchData(0)
      }
    
  };

  const onActiveChange = (value:any, index:any, id:any) => {
    // const findIndex = memberStatus.findIndex((item:any) => item.userId === id);
    if(activeMemberStatus[id]?.length>0){
      
    const updatedStatuses = [...activeMemberStatus];
      updatedStatuses[id] = {...updatedStatuses[id],
        block: value.toUpperCase()==="ACTIVE" ? false : true,
      communityId: id
    };
      setActiveMemberStatus(updatedStatuses);
      setFinalActiveMemberStatus(updatedStatuses)
    }else{
      const newStatus = {
        block: value.toUpperCase() === "ACTIVE" ? false : true,
        communityId: id,
      };
      setActiveMemberStatus({...activeMemberStatus,[id]: newStatus});
      setFinalActiveMemberStatus({...finalActiveMemberStatus,[id]: newStatus});
    }
    
  };

  const communityPermissions = ["Active", "Block"];
  const { Option } = Select;

    return (
        <>
       { loading ?
        <div className="loader">
        <Modal className="loaderModal" open centered footer>
          <Loader />
        </Modal>
      </div> :
        <>
         <div className="flex justify-center"> <Search
            placeholder="Search Community..."
            allowClear
            style={{ borderRadius: "20px" }}
            id="communitySearch"
            enterButton
            size="large"
            value={activeSearchText}
            onChange={(e: any) => {
              setCurrentPage(0)
              setActiveSearchText(e.target.value);
              handleActiveSearch(e.target.value);
            }}
            className="adminCommunitySearchBar"
            // onSearch={handleCommunitySearch}
          />
          </div>
         {activeSearchText?.length === 0 ?
         <InfiniteScroll
         dataLength={activeCommunities?.length}
         next={() => {
           setCurrentPage(currentPage + 1);
         }}
         hasMore={hasMore}
         loader={ <></>
           // currentPage!==0 &&
           // (<div className="flex flex-row items-center justify-center">
           //   <img
           //     src={newloader}
           //     style={{ width: "80px", height: "80px" }}
           //     className="my-3"
           //     alt=""
           //   />
           // </div>)
           }
           scrollableTarget="adminScrollableDiv"
       >
               <div id="adminScrollableDiv" className="flex justify-center adminCommunityList">
               <div className="flex flex-col adminCommunityListContainer">
               {activeCommunities?.length > 0 ? activeCommunities.map((community: any, index: any) => (
                 <div className="flex flex-row items-center justify-between" key={community?.id}>
                                       <div
                                         key={index}
                                         className="flex flex-row items-center py-2 justify-start dpnamecontainer"
                                       >
                                        
                                           <div
                                             className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                                             //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                             
                                             style={{
                                               backgroundColor:
                                               community?.communityProfileUrl,
                                             }}
                                           >
                                             <img src={community?.communityProfileUrl} alt=""/>
                                           </div>   
                                         <div
                                           className="flex items-center flex-col modalmemberName cursor-pointer"
                                           style={{ color: "black" }}
                                         >
                                           <span className="adminpostMembertitle capitalize ml-2">
                                               <span className="modalmemberName">
                                                 {community?.name}
                                               </span>
                                           </span>
                                         </div>
                                       </div>
                                       <div>
                                       <Select
                                       defaultValue={(community?.block && activeMemberStatus?.length === 0) ? "Blocked" :(activeMemberStatus[community?.id]?.block ? "Blocked" : "Active")}
                                       className={
                                         (community?.block && activeMemberStatus?.length === 0) ?  "blockedDropdown statusDropdown searchbardiscover" : activeMemberStatus[community?.id]?.block ? "blockedDropdown statusDropdown searchbardiscover" : " activeDropdown statusDropdown searchbardiscover" }
                                      bordered={false}
             optionLabelProp="label"
             style={{ width: "100%" }}
             onChange={(value) => onActiveChange(value, index,  community?.id)}
           >
             {communityPermissions?.map((item:any) => (
               <Option key={item} value={item} label={item}>
                 <div>{item === "Blocked" ? "Block" : `${item}`}</div>
               </Option>
             ))}
           </Select>
                
                 </div>
                                       </div>
                                     ))
                                    :
                                    <div className="flex flex-row items-center justify-center noCommunitiesText">No Communities</div>}
               </div>
               </div>
               </InfiniteScroll>
               :
               <InfiniteScroll
                  dataLength={activeSearchResults?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={ <></>
                    }
                    scrollableTarget="adminsearchScrollableDiv"
                >
               <div id="adminsearchScrollableDiv" className="flex justify-center adminCommunityList">
               <div className="flex flex-col adminCommunityListContainer">
               {activeSearchResults?.length > 0 ? activeSearchResults?.map((community: any, index: any) => (
                 <div className="flex flex-row items-center justify-between" key={community?.id}>
                                       <div
                                         key={index}
                                         className="flex flex-row items-center py-2 justify-start dpnamecontainer"
                                       >
                                        
                                           <div
                                             className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                                             //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                             
                                             style={{
                                               backgroundColor:
                                               community?.communityProfileUrl,
                                             }}
                                           >
                                             <img src={community?.communityProfileUrl} alt=""/>
                                           </div>   
                                         <div
                                           className="flex items-center flex-col modalmemberName cursor-pointer"
                                           style={{ color: "black" }}
                                           
                                         >
                                           <span className="adminpostMembertitle capitalize ml-2">
                                               <span className="modalmemberName">
                                                 {community?.name}
                                               </span>
                                           </span>
                                         </div>
                                       </div>
                                       <div>
                                       <Select
                                       defaultValue={(community?.block && activeMemberStatus?.length === 0) ? "Blocked" :(activeMemberStatus[community?.id]?.block ? "Blocked" : "Active")}
                                       className={
                                         (community?.block && activeMemberStatus?.length === 0) ?  "blockedDropdown statusDropdown searchbardiscover" : activeMemberStatus[community?.id]?.block ? "blockedDropdown statusDropdown searchbardiscover" : " activeDropdown statusDropdown searchbardiscover" }
                                      bordered={false}
             optionLabelProp="label"
             style={{ width: "100%" }}
             onChange={(value) => onActiveChange(value, index,  community?.id)}
           >
             {communityPermissions?.map((item:any) => (
               <Option key={item} value={item} label={item}>
                 <div>{item === "Blocked" ? "Block" : `${item}`}</div>
               </Option>
             ))}
           </Select>
                
                 </div>
                                       </div>
                                     ))
                                    :
                                    <div className="flex flex-row items-center justify-center noCommunitiesText">No Communities</div>}
               </div>
               </div>
               </InfiniteScroll>}
               <div className="w-full flex justify-center">
          <div className="flex justify-end adminSettingsSaveButtonContainer">
            <Button
              disabled={(finalActiveMemberStatus?.length ===0 ? true : false)}
              className="adminSettingsSaveButton"
              onClick={(event) => {
                onAddButtonClick();
                event.stopPropagation();
              }}
            >
                 Save
            </Button>
          </div>
          </div>
               </>
}   </> )
}

export default ActiveCommunitiesComponent;