import React, { useEffect } from "react";
import CommunityTab from "../Components/Commuinty/CommuinityTab/CommunityTab";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "../Utils/request";

type Props = {
  // parentCallback: Function;
};

// eslint-disable-next-line no-empty-pattern
function Community(props: Props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate("/profile");
    }
    return;
  };

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear();
      navigate("/login");
      // props.parentCallback("login");
    }
    if (profileScore === null) {
      meApiCall();
    } else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
  }, [navigate]);

  return (
    <div>
      <div className="containerWithOutSideBar">
        <div className="mainContentCol" id="communityWrapper">
          {token?.length && <CommunityTab />}
        </div>
      </div>
    </div>
  );
}

export default Community;
