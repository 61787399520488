import React from "react";
import { Button, Checkbox, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { request } from "../../Utils/request";

type Props = {
  matrimonyID: any;
};

const DLKyc = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const currentDate = dayjs();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const matrimonyID = props?.matrimonyID;
  let token = localStorage.getItem("token");
  const onFinish = async (values: any) => {
    try {
      const { drivingID, dob, termsCondition } = values;
      if (!termsCondition) {
        form.setFields([
          {
            name: "termsCondition",
            errors: ["Please accept the terms and conditions!"],
          },
        ]);
        return;
      } else {
        const requestBody = {
          method:'POST',
          body:JSON.stringify({
            docNumber: drivingID,
            matrimonyId: matrimonyID,
            docTypeEnum: "DL",
            fullName: "",
            dob: dob,
            mobileNumber: "",
          })
         
        };
        const response = await request(`/member/verify-doc-type`,requestBody);
        form.resetFields();
        // console.log("DL kyc Values===>", values);
        if (response?.userId) {
          toast.success("KYC request sent to admin for approval!");
          navigate(-1);
        } else {
          toast.error("KYC request failed to send to admin approval!");
        }
      }
    } catch (error: any) {
      toast.error("KYC request failed to send to admin approval!");
      // console.log("DL kyc Error===>", error);
    }
  };

  const disabledDate = (current: any) => {
    return current && current.year() >= 2006;
  };

  return (
    <div>
      <Form
        form={form}
        name="kycForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="kycFormItem"
      >
        <Form.Item
          className="formitem"
          label="Driving Licence"
          name="drivingID"
          rules={[
            {
              required: true,
              message: "Please enter your driving licence ID!",
            },
            {
              validator(_, value) {
                const dlRegex =
                  /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                if (dlRegex.test(value) || !value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter valid driving licence ID!")
                );
              },
            },
          ]}
        >
          <Input className="h-11" />
        </Form.Item>

        <Form.Item
          className="formitem"
          label="Date of Birth"
          name="dob"
          rules={[
            {
              required: true,
              message: "Please select your date of birth!",
            },
          ]}
        >
          <DatePicker
            className="h-11"
            format={"DD-MM-YYYY"}
            defaultPickerValue={currentDate.subtract(18, "year")}
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item
          name="termsCondition"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions!",
            },
          ]}
        >
          <Checkbox>
            <span className="text-[20px]">Terms & Conditions</span>
            <span style={{ color: "#ff4d4f" }}>*</span>
          </Checkbox>
        </Form.Item>

        <div className="Save-btn">
          <Form.Item
            wrapperCol={{ offset: 8, span: 24 }}
            className="flex justify-end gap-3 buttonsDiv"
          >
            <Button
              htmlType="submit"
              className="createButton"
              type="primary"
              style={{ marginTop: "-1px", height: "45px" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default DLKyc;
