import { request } from "./request";

export const checkEmailVerified =async ()=>{
    const localStorageEmailVerified = JSON.parse(localStorage.getItem('user'));
    if(localStorageEmailVerified?.emailVerified){
        return true;
    }else{
    const meApiResp = await request("/member/current/me");
    if (meApiResp?.emailVerified){
        localStorage.setItem('user', JSON.stringify(meApiResp));
        return true;
    }else{
        return false;
    }
    }
}