import React, { createContext, useContext, useState } from "react";

interface UserMatrimonyContextType {
  userMatrimonyData: any;
  setUserMatrimonyDataFunction: (data: any) => void;
}

const UserMatrimonyContext = createContext<
  UserMatrimonyContextType | undefined
>(undefined);

export const useUserMatrimonyContext = () => {
  const context = useContext(UserMatrimonyContext);
  if (!context) {
    throw new Error(
      "useUserMatrimonyContext must be used within an UserMatrimonyContextProvider"
    );
  }
  return context;
};

interface UserMatrimonyContextProviderProps {
  children: React.ReactNode;
}

export const UserMatrimonyContextProvider: React.FC<
  UserMatrimonyContextProviderProps
> = ({ children }) => {
  const [userMatrimonyData, setUserMatrimonyData] = useState<any>(null);

  const setUserMatrimonyDataFunction = (data: any) => {
    setUserMatrimonyData(data);
  };

  return (
    <UserMatrimonyContext.Provider
      value={{ userMatrimonyData, setUserMatrimonyDataFunction }}
    >
      {children}
    </UserMatrimonyContext.Provider>
  );
};
