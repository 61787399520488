import React from "react";
import "./MultiStepProgressBar.css";
import { CheckOutlined } from "@ant-design/icons";
import { ProgressBar, Step } from "react-step-progress-bar";
import personalDetails from "../../assets/svg icons/personalDetails.svg";
import familyDetails from "../../assets/svg icons/familyDetails.svg";
import hobbies from "../../assets/svg icons/hobbies.svg";
import employment from "../../assets/svg icons/employementDetails.svg";
// import marital from "../../assets/svg icons/maritalStatus.svg";
import photos from "../../assets/svg icons/photosBiodata.svg"

const MultiStepProgressBar = ({ page, onPageClickHandler }) => {
    var stepPercentage = 0;
    if (page === "page1") {
        stepPercentage = 8;
    } else if (page === "page2") {
        stepPercentage = 25;
    } else if (page === "page3") {
        stepPercentage = 43;
    } else if (page === "page4") {
        stepPercentage = 58;
    } else if (page === "page5") {
        stepPercentage = 75;
    } else if (page === "page6") {
        stepPercentage = 93;
    } else if (page === "page7") {
        stepPercentage = 100;
    }
    // else if (page === "page8") {
    //     stepPercentage = 95;
    // } else if (page === "page9") {
    //     stepPercentage = 100;
    // }
    else {
        stepPercentage = 0;
    }

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : "not"}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("1")}
                            >
                                <img className="progress-icons" src={personalDetails} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Personal details</h2>
                            </div>
                        </div>
                    </div>
                )}
                {/* <h2>Step1</h2> */}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : "not"}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("2")}
                            >
                                <img className="progress-icons" src={familyDetails} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Family details</h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : null}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("3")}
                            >
                                <img className="progress-icons" src={hobbies} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Hobbies</h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : null}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("4")}
                            >
                                <img className="progress-icons" src={employment} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Professional details</h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : null}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("5")}
                            >
                                <img className="progress-icons" src={personalDetails} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Looking for</h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : null}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("6")}
                            >
                                <img className="progress-icons" src={photos} />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2>Photos and bio data</h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div className="step">
                        <div className={`stepContainer ${accomplished ? "accomplished" : null}`}>
                            <div
                                className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                onClick={() => onPageClickHandler("7")}
                            >
                                {/* <img className="progress-icons" src={CheckOutlined} /> */}
                                <CheckOutlined className="checkIcon" />
                                {/* {index + 1} */}
                            </div>
                            <div className="caption">
                                <h2></h2>
                            </div>
                        </div>
                    </div>
                )}
            </Step>
            {/* <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    // onClick={() => onPageNumberClick("4")}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    // onClick={() => onPageNumberClick("4")}
                    >
                        {index + 1}
                    </div>
                )}
            </Step> */}
        </ProgressBar>
    );
};

export default MultiStepProgressBar;
