import React, { useEffect, useState } from "react";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";
import NoRequest from "./NoRequest";

type Props = {};

const Sent = (props: Props) => {
  const [sentData, setSentData] = useState<any[]>([]);
  const [postRequestResponse, setPostRequestRequestResponse] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const getReceivedData = async () => {
    const resp = await request(`/member/current/my/membership/requests`);
    const postRequestResp = await request(`/member/current/post-requests`);
    console.log("postRequestResponse", postRequestResponse);
    if (resp?.length) {
      setSentData(resp);
      setPostRequestRequestResponse(postRequestResp);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReceivedData();
  }, []);
  return (
    <section>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!sentData?.length ? (
            <div className="text-xl font-medium">No Messages</div>
          ) : (
            <div className="thumbnailRowForThree requestbox">
             <div className="text-xl font-medium w-full">
                <NoRequest message="No Notifications12"  />
              </div>
              {sentData?.map((data) => (
                <div className="thumbnailRowForThreeCol" key={data.id}>
                  <div className="thumbnailDescription">
                    Your request has been sent to join{" "}
                    <span className="font-bold ml-1 mr-1">
                      {" "}
                      {data?.communityName}{" "}
                    </span>
                    community
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Sent;
