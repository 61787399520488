export const imageVideoHeightCalc = (
    naturalWidth,
    naturalHeight,
    postId,
  ) => {
    const singleBigImg = document.getElementById("post_image_" + postId);
    const post_img_video = document.getElementById("post_img_video_" + postId);
    const post_imgvideo_container = document.getElementById(
      "post_imgvideo_container_" + postId
    );
    // console.log(naturalWidth, "====>", naturalHeight, "------", postId);
    if (naturalHeight >= 700 && naturalWidth <= naturalHeight) {
      if (post_img_video) {
        singleBigImg?.classList.add("singleBigImg");
        post_imgvideo_container?.classList.add("post_imgvideo_container");
  
        if (naturalHeight >= 1200 &&  naturalHeight < 1500) {
          post_img_video.classList.add("post_img_video_width45");
        }else if (naturalHeight >= 1500) {
          post_img_video.classList.add("post_img_video_width40");
        } else {
          post_img_video.classList.add("post_img_video_width60");
        }
      }
    }
  };