import { request } from "./request";

export const CheckBadWordsExist = async (censorCharacter) => {
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        text: censorCharacter?.toLowerCase(),
      }),
    };
    const resp = await request(
      `/member/current/check-profanity`,
      requestOptions
    );
    // const res = await resp?.json();
    return resp;
  } catch (error) {
    console.error("error", error);
    return {};
  }
};
