// Constants for maximum widths for readability
const MAX_MOBILE_WIDTH = 1100;
const MAX_MOBILE_ONLY_WIDTH = 700;

// Check if the window width is less than or equal to the maximum for general mobile
export const isMobile = window?.innerWidth <= MAX_MOBILE_WIDTH;

// Check if the window width is less than or equal to the maximum for mobile-only
export const isMobileOnly = window?.innerWidth <= MAX_MOBILE_ONLY_WIDTH;

export const isDesktop = window?.innerWidth > MAX_MOBILE_WIDTH;

export const isTablet = window?.innerWidth > MAX_MOBILE_ONLY_WIDTH && window?.innerWidth > MAX_MOBILE_WIDTH;

