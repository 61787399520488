import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import "../Components/Commuinty/YourCommunity.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { request } from "../Utils/request";
import PullToRefreshComponent from "../PullToRefreshComponent/PullToRefreshComponent";
import Loader from "../Components/Loader/Loader";
import UserCard from "../Components/RootSearch/UserCard";
import RootSearchDropdown from "../Components/RootSearch/RootSearchDropdown";
import { isMobile } from "../Utils/mobileSettings";
import EmailVerificationAlert from "../Components/EmailVerificationAlert/EmailVerificationAlert";
import { checkEmailVerified } from "../Utils/checkEmailVerified";

// import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

const { Option } = Select;

type Props = {};

type selectOption = {
  searchTerm: string;
  searchAcross: string;
  searchAcrossDisplayName: string;
  operator: string;
};

function RootSearch(props: Props) {
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedOptionsForTags, setSelectedOptionsForTags] = useState<
    selectOption[]
  >([]);

  const [loader, setLoader] = useState<Boolean>(false);
  const [isSearchClicked, setIsSearchClicked] = useState<Boolean>(false);
  const [showSearchResults, setShowSearchResults] = useState<Boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isEmailVerified, setIsEmailVerified] = useState<any>();
  const loginVia = localStorage.getItem("loginInVia");

  const getEmail = async () => {
    const isVerified = await checkEmailVerified();
    setIsEmailVerified(isVerified);
  };
  useEffect(() => {
    getEmail();
  }, []);

  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");
  const navigate = useNavigate();

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate("/profile");
    }
    return;
  };

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear();
      navigate("/login");
    }
    if (profileScore === null) {
      meApiCall();
    } else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
    // eslint-disable-next-line no-empty-pattern
  }, [navigate, token?.length]);

  const makeSearchApiCall = async () => {
    if (selectedOptionsForTags?.length > 0) {
      // setSearchQuery("");
      const url = `/member/current/search-by-root`;
      const body = {
        method: "POST",
        body: JSON.stringify({
          page: currentPage,
          size: 10,
          criteria: selectedOptionsForTags.map(({ searchAcross, ...rest }) => ({
            ...rest,
            searchAcross: searchAcross.replace(/\s/g, "").toUpperCase(),
          })),
        }),
      };
      const requestPromise = await request(url, body);
      if (currentPage === 0) {
        setSearchResults(requestPromise);
      } else {
        setSearchResults([...searchResults, ...requestPromise]);
      }
      if (requestPromise?.length === 0) {
        setHasMore(false);
      }

      console.log("search results", requestPromise);
    }
    setIsSearchClicked(true);
    setLoader(false);
  };

  const handleSearch = async (value: any) => {
    if (value?.length > 0) {
      setSelectedOptionsForTags((prevState: selectOption[]) => [
        ...prevState,
        {
          searchTerm: searchQuery,
          searchAcross: "ALL",
          searchAcrossDisplayName: "All fields",
          operator: "OR",
        },
      ]);
      setSearchQuery("");
      makeSearchApiCall();
    }
    setShowSearchResults(false);
    makeSearchApiCall();
  };

  const clearSearchText = () => {
    setSearchQuery("");
    setCurrentPage(0);
    // handleSearch();
  };

  const handleRefresh = () => {
    setSearchQuery("");
  };
  useEffect(() => {
    if (selectedOptionsForTags?.length === 0) {
      setSearchResults([]);
      setIsSearchClicked(false);
    }
  }, [selectedOptionsForTags]);

  useEffect(() => {
    if (selectedOptionsForTags?.length > 0 && hasMore) {
      handleSearch(searchQuery);
    }
  }, [currentPage]);

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div className="containerWithOutSideBar" style={{ paddingTop: "20px" }}>
        <div className="mainContentCol">
          <div className="tabPanelBox">
            <div style={{ minHeight: "250px" }}>
              {loader ? (
                <div className="loader mt-22">
                  <Loader />{" "}
                </div>
              ) : (
                <div className="flex flex-col ">
                  {isMobile && (
                    <>
                      {loginVia === "email" && !isEmailVerified && (
                        <div className="my-2">
                          <EmailVerificationAlert from={""} />
                        </div>
                      )}
                    </>
                  )}
                  <Input.Search
                    id="searchBykeywordWeb"
                    placeholder="Search users by keyword"
                    onSearch={handleSearch}
                    // style={{ width: "95%" }}
                    loading={loader}
                    enterButton={
                      <Button
                        type="primary"
                        className="bg-[#7d8742] hover:bg-green-600"
                      >
                        Search
                      </Button>
                    }
                    className="pl-1 md:pl-3 md:py-5 md:w-95 rootSearchButton"
                    allowClear
                    value={searchQuery}
                    onChange={(e) => {
                      if (selectedOptionsForTags?.length < 4) {
                        setSearchQuery(e.target.value);
                        if (e.target.value?.length > 0) {
                          setShowSearchResults(true);
                        }
                      } else {
                        toast.error(
                          "You can only search based on 4 conditions at a time."
                        );
                      }
                    }}
                  />
                  {searchQuery?.length > 0 &&
                    searchQuery !== " " &&
                    showSearchResults && (
                      <RootSearchDropdown
                        queryText={searchQuery}
                        setShowSearchResults={setShowSearchResults}
                        clearSearchText={clearSearchText}
                        setSelectedOption={setSelectedOptionsForTags}
                      />
                      // <></>
                    )}
                  <div className="flex flex-row py-2 sm:-ml-3 md:w-95 md:-ml-1 pt-2 pl-0  overflow-auto">
                    {selectedOptionsForTags?.length > 0 &&
                      selectedOptionsForTags?.map((tag: any, index: any) => {
                        const removeTag = () => {
                          setSelectedOptionsForTags((prevState) =>
                            prevState.filter((_, i) => i !== index)
                          );
                        };

                        return (
                          <div
                            key={index}
                            className="flex flex-row w-fit items-center"
                          >
                            <div className="flex flex-row items-center px-3 py-2 ml-5 bg-[#67702E] rounded-lg text-white flex-grow whitespace-nowrap overflow-hidden">
                              {tag?.searchTerm} in{" "}
                              {tag?.searchAcrossDisplayName}
                              <div
                                onClick={removeTag}
                                className="pl-2 cursor-pointer"
                              >
                                <i className="bi bi-x-circle"></i>
                              </div>
                            </div>
                            {index !== selectedOptionsForTags.length - 1 && (
                              <Select
                                defaultValue={tag?.operator}
                                className="ml-1 h-full flex-grow w-fit"
                                onChange={(value: string) => {
                                  setSelectedOptionsForTags((prevState) =>
                                    prevState.map((item, idx) =>
                                      idx === index
                                        ? { ...item, operator: value }
                                        : item
                                    )
                                  );
                                  console.log(selectedOptionsForTags);
                                }}
                              >
                                <Option key={"OR"} value={"OR"} label={"OR"}>
                                  <div>Or</div>
                                </Option>
                                <Option key={"AND"} value={"AND"} label={"AND"}>
                                  <div>And</div>
                                </Option>
                              </Select>
                            )}
                          </div>
                        );
                      })}
                  </div>

                  {searchResults?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={searchResults?.length}
                      next={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                      hasMore={hasMore}
                      loader={<>{/* <Loader /> */}</>}
                    >
                      <div className="thumbnailBox">
                        <div className="thumbnailRowForThree communityUI">
                          {searchResults?.map((user) => (
                            <UserCard user={user} />
                          ))}
                        </div>
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <div className="flex justify-center items-center pt-5 shadow-xl">
                      <div className="bg-white text-center py-8 rounded-lg border border-[#7F4C30] ">
                        {selectedOptionsForTags?.length > 0 &&
                        isSearchClicked ? (
                          <>
                            <div className="text-[#7F4C30] text-lg font-semibold px-2">
                              No users found
                            </div>
                            <div className="text-[#7F4C30] text-sm mt-2 px-2">
                              Try changing the search conditions.
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-[#7F4C30] text-lg font-semibold px-2">
                              Try searching for people
                            </div>
                            <div className="text-[#7F4C30] text-sm mt-2 px-2">
                              Start typing to search users based on user
                              information and location.
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PullToRefreshComponent>
  );
}

export default RootSearch;
