import React from 'react'

type Props = {}

// eslint-disable-next-line no-empty-pattern
function TermsOfUse({}: Props) {
  return (
     <div className='footerPages'>
    <section className='termsPage innerpagesalign'>
     <div  className='innerpagesdiv'>
    <h1 className=''>TERMS OF USE</h1>
    <p className=''>
    Please read these Terms of Use carefully before using the https://www.vamshavruksha.in website and the Mobile App operated by the Company. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
         </p>
         <p className=''>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

  <h1 className='smalltxt'>Cookies</h1>
    <p className=''>
    Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
         </p>    

 <h1 className='smalltxt'>Content</h1>
    <p className='text-[#333333] !font-thin text-base leading-10'>
    Our Service allows you to access certain information, text, graphics, videos, or other material ("Content"). The rights granted to you by these Terms of Use is a right of access through the Site only, and does not grant to you any right to download or store any Proprietary Materials in any medium.
         </p>     

         <h1 className='smalltxt'>Links To Other Web Sites</h1>
    <p className='text-[#333333] !font-thin text-base leading-10'>
    Our Service may contain links to third-party web sites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
         </p> 

         <h1 className='smalltxt'>Changes</h1>
    <p className='text-[#333333] !font-thin text-base leading-10'>
    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
         </p> 

         <h1 className='smalltxt'>Contact Us</h1>
    <p className=''>
    If you have any questions about this Terms In Use, please contact us.
         </p> 
         </div>
</section>
</div>
  )
}

export default TermsOfUse