import React, { useState, useEffect } from "react";
import { request } from "../../Utils/request";
import "./CommunityPreview.css";
import image from "../../assets/images/community-connect.jpg";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";

type Props = {
  id?: string;
};

function Trending(props: Props) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getTrending = async () => {
      const resp = await request(`/member/current/community/trending`);

      if (resp) {
        const datafetch = resp;
        setLoader(false);

        setPosts(datafetch);
      } else {
        setLoader(false);
      }
    };
    getTrending();
  }, []);

  // Filter out duplicate usernames
  // const uniqueUsernames = Array.from(new Set(posts?.map((post) => post.userName)));

  if (posts?.length === 0) {
    return (
      <>
      {loader ? (
        <div  style={{minHeight:"8rem"}}>
        <div className="loader my-3">
          <Loader />{" "}
        </div>
        </div>
      ) : (
        <>
        <p className="RightSideTextLabel">Trending Communities</p>
        <div className="flex flex-col items-center">
          {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
          <div className="text-gray-500 text-xl pb-8"> No communities </div>
        </div>
        </>)}
      </>
    );
  }

  return (
    <>
    {loader ? (
      <div  style={{minHeight:"8rem"}}>
        <div className="loader my-3">
          <Loader />{" "}
        </div>
        </div>
      ) : (
    <div>
      <p className="RightSideTextLabel">Trending Communities</p>
      {posts?.length > 0 &&
        posts?.map((post, index) => (
          <div
            key={index}
            className="pointer flex flex-row trendingRow"
            onClick={() => navigate(`/communityPreview/${post?.id}`, {
              state: {
                communityMemberStatus: post?.admin
                  ? "admincommunity"
                  : "othercommunity",
                  from:0,
              }},)}
          >
            <div className="">
              <img
                src={
                  post?.communityProfileUrl ? post?.communityProfileUrl : image
                }
                alt=""
                className="trendingCommunityImage"
              />
            </div>
            <div className="flex items-center">
              <span className="posttitle capitalize">{post?.name}</span>
              {/* <span className="ml-1">&#8594;</span>
              <span className="posttitle">{posts[0]?.communityName}</span> */}
            </div>
          </div>
        ))}
    </div>
      )}
    </>
  );
}

export default Trending;
