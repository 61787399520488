import React, { useState, useEffect } from "react";
import "./KycComponent.css";
import { Form, Select } from "antd";
import DLKyc from "./DLKyc";
import AadharKyc from "./AadharKyc";
import PanKyc from "./PanKyc";
import VoterKyc from "./VoterKyc";
import PassportKyc from "./PassportKyc";
import { useParams } from "react-router-dom";

type ProfileProps = {
  // parentCallback: Function;
};

const KycComponent = (props: ProfileProps) => {
  const params = useParams();
  const matrimonyID = params?.id;
  const kycItems = [
    {
      key: "1",
      label: "Aadhar Card ",
      Component: <AadharKyc matrimonyID={matrimonyID} />,
      value: "aadharCard",
    },
    {
      key: "2",
      label: "Pan Card",
      Component: <PanKyc matrimonyID={matrimonyID} />,
      value: "panCard",
    },
    {
      key: "3",
      label: "Voter ID",
      Component: <VoterKyc matrimonyID={matrimonyID} />,
      value: "voaterId",
    },
    {
      key: "4",
      label: "Driving License",
      Component: <DLKyc matrimonyID={matrimonyID} />,
      value: "DL",
    },
    {
      key: "5",
      label: "Passport",
      Component: <PassportKyc matrimonyID={matrimonyID} />,
      value: "passport",
    },
  ];

  useEffect(() => {
    setKYCActiveComponent(kycItems[0].Component);
  }, []);

  const [KYCActiveComponent, setKYCActiveComponent] = useState<
    React.ReactNode | undefined
  >(undefined);

  const handleChange = (value: string) => {
    // console.log(`selected ${value}`);
    const filterComponent =
      kycItems.find((item) => item.value === value)?.Component || undefined;
    setKYCActiveComponent(filterComponent);
  };
  return (
    <div className="container">
      {localStorage.getItem("kycCompleted") === "true" ? (
        <>
          <i className="bi bi-check2-circle checkcircle"></i>
          <h1 className="verifiedheading">YOUR PROFILE IS VERIFIED!!</h1>
        </>
      ) : (
        <>
          <h1 className="heading">KYC VERIFICATION</h1>
          <div className="form">
            <div style={{ width: "40%" }}>
              <i className="kyc-icon bi bi-person-lines-fill"></i>
            </div>
            <div className="kycFormContainer">
              <div>
                <Form
                  name="kycForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  className="kycFormItem"
                >
                  <Form.Item
                    className="formitem"
                    label="Document Type"
                    name="aadharNo"
                  >
                    <Select
                      className="h-11"
                      defaultValue="aadharCard"
                      onChange={handleChange}
                      options={kycItems}
                    />
                  </Form.Item>
                </Form>
              </div>
              {KYCActiveComponent}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KycComponent;
