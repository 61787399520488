import {createSlice} from '@reduxjs/toolkit'
import { stat } from 'fs'

export interface loggedInfo  {
    feedsInfo:object
}

const initialState : loggedInfo = {
    // isLogin:false,
    feedsInfo : {

    }
}

export const AuthenticateSlice = createSlice({
    name:'loginInfo',
    initialState,
    reducers:{
        // LoginDispatch : (state,payload) => {
        //     state.loggedInPersonInfo = {
        //         ...payload
        //     }
        // },
        FeedsDispatch:(state,payload) => {
            state.feedsInfo = {
                ...payload
            }
            
        }
    }
}) 

export const {FeedsDispatch} = AuthenticateSlice.actions
export default AuthenticateSlice.reducer