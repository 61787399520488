import AssociationBanner from '../../assets/categoryImages/Association.jpg'
import FamilyBanner from '../../assets/categoryImages/Family.jpg'
import ReligionBanner from '../../assets/categoryImages/Religion.jpg'
import SocietyBanner from '../../assets/categoryImages/Society.jpg'
import FriendsBanner from '../../assets/categoryImages/Friends.jpg'
import AssociationProfile from '../../assets/categoryImages/Association-01.jpg'
import FamilyProfile from '../../assets/categoryImages/Family-01.jpg'
import ReligionProfile from '../../assets/categoryImages/Religion-01.jpg'
import SocietyProfile from '../../assets/categoryImages/Society-01.jpg'
import FriendsProfile from '../../assets/categoryImages/Friends-01.jpg'


const categoryImages = {
  'Association community': {
    banner: AssociationBanner,
    profile: AssociationProfile,
  },
  'Society community': {
    banner: SocietyBanner,
    profile: SocietyProfile,
  },
  ' Family community': {
    banner: FamilyBanner,
    profile: FamilyProfile,
  },
  'Religion community': {
    banner: ReligionBanner,
    profile: ReligionProfile,
  },
  'Friends community': {
    banner: FriendsBanner,
    profile: FriendsProfile,
  },}

  type CategoryName = 'Association community' | 'Society community' | ' Family community' | 'Religion community' | 'Friends community';

  function getCategoryImages(categoryName: CategoryName) {
    
    if (categoryImages.hasOwnProperty(categoryName)) {
      const categoryImage = categoryImages[categoryName];
      return {
        bannerUrl: categoryImage.banner,
        profileUrl: `${categoryImage.profile}`,
      };
    } else {
      return {
        bannerUrl: '',
        profileUrl: '',
      };
    }
  }

  export default getCategoryImages