import React, { useState } from "react";
import { toast } from "react-toastify";
import { request } from "../../Utils/request";
import { useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  Form,
  Input,
} from "antd";

type Props = {
  matrimonyID: any;
};

const AadharKyc = (props: Props) => {
  const [form] = Form.useForm();
  const matrimonyID = props?.matrimonyID;
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    try {
      const { aadharNo, termsCondition } = values;
      if (!termsCondition) {
        form.setFields([
          {
            name: "termsCondition",
            errors: ["Please accept the terms and conditions!"],
          },
        ]);
        return;
      } else {
        const requestBody = {
          method:'POST',
          body:JSON.stringify({
            docNumber: aadharNo,
            matrimonyId: matrimonyID,
            docTypeEnum: "AADHAAR",
            fullName: "",
            dob: "",
            mobileNumber: "",
          })
        
        };
        const response = await request(
          `/member/verify-doc-type`, requestBody);
        form.resetFields();
        // console.log("Aadhar kyc Values===>", values);

        if (response?.userId) {
          toast.success("KYC request sent to admin for approval!");
          navigate(-1);
        } else {
          toast.error("KYC request failed to send to admin approval!");
        }
      }
    } catch (error: any) {
      // console.log("Aadhar Card kyc Error===>", error);
      toast.error("KYC request failed to send to admin approval!");
    }
  };

  const formatAadhar = (value: string) => {
    const numericValue = value?.replace(/\D/g, "");
    const formattedValue = numericValue?.replace(
      /(\d{4})(\d{4})(\d{4})/,
      "$1 $2 $3"
    );
    return formattedValue.trim();
  };

  const handleAadharChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatAadhar(e.target.value);
    form.setFieldsValue({ aadharNo: formattedValue.trim() });
  };
  
  return (
    <div>
      <Form
        form={form}
        name="kycForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="kycFormItem"
      >
        <Form.Item
          className="formitem"
          label="Aadhar No"
          name="aadharNo"
          rules={[
            {
              validator(_, value) {
                const aadharWithoutSpaces = value?.replace(/\s/g, "");
                // const aadharRegex = /^\d{12}$/;
                const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{8}$/;
                if (!aadharRegex.test(aadharWithoutSpaces) && value) {
                  return Promise.reject("Invalid Aadhar number!");
                } else if (!value) {
                  return Promise.reject("Please enter your Aadhar number!");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            type="type"
            className="h-11"
            onChange={handleAadharChange}
            maxLength={14}
          />
        </Form.Item>

        <Form.Item
          name="termsCondition"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Please accept the terms and conditions!",
            },
          ]}
        >
          <Checkbox>
            <span className="text-[20px]">Terms & Conditions</span>
            <span style={{ color: "#ff4d4f" }}>*</span>
          </Checkbox>
        </Form.Item>

        <div className="Save-btn">
          <Form.Item
            wrapperCol={{ offset: 8, span: 24 }}
            className="flex justify-end gap-3 buttonsDiv"
          >
            <Button
              htmlType="submit"
              className="createButton"
              type="primary"
              style={{ marginTop: "-1px", height: "45px" }}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AadharKyc;
