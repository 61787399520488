import React from "react";

interface Props {
  message?: string;
}
const NoRequest = (props: Props) => {
  return (
    <div className="flex items-center justify-center noRequestContainer">
      <div className="text-gray-400 noRequestsText">
        {props.message || "No Requests"}
      </div>
    </div>
  );
};

export default NoRequest;
