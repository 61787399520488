import React from "react";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import "./MatrimonyTabs.css";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import { isMobileOnly } from "../../Utils/mobileSettings";
import KYCActive from "../../assets/newUiImages/kycActive.svg";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type ExploreProps = {
  matrimonyList: any;
  handleRefresh: () => void;
  loader: boolean;
};

function convertHeightToFeetAndInches(heightInCms: number) {
  const inches = heightInCms / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  // Adjust for the case where remainingInches is 12
  if (remainingInches === 12) {
    return `${feet + 1}' 0''`;
  }

  return `${feet}' ${remainingInches}''`;
}

function truncateString(str: string, maxLength: number) {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength) + "...";
  }
  return str;
}

function Explore({ matrimonyList, handleRefresh, loader }: ExploreProps) {
  const navigate = useNavigate();

  const handleMatrimonyProfile = (id: any) => {
    navigate(`/matrimony/matrimony-profile/${id}`, {
      state: { user: "explore" },
    });
  };

  const handlePullToRefresh = async (): Promise<void> => {
    handleRefresh();
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handlePullToRefresh();
      }}
    >
      <div>
        {loader ? (
          <div className="flex justify-center items-center mt-5">
            <Loader />
          </div>
        ) : (
          <div className="matrimonyExplore">
            {matrimonyList?.length > 0 ? (
              <div className={isMobileOnly ? "mt-3" : "mt-7"}>
                <div
                  id="matrimonyExploreList"
                  className="grid grid-cols-1 pb-3  sm:grid-cols-2 -mr-3 md:grid-cols-2 lg:grid-cols-4 gap-4 pr-2 mt-2 pr-2 pt-2 mr-1 xl:grid-cols-4 gap-4"
                >
                  {matrimonyList?.map((matrimony: any, index: any) => (
                    <div
                      className="relative border-2 border-gray-300 border-solid rounded-lg ml-3 mr-2 p-1 overflow-hidden shadow-md w-full profileList"
                      key={matrimony?.matrimonyId}
                    >
                      <div className="flex cursor-pointer p-2 mb-1 pb-2 relative w-full contentwrapper">
                        <div
                          className="overflow-hidden rounded-l-lg sm:h-48 h-36 rounded-l-lg md:rounded-lg"
                          onClick={() =>
                            handleMatrimonyProfile(matrimony?.matrimonyId)
                          }
                        >
                          {matrimony?.profileUrl?.length > 0 ? (
                            <img
                              src={
                                matrimony?.profileUrl?.length < 6
                                  ? matrimony?.profileUrl[0]
                                  : matrimony?.profileUrl
                              }
                              alt=""
                              className={
                                matrimony?.thumbnailImage ||
                                matrimony?.show === "EVERYONE" ||
                                matrimony?.request === "APPROVE"
                                  ? "object-cover h-48 "
                                  : "object-cover h-48 blur-sm "
                              }
                            />
                          ) : (
                            <img
                              src={profilePlaceholder}
                              alt=""
                              className="object-cover h-48  sm:rounded-l-lg md:rounded-xl"
                            />
                          )}
                        </div>
                        <div
                          className="thumbnailContent ml-3 pr-2"
                          onClick={() =>
                            handleMatrimonyProfile(matrimony?.matrimonyId)
                          }
                        >
                          <div className=" ThumbnailTitle text-lg font-semibold p-0 m-0 pb-0 matrimonyThumbnailTitle md:p-2 m-2 mt-1 ">
                            {truncateString(matrimony?.member?.name, 10)}
                          </div>
                          <div className="text-base font-semibold p-0 m-0 md:p-2 m-2 mt-1 thumbnailContent">
                            {matrimony?.age > 0 && (
                              <div>{matrimony?.age} years,</div>
                            )}{" "}
                            {convertHeightToFeetAndInches(
                              matrimony?.member?.height
                            )}
                            {matrimony?.member?.currentState && (
                              <div>
                                From&nbsp;
                                {truncateString(
                                  matrimony?.member?.currentState,
                                  10
                                )}
                              </div>
                            )}
                            {matrimony?.communityIds &&
                              matrimony?.communityIds?.length > 0 && (
                                <div className="text-[#804C31]">
                                  {matrimony?.communityIds?.length}{" "}
                                  {matrimony?.communityIds?.length > 1 ? (
                                    <>common communities</>
                                  ) : (
                                    <>common community</>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {matrimony?.kycVerified && (
                        <div
                          className="kyc-verify-thumbnail"
                          title="KYC verified"
                        >
                          <img
                            src={KYCActive}
                            style={{ padding: "3px", marginLeft: "0" }}
                            className="kyc-verify-img"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-full">
                <div className="flex flex-col items-center pt-20 pb-10">
                  <div className="text-gray-500 text-2xl pt-5">
                    {" "}
                    No profiles to show
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </PullToRefreshComponent>
  );
}

export default Explore;
