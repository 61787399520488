import axios from "axios";
import { getTokenInfo } from "./jwt";
import moment from "moment";
import {toast} from 'react-toastify'
import { getToken } from "./timeago";

const BASE_URL = process.env.REACT_APP_BASE_URL;

//Fetch API calls
export const makeAPiCall = async (url, body, fbToken = true) => {
  const token = localStorage.getItem("token");
  // console.log('i am coming here')
  const resp = await fetch(`${BASE_URL + url}`, {
  method: body?.method || "GET",
  headers: {
  "Content-Type": "application/json",
  Authorization: fbToken ? `Bearer ${token}` : ``,
  },
  body: body?.body,
  });


  // this check for deleted accounts
  if(resp?.status === 451) {
    localStorage.clear()
    clearCache()
    localStorage.setItem('userDeleted','yes')
    window.location.href='/login'
  }
  return resp;
};

export const API_TIMEOUT_INTERVAL=4500;


export const cacheResponse = async (key, data) => {
  const cache = await caches.open("api-cache");
  const response = new Response(JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
  await cache.put(key, response.clone());
};

export const checkApiResponse = async (url) => {
 let requestPromise = request(url);
 let timeoutPromise = new Promise((resolve) =>setTimeout(resolve, API_TIMEOUT_INTERVAL));
 const resp = await Promise.race([requestPromise, timeoutPromise]);

 if(resp === undefined) {
 return true
 } else return requestPromise
}


export const getCachedResponse = async (key) => {
  const cache = await caches.open("api-cache");
  const response = await cache.match(key);
  return response.json();
};

export const clearCache = async () => {
  try {
    const cacheNames = await caches.keys();
    
    await Promise.all(
      cacheNames.map(async (cacheName) => {
        await caches.delete(cacheName);
      })
    );

    console.log("Cache cleared successfully");
  } catch (error) {
    console.error("Error clearing cache:", error);
  }
};




const getCurrentTime = async () => {
  return new Promise(async (resolve, reject) => {
    //getTokenInfo returns expire time
    getTokenInfo()
      .then((jwtExpTime) => {
        const currentTime = moment().add(5, "minutes").unix();
        if (currentTime <= jwtExpTime) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const responseData = async (resp) => {
  if(resp?.ok) {
    const contentType = resp?.headers?.get("content-type");
    if (contentType && contentType.includes("application/json")) {
     return  await resp.json();
    } else {
      return await resp.text();
    }
  } else {
    return resp
  }
}

//generic request function of fetch 
export const request = async (url, body, fbToken = true) => {
  const tokenExist = await getCurrentTime();
  try {
    let resp;
    if (tokenExist) {
      resp = await makeAPiCall(url, body, fbToken);
    } else {
      await getToken();
      resp = await makeAPiCall(url, body, fbToken);
    }

    if (!resp.ok) {
      const errorData = await resp.json();
      throw new Error(errorData.message || 'An unknown error occurred');
    }

    return responseData(resp);
  } catch (error) {
    console.error("Error in request:", error);
    // Re-throw the error so it can be caught by the calling function
    throw error;
  }
};

//axios API calls for form-data
const multiFormResponseData = async (url,body,type) => {
  const token = localStorage.getItem("token");
  const resp = await axios[type](`${BASE_URL + url}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  return resp;
}

export const multiFormRequest = async (url, body, type = "post") => {
  const tokenExist = await getCurrentTime();
  try {
    if (tokenExist) {
      return multiFormResponseData(url,body,type)
    } else {
      const token = await getToken();
      return multiFormResponseData(url,body,type)
    }
  } catch (error) {
    return error;
  }
};


