import React, { useState, useEffect } from "react";
import { ConfigProvider, Select } from "antd";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import {
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import { useChatContext } from "../../Context/ChatContext";
import { request } from "../../Utils/request";
import { db } from "../../firebase";
import { DownOutlined } from "@ant-design/icons";

const ChatSelectUserComponent = () => {
  const [yourMatrimonyList, setYourMatrimonyList] = useState<any>([]);
  const [apiResp, setApiResp] = useState<boolean>(false);
  const { userMatrimonyData, setUserMatrimonyDataFunction } =
    useUserMatrimonyContext();
  const { chatData, setChatDataFunction } = useChatContext();
  const [selectedUserMatrimonyProfile, setSelectedUserMatrimonyProfile] =
    useState<any>();

  const getYourMatrimonyData = async () => {
    const resp = await request(
      `/member/current/matrimony-profile/active/profiles`
    );
    if (resp?.length > 0) {
      setYourMatrimonyList(resp);
      setApiResp(!apiResp);
    } else {
      console.error("error");
    }
  };

  useEffect(() => {
    getYourMatrimonyData();
  }, []);

  useEffect(() => {
    if (userMatrimonyData?.matrimonyId && chatData?.matrimonyId) {
      handleChat();
    }
  }, [userMatrimonyData]);

  const handleChat = async () => {
    //check whether the group(chats in firestore) exists, if not create

    if (userMatrimonyData?.matrimonyId && chatData?.matrimonyId) {
      const combinedId =
        userMatrimonyData?.matrimonyId > chatData?.matrimonyId
          ? userMatrimonyData?.matrimonyId + "_" + chatData?.matrimonyId
          : chatData?.matrimonyId + "_" + userMatrimonyData?.matrimonyId;
      try {
        const res = await getDoc(doc(db, "chats", combinedId));
        if (!res.exists()) {
          //create a chat in chats collection
          await setDoc(doc(db, "chats", combinedId), { messages: [] });

          //create user chats
          await updateDoc(
            doc(db, "recentChats", userMatrimonyData?.matrimonyId),
            {
              [combinedId + ".userInfo"]: {
                matrimonyId: chatData?.matrimonyId,
                member: { name: chatData?.member?.name },
                profileUrl: chatData?.profileUrl,
              },
              [combinedId + ".date"]: serverTimestamp(),
            }
          );

          await updateDoc(doc(db, "recentChats", chatData?.matrimonyId), {
            [combinedId + ".userInfo"]: {
              matrimonyId: userMatrimonyData?.matrimonyId,
              member: { name: userMatrimonyData?.member?.name },
              profileUrl: userMatrimonyData?.profileUrl,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });
        }
      } catch (err) {}
    }
  };

  const handleSelect = (value: any) => {
    const selectedProfile = yourMatrimonyList?.find(
      (item: any) => item?.matrimonyId === value
    );
    const userMatrimonyInfo = {
      matrimonyId: selectedProfile?.matrimonyId,
      member: { name: selectedProfile?.member?.name },
      profileUrl: selectedProfile?.profileUrl,
    };
    setChatDataFunction({});
    setUserMatrimonyDataFunction(userMatrimonyInfo);
    setSelectedUserMatrimonyProfile(selectedProfile);
  };

  return (
    <div className="w-full ">
      <>
        <div className="w-full chat-select-user rounded-t-2xl">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#67702E",
                borderRadius: 0,
                colorPrimaryBorder: "#67702E",
                colorBgContainer: "#804c31",
                colorBgMask: "#804c31",
              },
            }}
          >
            {yourMatrimonyList?.length === 0 ? (
              <>
                <div className="text-white"></div>{" "}
              </>
            ) : (
              <Select
                className="matrimonySelect w-full  text-white "
                placeholder={
                  <div className="text-[#efe8e1]">Select a profile to chat</div>
                }
                suffixIcon={
                  <div className="text-[10px] text-gray-600 font-bold">
                    <DownOutlined className="text-base text-white font-bold" />{" "}
                  </div>
                }
                value={userMatrimonyData?.matrimonyId}
                onChange={(value: any) => {
                  handleSelect(value);
                }}
              >
                {yourMatrimonyList?.map((profile: any) => (
                  <Select.Option
                    key={profile?.matrimonyId}
                    value={profile?.matrimonyId}
                  >
                    <div className="flex justify-between ">
                      {profile?.profileUrl?.length > 0 ? (
                        <img
                          src={profile?.profileUrl[0]}
                          alt="profile pic"
                          className="OptionProfileImage"
                          style={{ aspectRatio: 3 / 2 }}
                        />
                      ) : (
                        <img
                          src={profilePlaceholder}
                          alt="profile pic"
                          className="OptionProfileImage"
                        />
                      )}
                      <div className="flex pl-2 items-center w-full text-lg font-semibold">
                        {profile?.member?.name}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
          </ConfigProvider>
        </div>
      </>
    </div>
  );
};

export default ChatSelectUserComponent;
