import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RequestComponent from "../Components/RequestComponent/RequestComponent";
import { request } from "../Utils/request";

type Props = {};

// eslint-disable-next-line no-empty-pattern
function Requests({}: Props) {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }

  useEffect(() => {
    if (!token?.length) {
      navigate("/login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
  }, []);
  return (
    <div className="">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          <div className="tabPanelBox">
            {token?.length && <RequestComponent />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Requests;
