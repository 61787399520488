import React from "react";
import { Modal } from 'antd';
import './modalStyles.css'

export default function TermsAndPrivacyModal({ modalValue , isModalVisible, handleOk, handleCancel }) {

    return (
        <Modal
            title={modalValue === 'TermsOfService' ? "Terms of Use" : "Privacy Policy"}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="terms-and-conditions-modal"
            footer={null}>
            {
                modalValue === 'TermsOfService' ? (
                    <React.Fragment>
                        <p>Please read these Terms of Use carefully before using the https://www.vamshavruksha.in website and the Mobile App operated by the Company. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
                        <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>

                        <h2>Cookies</h2>
                        <p>Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

                        <h2>Content</h2>
                        <p>Our Service allows you to access certain information, text, graphics, videos, or other material ("Content"). The rights granted to you by these Terms of Use are a right of access through the Site only, and do not grant to you any right to download or store any Proprietary Materials in any medium.</p>

                        <h2>Abusive Words Check</h2>
                        <p>Please note that our application includes a feature to check for abusive or harmful language. Users are expected to refrain from using abusive or harmful text in their interactions.</p>

                        <h2>Links To Other Websites</h2>
                        <p>Our Service may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>

                        <h2>Changes</h2>
                        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions about these Terms of Use, please contact us.</p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p>This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

                        <h2>Log Data</h2>
                        <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics. In addition, we may use third-party services such as Google Analytics that collect, monitor, and analyze this data.</p>

                        <h2>Security</h2>
                        <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                        <h2>Changes To This Privacy Policy</h2>
                        <p>This Privacy Policy is effective as of 03-DEC-2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us.</p>
                    </React.Fragment>
                )
            }
        </Modal>
    )
}