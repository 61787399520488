import React from "react";

type Props = {};

// eslint-disable-next-line no-empty-pattern
function AboutUs({}: Props) {
  return (
    <div className="footerPages">
    <section className="aboutUsPage">
      <section className="innerpagesalign">
        <h1 className="">About VamshaVruksha</h1>
        <div className="innerpagesdiv">
          <p className="">
            Vamshavruksha is platform for people to connect based on community.
            It aims to provide a forum for people of different communities to
            interact.
          </p>
          <p>
            It also aims to help people to find partner for life. Creation of a
            matrimony profile is simple and self explanatory.
          </p>
        </div>
      </section>
    </section>
    </div>
  );
}

export default AboutUs;
