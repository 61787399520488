import React, { useEffect, useState } from "react";
import loginLogo from "../assets/LoginImages/LoginLogo.svg";
import loginFamilyPic from "../assets/LoginImages/emailVerified.png";
import "./EmailRedirect.css";
import { useNavigate } from "react-router";
import { Input } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Components/Loader/Loader";
import { isMobile } from "../Utils/mobileSettings";
import jwtDecode from "jwt-decode";

function ResetPassword() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [resetComplete, setResetComplete] = useState<boolean>(false);

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  
  const isTokenExpired = () => {
    try {
      if (token) {
        const decodedToken: any = jwtDecode(token);

        if (decodedToken && decodedToken.exp) {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          return decodedToken.exp < currentTimestamp;
        }
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }

    return true;
  };

  useEffect(() => {
    if (isTokenExpired()) {
      toast.error("The reset password link has expired.");
      navigate("/login");
    }
  }, []);

  const submitPassword = async () => {
    setLoader(true);
    const requestBody = {
      newPassword: password,
      confirmPassword: confirmPassword,
      password: true,
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/member/reset-password?token=${token}`,
        requestBody
      );

      if (res?.status === 200) {
        setLoader(false);
        setResetComplete(true);
        if (!isMobile) {
          toast.success("Password updated successfully");
          navigate("/login");
        } else {
          toast.success(
            "Password updated successfully. Please proceed to login using the mobile app."
          );
        }
      } else {
        toast.error("Failed to update password");
        setLoader(false);
      }
    } catch (error: any) {
      console.error("An error occurred:", error);
      if (error?.response?.data) {
        toast.error(error?.response?.data?.message);
        setLoader(false);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleSubmit = () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      submitPassword();
    }
  };

  return (
    <div className="LoginWrapper">
      <div className="logincontainerWithOutSideBar loginPage">
        <div className="LoginMainContentCol">
          <div className="loginRow">
            <div className="loginImageCol loginCol-L">
              <img className="Login-L-Img" src={loginFamilyPic} alt="" />
            </div>
            <div className="loginCol loginCol-L">
              <div className="loginColWrapper">
                <div className="logoAndTittleWrapper">
                  <img
                    alt=""
                    className="cursor-pointer"
                    onClick={() => {
                      if (!isMobile) {
                        navigate("/");
                      }
                    }}
                    src={loginLogo}
                  />
                  <span className="logoText">VamshaVruksha</span>
                </div>
                {!resetComplete && (
                  <div className="flex flex-col justify-center items-center resetPassword">
                    {isMobile && (
                      <>
                        <p className="logoText mb-5">VamshaVruksha</p>
                      </>
                    )}
                    <Input.Password
                      placeholder="Enter the new password"
                      className="resetInputWrapper text-lg font-normal w-full"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <Input.Password
                      placeholder="Confirm new password"
                      className="resetInputWrapper text-lg font-normal w-full"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <button
                      className="resetInputWrapper resetSubmitBtn w-full"
                      onClick={handleSubmit}
                    >
                      {loader ? (
                        <div className="flex justify-center items-center">
                          <div className="-mt-3 w-[50px]">
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <>Submit</>
                      )}
                    </button>
                  </div>
                )}

                {!isMobile && (
                  <div className="">
                    <p className="emailText">
                      Click here to{" "}
                      <span
                        className="pointer"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Login
                      </span>
                    </p>
                  </div>
                )}
                {resetComplete && (
                  <div>
                    Password updated successfully. Please proceed to login using
                    the mobile app.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default ResetPassword;
