import React, { useEffect } from "react";
import ChatSidebar from "../Components/ChatComponent/ChatSidebar";
import { useNavigate } from "react-router";
import ChatComponent from "../Components/ChatComponent/ChatComponent";
import { isMobileOnly } from "../Utils/mobileSettings";
import "../Components/ChatComponent/ChatComponent.css";
import { toast } from "react-toastify";
import { request } from "../Utils/request";

type Props = {
  // parentCallback: Function;
};

function Chat(props: Props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear()
      navigate("/login");
      // props.parentCallback("login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
    // eslint-disable-next-line no-empty-pattern
  }, []);
  return (
    <>
      {token?.length && (
        <div className={isMobileOnly ? "remove-header" : "overflow-hidden"}>
          <div className="flex">
            <ChatSidebar />

            {isMobileOnly ? (
              <></>
            ) : (
              <>
                <div className="chat-right">
                  <ChatComponent />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Chat;
