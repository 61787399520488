import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import CommunityNotification from "./CommunityNotification";
import PostNotification from "./PostNotification";
import MatrimonyNotification from "./MatrimonyNotification";
import AdminNotification from "./AdminNotification";
import { request } from "../../Utils/request";
type Props = {};
const items: TabsProps["items"] = [
  {
    key: "1",
    label: `Community Notification`,
    children: <CommunityNotification />,
  },

  // {
  //   key: "2",
  //   label: `Post Notification`,
  //   children: <PostNotification />,
  // },
  {
    key: "2",
    label: `Matrimony Notification`,
    children: <MatrimonyNotification />,
  },
  {
    key: "3",
    label: `Admin Notification`,
    children: <AdminNotification />,
  },
];

const Notifications = (props: Props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }

  useEffect(() => {
    if (!token?.length) {
      navigate("/login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
  }, [navigate, token?.length]);

  return (
    <div>
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          <div className="w-full tabPanelBox h-full">
            {token?.length && (
              <Tabs
                className=""
                defaultActiveKey="1"
                items={items}
                onChange={() => {}}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
