import React, { ErrorInfo, ReactNode } from 'react';
import ErrorComponent from '../RequestComponent/NoRequest';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
 
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent message='Sorry, Something went wrong.'></ErrorComponent>;
    }

    return this.props.children;
  }
}

// Placeholder function, replace it with your actual error logging function
function logErrorToMyService(error: Error, errorInfo: ErrorInfo): void {
  // Implement your error logging logic here
  console.error(error, errorInfo);
}

export default ErrorBoundary;


