import React, {useEffect} from 'react'
import KycComponent from '../Components/KycComponent/KycComponent'
import { useNavigate } from 'react-router';

type ProfileProps = {
  // parentCallback: Function;
};

const Kyc = (props:ProfileProps) => {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // var str = "2023-10-06T11:56:30.869";
  // var strToDate = new Date(str);
  // console.log("sample date-------->", strToDate);

  useEffect(() => {
    // if (!token?.length) {
    //   navigate("/login");
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <KycComponent />
    </div>
  )
}

export default Kyc
