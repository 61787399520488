import React from 'react'

type Props = {}
// eslint-disable-next-line no-empty-pattern
function ContactUs({}: Props) {
  return (
    <div className='footerPages'>
    <section className='aboutUsPage'>
    <section className='innerpagesalign'>
       <div className='innerpagesdiv'>
               <h1 className=''>CONTACT US</h1>
               <div className=''>
               <p>If you have any queries, please send a mail to
                        <a className=''  href="mailto:info@vamshavruksha.in"> info@vamshavruksha.in</a>.
                    </p>
               </div>
                   
               </div>
    </section>
    </section>
    </div>
  )
}

export default ContactUs