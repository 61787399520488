import React, { useState } from "react";
import { Button, ConfigProvider, Select } from "antd";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import { DownOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import { useNavigate } from "react-router";

interface YourMatrimonySelectPopupProps {
  onCancelClick: Function;
  yourMatrimonyList: any[];
}

const SelectMatrimonyModal = (props: YourMatrimonySelectPopupProps) => {
  const { yourMatrimonyList } = props;
  const { userMatrimonyData, setUserMatrimonyDataFunction } =
    useUserMatrimonyContext();

  const [selectedUserMatrimonyProfile, setSelectedUserMatrimonyProfile] =
    useState<any>();

  const navigate = useNavigate();

  const handleSelect = (value: any) => {
    const selectedProfile = yourMatrimonyList?.find(
      (item: any) => item?.matrimonyId === value
    );
    const userMatrimonyInfo = {
      matrimonyId: selectedProfile?.matrimonyId,
      member: { name: selectedProfile?.member?.name },
      profileUrl: selectedProfile?.profileUrl,
    };
    setUserMatrimonyDataFunction(userMatrimonyInfo);
    setSelectedUserMatrimonyProfile(selectedProfile);
  };

  const handleSubmit = () => {
    navigate("/chat");
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#EFE8E1",
          colorBgMask: "#804c31",
        },
      }}
    >
      <Modal
        open
        onCancel={() => {
          props.onCancelClick();
        }}
        title={
          <div className="flex justify-center items-center ">
            <div className="mt-3">Select your profile to start the chat</div>
          </div>
        }
        style={{ width: "850px", padding: "10px" }}
        footer={
          <div className="flex justify-center items-center p-2">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="w-full ">
          <>
            <div className="chat-select-user m-5 rounded-2xl overflow-hidden border border-[#EDC2A6]">
              <Select
                className="matrimonySelect w-full "
                suffixIcon={
                  <div className="text-[10px] text-gray-600 font-bold">
                    <DownOutlined className="text-base text-[#EDC2A6] font-bold" />{" "}
                  </div>
                }
                value={userMatrimonyData?.matrimonyId}
                onChange={(value: any) => {
                  handleSelect(value);
                }}
              >
                {yourMatrimonyList?.map((profile: any) => (
                  <Select.Option
                    key={profile?.matrimonyId}
                    value={profile?.matrimonyId}
                  >
                    <div className="flex justify-between ">
                      {profile?.profileUrl?.length > 0 ? (
                        <img
                          src={profile?.profileUrl[0]}
                          alt="profile pic"
                          className="OptionProfileImage"
                          style={{ aspectRatio: 3 / 2 }}
                        />
                      ) : (
                        <img
                          src={profilePlaceholder}
                          alt="profile pic"
                          className="OptionProfileImage"
                        />
                      )}
                      <div className="flex pl-2 items-center w-full text-lg font-semibold text-black ">
                        {profile?.member?.name}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default SelectMatrimonyModal;
