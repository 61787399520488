// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
console.log('here',process.env.REACT_APP_API_KEY,process.env.NODE_ENV)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,

  //DEV config keys
  // apiKey: "AIzaSyDZyBXBXoZ2Pq7MS3cTSLHWnPpPkP0RwFM",
  // authDomain: "vamshavruksha-bc9be.firebaseapp.com",
  // projectId: "vamshavruksha-bc9be"
  // storageBucket: "vamshavruksha-bc9be.appspot.com",
  // messagingSenderId: "1001237783919",
  // appId: "1:1001237783919:web:623e80402a46623a5ab49a"
  // REACT_APP_BASE_URL='https://vamshavruksha-dev.southindia.cloudapp.azure.com:6061'
// REACT_APP_WEB_CLIENT_ID=1001237783919-28c8i0q2256tttr4de9fqcmqj28cj15n.apps.googleusercontent.com


  //Production config keys
  // apiKey: "AIzaSyAu6coDcAFzVBVcOKrQIntsE_ZbaOpCHTw",
  // authDomain: "vamshavruksha-1f188.firebaseapp.com",
  // projectId: "vamshavruksha-1f188",
  // storageBucket: "vamshavruksha-1f188.appspot.com",
  // messagingSenderId: "751667845478",
  // appId: "1:751667845478:web:2d6172ff79bfbde98690a7",
  // measurementId: "G-HLC3RZ40WM"
  // REACT_APP_BASE_URL='https://vamshavruksha-dev.southindia.cloudapp.azure.com:6061'
// REACT_APP_WEB_CLIENT_ID=11264771794-kfob7npdre4k2biluqid566pd08ncnvn.apps.googleusercontent.com
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

// const messaging = firebase.messaging();

// Handle background messages
// messaging.onBackgroundMessage(function (payload) {
//     console.log("Received background message ", payload);
//     console.log('fcm works');
    
//     const notificationTitle = payload.data.title;
//     const notificationOptions = {
//       body: payload.data.body,
//       icon: "./logo192.png",
//       data: { link: payload.data.link } // Store the link in the notification data
//     };
  
//     // Show notification with specified icon
//   // eslint-disable-next-line no-restricted-globals
//     self.registration.showNotification(notificationTitle, notificationOptions);
//   });
  
  // Handle notification click event
  // eslint-disable-next-line no-restricted-globals
  // self.addEventListener('notificationclick', function(event) {
  //   event.notification.close(); // Close the notification
  //   // eslint-disable-next-line no-restricted-globals
  //   console.log("NOTIFICATION CLICKED",self.clients)
  //   // eslint-disable-next-line no-restricted-globals
  //   event.waitUntil(self.clients.matchAll({ type: 'window', includeUncontrolled: true  }).then(windowClients => {
  //     const link = event.notification.data.link; // Access the link from notification data
  //     let matchingClient = null;
  //   //   const client = clientsArr.find(client => client.url === link);
  //   console.log("windowClients",windowClients, windowClients.length)
  //       for (let i = 0; i < windowClients.length; i++) {
  //                   let client = windowClients[i];
  //                   console.log("client", client, link);
  //                   // If so, just focus it.
  //                   if (client.url === link && 'focus' in client) {
  //                       matchingClient = client;
  //                       break;
  //                   }
  //               }
  //                   if (matchingClient) {
  //                       matchingClient.focus();
  //                     }
  //                     // eslint-disable-next-line no-restricted-globals
  //                   else if (self.clients.openWindow) {
  //                   console.log("open window")
  //   // eslint-disable-next-line no-restricted-globals
  //                   self.clients.openWindow(link);
  //               }
  //   }));
  //   // eslint-disable-next-line no-restricted-globals
  //   // self.clients.openWindow(event.notification.data.link)
  // });

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//         const notificationTitle = payload.data.title;
//       const notificationOptions = {
//           body: payload.data.body,
//           icon: "../public/logo192.png",        
//       };
    
//       if (!("Notification" in window)) {
//           console.log("This browser does not support system notifications.");
//       } else if (Notification.permission === "granted") {
//             // eslint-disable-next-line no-restricted-globals
//           var notification = new Notification(notificationTitle,notificationOptions);
//           notification.onclick = function(event) {
//             event.preventDefault();
//             // Redirect the current tab to the link
//             window.location.href = payload.data.link;
//             notification.close();
//           }
//       }
// });


// export const requestPermission = () => {
//     console.log('user permisson...')
//     Notification.requestPermission().then(permission => {
//         if (permission === 'granted') {
//             console.log('permssion granted')
//             return getToken(messaging, {
//                 vapidKey: "BD_Rk5Yqv11l3yGj96EQWlhH7hIS9kfoO1k2gWRaiRCwPKkBEdmctYTnvvyxNpuGGuJ2aGD7ZKlmr_LqEL4KKHI"
//             }).then(async currentToken => {
//                 if (currentToken) {
//                     console.log('client Token', currentToken)
//                     localStorage.setItem("deviceToken",currentToken);
//                     // alert(currentToken,'token')
//                     const payload = {
//                       method: "POST",
//                       body: JSON.stringify({
//                         deviceToken: currentToken,
//                         deviceType: "WEB",
//                         deviceOsVersion: 0,
//                       }),
//                       headers: {
//                         'Content-Type': 'application/json'
//                       }
//                     };
            
//                     // toast.success("Device token is " + token + " and OS version is " + version);
            
//                     const response = await request(`/member/current/device-token`, payload);
//                     console.log("RESPONSE: " + response);
                    
//                 } else {
//                     console.log('failed to get regsitration tokem')
//                 }
//             }).catch(err => {
//                 console.log('error occured', err)
//             })
//         } else {
//             console.log('user permission denied')
//         }
//     })
// }

// export { messaging };
export const auth = getAuth(app)
export const db= getFirestore(app)
export const storage = getStorage();
export const firebasecon = firebaseConfig;
export default app;

